import { IProductState, IActionBase } from "../models/root.interface";
import {
  ADD_PRODUCT,
  CHANGE_PRODUCT_PENDING_EDIT,
  EDIT_PRODUCT,
  REMOVE_PRODUCT,
  CLEAR_PRODUCT_PENDING_EDIT,
  SET_MODIFICATION_STATE,
  CHANGE_PRODUCT_AMOUNT,
  EDIT_TAG,
} from "../actions/products.action";
import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
import firebase from "./../../firebase/index";

let localUserEmail = localStorage.getItem("user_email");

function getProductData() {
  let productsData = [
    {
      id: 1,
      firebaseId: "abc",
      tag: "",
      name: "Welcome",
      description: "This is Chocolate and it is Sweet",
      amount: 5,
      price: 4,
      hasExpiryDate: true,
      category: "Welcome",
      downloadUrl: "",
      track: false,
      isDelete: false,
      helperText: "",
    },
  ];

  const recordingRef = firebase.firestore().collection("recordings");
  recordingRef
    .where("userEmail", "==", localUserEmail)
    .get()
    .then((snapshot) => {
      productsData.shift();
      let index = 0;
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach((doc) => {
        productsData.push({
          id: ++index,
          firebaseId: doc.id,
          name: doc.data().recordingName,
          description: "This is Chocolate and it is Sweet",
          amount: 5,
          price: 4,
          hasExpiryDate: true,
          category: doc.data().triggerKeywords,
          tag: doc.data().tag,
          downloadUrl: doc.data().recordingUrl,
          track: doc.data().track,
          isDelete: doc.data().isDelete,
          helperText: doc.data().helperText,
        });
      });
    });

  return productsData;
}

const initialState: IProductState = {
  modificationState: ProductModificationStatus.None,
  selectedProduct: null,
  products: getProductData(),
};

function productsReducer(
  state: any = initialState,
  action: IActionBase
): IProductState {
  switch (action.type) {
    case ADD_PRODUCT: {
      let maxId: number =
        state.products.length > 0
          ? Math.max.apply(
              Math,
              state.products.map(function (o) {
                return o.id;
              })
            )
          : 0;
      action.product.id = maxId + 1;
      firebase
        .firestore()
        .collection("recordings")
        .add({
          userEmail: action.product.email,
          projectName: action.product.project,
          recordingName: action.product.name,
          recordingUrl: action.product.downloadUrl,
          projectId: action.product.projectId,
          tag: action.product.tag,
          isDelete: action.product.isDelete,
          helperText: action.product.helperText
            ? action.product.helperText
            : "",
          uploadedRecording: action.product.uploadedRecording,
          trimmedRecording: action.product.trimmedRecording,
        })
        .then((res) => {
          action.product.firebaseId = res.id;
        });

      return { ...state, products: [...state.products, action.product] };
    }
    case EDIT_PRODUCT: {
      let obj = {
        recordingUrl: action.product.downloadUrl,
        uploadedRecording: action.product.uploadedRecording,
        trimmedRecording: action.product.trimmedRecording,
      };
      console.log("objobj", obj);
      firebase
        .firestore()
        .collection("recordings")
        .doc(action.product.firebaseId)
        .update({
          recordingUrl: action.product.downloadUrl,
          uploadedRecording: action.product.uploadedRecording,
          trimmedRecording: action.product.trimmedRecording,
        });
      const foundIndex: number = state.products.findIndex(
        (pr) => pr.firebaseId === action.product.firebaseId
      );
      let products: IProduct[] = state.products;
      products[foundIndex] = action.product;
      return { ...state };
    }
    case EDIT_TAG: {
      firebase.firestore().collection("recordings").doc(action.tag.id).update({
        track: action.tag.track,
      });

      let foundIndex = state.products.find(
        (pr) => pr.firebaseId === action.tag.id
      );

      let products = state.products;
      products[foundIndex] = action.product;
      if (foundIndex) {
        foundIndex.track = action.tag.track;
      }
      const foundIndex1: number = state.products.findIndex(
        (pr) => pr.firebaseId === action.tag.id
      );
      let products1: IProduct[] = state.products;
      products[foundIndex1] = foundIndex;
      return { ...state, products: products1 };
    }
    case REMOVE_PRODUCT: {
      firebase
        .firestore()
        .collection("recordings")
        .doc(action.product.firebaseId)
        .delete();
      return {
        ...state,
        products: state.products.filter(
          (pr) => pr.firebaseId !== action.product.firebaseId
        ),
      };
    }
    case CHANGE_PRODUCT_PENDING_EDIT: {
      return { ...state, selectedProduct: action.product };
    }
    case CLEAR_PRODUCT_PENDING_EDIT: {
      return { ...state, selectedProduct: null };
    }
    case SET_MODIFICATION_STATE: {
      return { ...state, modificationState: action.value };
    }
    case CHANGE_PRODUCT_AMOUNT: {
      const foundIndex: number = state.products.findIndex(
        (pr) => pr.id === action.id
      );
      let products: IProduct[] = state.products;
      products[foundIndex].amount = products[foundIndex].amount - action.amount;
      return { ...state, products: products };
    }
    default:
      return state;
  }
}

export default productsReducer;
