import { IProductState, IActionBase } from "../models/root.interface";
import {
  ADD_TEMPLATE,
  CHANGE_TEMPLATE_PENDING_EDIT,
  EDIT_TEMPLATE,
  REMOVE_TEMPLATE,
  CLEAR_TEMPLATE_PENDING_EDIT,
  SET_MODIFICATION_STATE,
} from "../actions/template.action";
import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
import firebase from "../../firebase";

let localUserEmail = localStorage.getItem("user_email");
const campaignRef = firebase.firestore().collection("adminTemplate");

function getCampaignData() {
  let templateData = [
    {
      id: "1",
      name: "Welcome",
      description: "This is Chocolate and it is Sweet",
      recordingArray: [],
      projectId: "",
      language: "",
      imgUrl: "",
    },
  ];

  campaignRef.get().then((snapshot) => {
    templateData.shift();
    let index = 0;
    if (snapshot.empty) {
      return;
    }
    snapshot.forEach((doc) => {
      templateData.push({
        id: doc.id,
        name: doc.data().name,
        description: doc.data().description,
        recordingArray: doc.data().recordingArray,
        projectId: doc.data().projectId,
        language: doc.data().language,
        imgUrl: doc.data().imgUrl,
      });
    });
  });
  return templateData;
}

const initialState: any = {
  modificationState: ProductModificationStatus.None,
  selectedProduct: null,
  template: getCampaignData(),
};

function templateReducer(
  state: any = initialState,
  action: IActionBase
): IProductState {
  switch (action.type) {
    case ADD_TEMPLATE: {
      action.template.imgUrl = action.template.imgUrl
        ? action.template.imgUrl
        : "";

      campaignRef.add(action.template).then((res) => {
        action.template.id = res.id;
      });
      return { ...state, template: [action.template, ...state.template] };
    }
    case EDIT_TEMPLATE: {
      campaignRef
        .doc(action.template.id)
        .update({
          recordingArray: action.template.recordingArray,
          name: action.template.name,
          description: action.template.description,
          projectId: action.template.projectId,
          language: action.template.language,
          imgUrl: action.template.imgUrl ? action.template.imgUrl : "",
        })
        .then((res) => {});

      const foundIndex: number = state.template.findIndex(
        (pr) => pr.id === action.template.id
      );
      let products: IProduct[] = state.template;
      products[foundIndex] = action.template;

      return { ...state, template: products };
    }
    case REMOVE_TEMPLATE: {
      campaignRef.doc(action.template).delete();
      return {
        ...state,
        template: state.template.filter((cp) => cp.id !== action.template),
      };
    }
    case CHANGE_TEMPLATE_PENDING_EDIT: {
      return { ...state, selectedProduct: action.template };
    }
    case CLEAR_TEMPLATE_PENDING_EDIT: {
      return { ...state, selectedProduct: null };
    }
    case SET_MODIFICATION_STATE: {
      return { ...state, modificationState: action.value };
    }

    default:
      return state;
  }
}

export default templateReducer;
