import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";
export const ADD_CAMPAIGNRUN: string = "ADD_CAMPAIGNRUN";
export const GET_CAMPAIGNRUN: string = "GET_CAMPAIGNRUN";

export function addCampaignRun(campaignRun: any): any {
  return { type: ADD_CAMPAIGNRUN, campaignRun: campaignRun };
}

export function getCamapaignRun(): any {
  return { type: GET_CAMPAIGNRUN };
}
export function setModificationState(
  value: ProductModificationStatus
): ISetModificationStateActionType {
  return { type: SET_MODIFICATION_STATE, value: value };
}

interface ISetModificationStateActionType {
  type: string;
  value: ProductModificationStatus;
}
