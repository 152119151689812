import { userType } from "./user.type";

const initialState = {
  token: null,
};

function mainUserReducer(state = initialState, action) {
  switch (action.type) {
    case userType.GET_TOKEN: {
      return { ...state, token: action.payload };
    }
    case userType.GET_GOOGLE_TOKEN: {
      return { ...state, token: action.payload };
    }
    default:
      return state;
  }
}

export default mainUserReducer;
