import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
export const ADD_MESSAGE: string = "ADD_MESSAGE";
export const EDIT_MESSAGE: string = "EDIT_MESSAGE";
export const REMOVE_MESSAGE: string = "REMOVE_MESSAGE";
export const CHANGE_PRODUCT_AMOUNT: string = "CHANGE_PRODUCT_AMOUNT";
export const CHANGE_PRODUCT_PENDING_EDIT: string =
  "CHANGE_PRODUCT_PENDING_EDIT";
export const CLEAR_PRODUCT_PENDING_EDIT: string = "CLEAR_PRODUCT_PENDING_EDIT";
export const SET_MODIFICATION_STATE1: string = "SET_MODIFICATION_STATE1";

export function addMessage(audience: any): any {
  return { type: ADD_MESSAGE, audience: audience };
}

export function editMessage(audience: any): any {
  return { type: EDIT_MESSAGE, audience: audience };
}

export function removeMessage(id: number): IRemoveProductActionType {
  return { type: REMOVE_MESSAGE, id: id };
}

export function changeProductAmount(
  id: number,
  amount: number
): IChangeProductAmountType {
  return { type: CHANGE_PRODUCT_AMOUNT, id: id, amount: amount };
}

export function changeSelectedProduct(
  product: IProduct
): IChangeSelectedProductActionType {
  return { type: CHANGE_PRODUCT_PENDING_EDIT, product: product };
}

export function clearSelectedProduct(): IClearSelectedProductActionType {
  return { type: CLEAR_PRODUCT_PENDING_EDIT };
}

export function setModificationState1(
  value: ProductModificationStatus
): ISetModificationStateActionType {
  return { type: SET_MODIFICATION_STATE1, value: value };
}

interface IAddProductActionType {
  type: string;
  product: IProduct;
}
interface IEditProductActionType {
  type: string;
  product: IProduct;
}
interface IRemoveProductActionType {
  type: string;
  id: number;
}
interface IChangeSelectedProductActionType {
  type: string;
  product: IProduct;
}
interface IClearSelectedProductActionType {
  type: string;
}
interface ISetModificationStateActionType {
  type: string;
  value: ProductModificationStatus;
}
interface IChangeProductAmountType {
  type: string;
  id: number;
  amount: number;
}
