import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { db } from "../../firebase";
import { addProduct, removeProduct } from "../../store/actions/products.action";
import { updateCurrentPath } from "../../store/actions/root.actions";
const Swal = require("sweetalert2");

function TemplateDetails() {
  let state = JSON.parse(localStorage.getItem("state"));
  const history = useHistory();
  //console.log(state);
  const email = localStorage.getItem("user_email");
  const projectRef = db.collection("projects");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateCurrentPath("templates", state.name));
    const callFn = async () => {
      await projectRef
        .where("userEmail", "==", localStorage.getItem("user_email"))
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.forEach((doc) => {
              localStorage.setItem("projectId", doc.data().projectId);
            });
          }
        });
    };
    callFn();
  }, []);

  const dataUpload = async (del) => {
    if (del) {
      db.collection("recordings")
        .where("userEmail", "==", email)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              if (doc.data().isDelete === false) {
                dispatch(removeProduct({ firebaseId: doc.id }));
              }
            });
          }
        });
    }
    localStorage.setItem("projectId", state.projectId);
    await projectRef
      .where("userEmail", "==", localStorage.getItem("user_email"))
      .get()
      .then((snap) => {
        if (snap.empty) {
          projectRef.add({
            projectId: state.projectId,
            userEmail: localStorage.getItem("user_email"),
            langCode: state.language,
          });
        } else {
          snap.forEach((doc) => {
            projectRef.doc(doc.id).update({
              projectId: state.projectId,
              langCode: state.language,
            });
          });
        }
      });

    await state.recordingArray.map((data) => {
      dispatch(
        addProduct({
          email: email,
          project: "default",
          name: data.recordingName,
          tag: data.recordingTag,
          projectId: state.projectId,
          isDelete: false,
          downloadUrl: "",
          helperText: data.helpingText,
          uploadedRecording: "",
          trimmedRecording: "",
        })
      );
    });

    history.push({ pathname: "/recordings" });
  };
  const saveData = async () => {
    let projectId = localStorage.getItem("projectId");
    if (projectId === state.projectId) {
      Swal.fire({
        title: "Warning!",
        text: "Already in use",
        icon: "warning",
        confirmButtonText: "Okay",
      });
    } else {
      if (projectId) {
        Swal.fire({
          title: "Warning!",
          text: "Another Template already in use, if you use this template,you would lose all you previous data",
          icon: "warning",
          showDenyButton: true,
          confirmButtonText: "Cancel",
          denyButtonText: `Proceed Anyway!`,
          denyButtonColor: "#7066e0",
          confirmButtonColor: "#dc3741",
          willClose: () => {
            return false;
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            return false;
          } else if (result.isDenied) {
            await dataUpload(true);
          }
        });
      } else {
        await dataUpload();
      }
    }
  };

  return (
    <>
      <div className="upDiv">
        <span className="spanLeft">{state.name}</span>
        <span className="spanRight">
          <button onClick={saveData} className=" btn btn-info">
            Use Template
          </button>
        </span>
      </div>
      <br />
      <div className="downDiv">
        <p>{state.description}</p>
      </div>
      <div className="imgSection">
        <img alt="" className="tempImg" src={state.imgUrl} />
      </div>
    </>
  );
}

export default TemplateDetails;
