import { audienceType } from "./audience.type";

const initialState = {
  audienceList: null,
  totalAudience: 0,
  totalContacts: 0,
};

function mainAudienceReducer(state = initialState, action) {
  switch (action.type) {
    case audienceType.GET_AUDIENCE_LIST: {
      console.log(action.payload);
      const { data, totalAudience, totalContacts } = action.payload;
      return {
        ...state,
        audienceList: data,
        totalAudience: totalAudience,
        totalContacts: totalContacts,
      };
    }

    default:
      return state;
  }
}

export default mainAudienceReducer;
