import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStateType } from "../../store/models/root.interface";
import { IAccount } from "../../store/models/account.interface";
import { db } from "../../firebase";
const LeftMenu: React.FC = () => {
  let [leftMenuVisibility, setLeftMenuVisibility] = useState(false);

  function changeLeftMenuVisibility() {
    setLeftMenuVisibility(!leftMenuVisibility);
  }

  function getCollapseClass() {
    return leftMenuVisibility ? "" : "collapsed";
  }

  const account: IAccount = useSelector((state: IStateType) => state.account);
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    const dataFetch = async () => {
      await db
        .collection("adminAccess")
        .get()
        .then((res) => {
          if (!res.empty) {
            res.forEach((doc) => {
              if (doc.data().userEmail === account.email) {
                if (doc.data().access === true) {
                  setVisibility(true);
                }
              }
            });
          }
        });
    };

    dataFetch();
  }, []);

  return (
    <Fragment>
      <div className="toggle-area">
        <button
          className="btn btn-primary toggle-button"
          onClick={() => changeLeftMenuVisibility()}
        >
          <i className="fas fa-bolt"></i>
        </button>
      </div>

      <ul
        className={`navbar-nav bg-gradient-primary-green sidebar sidebar-dark accordion ${getCollapseClass()}`}
        id="collapseMenu"
      >
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href={""}
          style={{ marginLeft: "-60px" }}
        >
          <div className="sidebar-brand-icon icon-green rotate-n-15">
            {/* <i className="fas fa-bolt" ></i> */}
            <img
              style={{ transform: "rotate(194deg)" }}
              height={"67px"}
              src="logo.png"
            />
          </div>
          <div className="sidebar-brand-text ml-2 patron">
            Vodex <sup></sup>
          </div>
        </a>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item active">
          <Link className="nav-link" to="dashboard">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Phone Bot</div>

        <li className="nav-item">
          <Link className="nav-link" to={`/templates`}>
            <i className="fas fa-fw fa-cog"></i>
            <span>Templates</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/prompt`}>
            <i className="fas fa-fw fa-cog"></i>
            <span>Prompt</span>
          </Link>
        </li>
        {/* <li className="nav-item">
                    <Link className="nav-link" to={`/orders`}>
                        <i className="fas fa-fw fa-phone"></i>
                        <span>Phone Bot</span>
                    </Link>
                </li> */}
        <li className="nav-item">
          <Link className="nav-link" to={`/recordings`}>
            <i className="fas fa-fw fa-headphones-alt"></i>
            <span>Recordings</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/whatsapp`}>
            <i className=" fa-fw fab fa-whatsapp"></i>
            <span>Whatsapp/SMS</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/audience`}>
            <i className="fas fa-fw fa-users"></i>
            <span>Audience</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/campaigns`}>
            <i className="fas fa-fw fa-user"></i>
            <span>Campaign</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Admin</div>

        {/* <li className="nav-item">
          <Link className="nav-link" to={`/users`}>
            <i className="fas fa-fw fa-user"></i>
            <span>Users</span>
          </Link>
        </li> */}

        <li className="nav-item">
          <Link className="nav-link" to={`/integration`}>
            <i className="fas fa-fw fa-cog"></i>
            <span>Integration</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/callsettings`}>
            <i className="fas fa-fw fa-phone-square-alt"></i>
            <span>Call Settings</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/advancesetting`}>
            <i className="fas fa-fw fa-cogs"></i>
            <span>Advance Settings</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/users`}>
            <i className="fas fa-fw fa-user-circle"></i>
            <span>Profile Settings</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Billing</div>

        {/* <li className="nav-item">
          <Link className="nav-link" to={`/prices`}>
            <i className="fas fa-fw fa-dollar-sign"></i>
            <span>Subscriptions</span>
          </Link>
        </li> */}

        <li className="nav-item">
          <Link className="nav-link" to={`/topupaccount`}>
            <i className="fas fa-fw fa-money-check-alt"></i>
            <span>Topup Account</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to={`/invoices`}>
            <i className="fas fa-fw fa-file-invoice-dollar"></i>
            <span>Invoices</span>
          </Link>
        </li>

        {visibility && (
          <>
            <hr className="sidebar-divider" />

            <div className="sidebar-heading">Super Admin</div>

            <li className="nav-item">
              <Link className="nav-link" to={`/upload-templates`}>
                <i className="fas fa-fw fa-cog"></i>
                <span>Upload Templates</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={`/whatsapp-settings`}>
                <i className="fas fa-fw fa-cog"></i>
                <span>Whatsapp Setting</span>
              </Link>
            </li>
            {account.email === "founders@lilchirp.io" && (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to={`/addbalance`}>
                    <i className="fas fa-fw fa-file-invoice-dollar"></i>
                    <span>Balance</span>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to={`/pricing`}>
                    <i className="fas fa-fw fa-file-invoice-dollar"></i>
                    <span>Pricing</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/coupon`}>
                    <i className="fas fa-fw fa-file-invoice-dollar"></i>
                    <span>Coupon</span>
                  </Link>
                </li>
              </>
            )}
          </>
        )}

        <hr className="sidebar-divider d-none d-md-block" />

        <li className="nav-item">
          <a
            target="_blank"
            className="nav-link"
            href={`https://lilchirp.canny.io/feature-requests`}
          >
            <i className="fas fa-fw fa-cube"></i>
            <span>Feature Request</span>
          </a>
        </li>
      </ul>
    </Fragment>
  );
};

export default LeftMenu;
