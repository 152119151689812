import React, { Fragment, Dispatch, useState, useEffect } from "react";
import ProductList from "./ProductsList";
import ProductForm from "./ProductsForm";
import TopCard from "../../common/components/TopCard";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IProductState,
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import Popup from "reactjs-popup";
import {
  removeProduct,
  clearSelectedProduct,
  setModificationState,
  changeSelectedProduct,
} from "../../store/actions/products.action";
import { addNotification } from "../../store/actions/notifications.action";
import {
  ProductModificationStatus,
  IProduct,
} from "../../store/models/product.interface";

const Products: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const products: IProductState = useSelector(
    (state: IStateType) => state.products
  );
  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );
  const numberItemsCount: number = products.products.length;
  const totalPrice: number = products.products.reduce(
    (prev, next) => prev + (next.price * next.amount || 0),
    0
  );
  const totalAmount: number = products.products.reduce(
    (prev, next) => prev + (next.amount || 0),
    0
  );
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    dispatch(clearSelectedProduct());
    dispatch(updateCurrentPath("recordings", "list"));
    return () => {
      dispatch(setModificationState(ProductModificationStatus.None));
    };
  }, [path.area, dispatch]);

  function onProductSelect(product: IProduct): void {
    dispatch(changeSelectedProduct(product));
    // dispatch(setModificationState(ProductModificationStatus.None));
  }

  function onProductRemove() {
    if (products.selectedProduct) {
      setPopup(true);
    }
  }

  //console.log(products.modificationState);
  //console.log(ProductModificationStatus.Create);

  return (
    <Fragment>
      <h1 className="h3 mb-2 text-gray-800">Recordings</h1>
      <p className="mb-4">Upload recordings here</p>
      <div className="row">
        <TopCard
          title="RECORDING COUNT"
          text={`${numberItemsCount}`}
          icon="box"
          class="primary"
        />
      </div>
      {products.modificationState === ProductModificationStatus.Create ||
      (products.modificationState === ProductModificationStatus.Edit &&
        products.selectedProduct) ? (
        <ProductForm />
      ) : null}

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">
                Recording List
              </h6>
              <div className="header-buttons">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    dispatch(
                      setModificationState(ProductModificationStatus.Create)
                    )
                  }
                >
                  <i className="fas fa fa-plus"></i> Add Recording
                </button>
                &nbsp;
                {/* <button className="btn btn-success btn-blue" onClick={() =>
                  dispatch(setModificationState(ProductModificationStatus.Edit))}>
                  <i className="fas fa fa-pen"></i>
                </button>
                <button className="btn btn-success btn-red" onClick={() => onProductRemove()}>
                  <i className="fas fa fa-times"></i>
                </button> */}
              </div>
            </div>
            <div className="card-body">
              <ProductList onSelect={onProductSelect} />
            </div>
          </div>
        </div>
      </div>

      {/* <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">
            Remove this recording?
          </div>
          <div className="popup-content">
            <button type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!products.selectedProduct) {
                  return;
                }
                dispatch(addNotification("Recording removed", `Recording ${products.selectedProduct.name} was removed`));
                dispatch(removeProduct(products.selectedProduct));
                dispatch(clearSelectedProduct());
                setPopup(false);
              }}>Remove
            </button>
          </div>
        </div>
      </Popup> */}
    </Fragment>
  );
};

export default Products;
