import React, { Fragment, Dispatch, useState, useEffect } from "react";
import AudienceList from "./AudienceList";
import ProductForm from "./AudienceForm";
import TopCard from "../../common/components/TopCard";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IProductState,
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import {
  clearSelectedProduct,
  setModificationState,
  changeSelectedProduct,
} from "../../store/actions/audience.action";
import {
  ProductModificationStatus,
  IProduct,
} from "../../store/models/product.interface";
import { getAudienceList } from "../../store/Store/Audience/audience.action";
import SkeletonLoding from "../../Constant/SkeletonLoding";

const Audience: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const products: IProductState = useSelector(
    (state: IStateType) => state.products
  );

  const reducer: any = useSelector((state) => state);
  const { mainAudienceReducer } = reducer;
  const { totalAudience, totalContacts, audienceList } = mainAudienceReducer;
  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );
  const [view, setView] = useState(true);
  const [spin, setSpin] = useState(true);
  useEffect(() => {
    dispatch(clearSelectedProduct());
    dispatch(updateCurrentPath("audience", "list"));
    return () => {
      dispatch(setModificationState(ProductModificationStatus.None));
    };
  }, [path.area, dispatch]);
  useEffect(() => {
    const callfn = async () => {
      await dispatch(getAudienceList());
      setSpin(false);
    };
    callfn();
  }, []);
  function onProductSelect(product: IProduct): void {
    dispatch(changeSelectedProduct(product));
    dispatch(setModificationState(ProductModificationStatus.None));
  }
  const onBack = (data) => setView(data);
  return !spin ? (
    <Fragment>
      <h1 className="h3 mb-2 text-gray-800">Audience</h1>
      <p className="mb-4">Manage your audience here</p>
      <div className="row">
        <TopCard
          title="Total Audience List"
          text={`${totalAudience || 0}`}
          icon="users"
          class="primary"
        />
        <TopCard
          title="Total Contacts"
          text={`${totalContacts || 0}`}
          icon="address-book"
          class="danger"
        />
      </div>
      {products.modificationState === ProductModificationStatus.Create ||
      (products.modificationState === ProductModificationStatus.Edit &&
        products.selectedProduct) ? (
        <ProductForm />
      ) : null}

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Audience List</h6>
              <div className="header-buttons">
                {view ? (
                  <>
                    <button
                      className="btn btn-success"
                      onClick={() =>
                        dispatch(
                          setModificationState(ProductModificationStatus.Create)
                        )
                      }
                    >
                      <i className="fas fa fa-plus"></i> New Audience List
                    </button>
                    &nbsp;
                  </>
                ) : (
                  <button
                    className="btn btn-info"
                    style={{ marginRight: "10px" }}
                    onClick={() => setView(true)}
                  >
                    Back
                  </button>
                )}
              </div>
            </div>
            <div className="card-body">
              <AudienceList
                onSelect={onProductSelect}
                view={view}
                onBack={onBack}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <SkeletonLoding />
  );
};

export default Audience;
