import React, {
  useState,
  FormEvent,
  Dispatch,
  Fragment,
  useEffect,
} from "react";
import { IStateType, IProductState } from "../../store/models/root.interface";
import { useSelector, useDispatch } from "react-redux";
import { ProductModificationStatus } from "../../store/models/product.interface";
import TextInput from "../../common/components/TextInput";
import {
  editProduct,
  clearSelectedProduct,
  setModificationState,
  addProduct,
} from "../../store/actions/products.action";
import { addNotification } from "../../store/actions/notifications.action";
import Checkbox from "../../common/components/Checkbox";
import SelectInput from "../../common/components/Select";
import {
  OnChangeModel,
  IProductFormState,
} from "../../common/types/Form.types";
import { DropzoneArea } from "material-ui-dropzone";
import firebase from "./../../firebase";

import Recorder from "react-mp3-recorder";
import ReactAudioPlayer from "react-audio-player";
import blobToBuffer from "blob-to-buffer";
import axios from "axios";
import Popup from "reactjs-popup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  swalSuccessMessage,
  swalWarningMessage,
} from "../../Constant/swalMessage";

const Swal = require("sweetalert2");

const ProductForm: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const products = useSelector((state: any) => state.products);
  let product = products.selectedProduct;

  const isCreate: boolean =
    products.modificationState === ProductModificationStatus.Create;

  if (!product || isCreate) {
    product = {
      id: 0,
      firebaseId: "",
      name: "",
      description: "",
      amount: 0,
      price: 0,
      hasExpiryDate: false,
      category: "",
      tag: "",
      downloadUrl: "",
    };
  }

  const [formState, setFormState] = useState({
    name: { error: "", value: product.name },
    description: { error: "", value: product.description },
    amount: { error: "", value: product.amount },
    price: { error: "", value: product.price },
    hasExpiryDate: { error: "", value: product.hasExpiryDate },
    category: { error: "", value: product.category },
    tag: { error: "", value: product.tag },
  });
  const [recording, setRecording] = useState(false);
  const [mouseLeft, setMouseLeft] = useState(false);
  const [uploadMethod, setUploadMethod] = useState("");
  const [recordingUrl, setRecordingUrl] = useState("");
  const [blob, setBlob] = useState<BlobPart>();
  const [popup, setPopup] = useState(false);
  const [uploadFile, setUploadFile] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const email: string = useSelector((state: IStateType) => state.account.email);
  const [uploadedRecording, setUploadedRecording] = useState("");
  const [trimmedRecording, setTrimmedRecording] = useState("");
  const [selectedRecording, setSelectedRecording] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);

  function hasFormValueChanged(model: OnChangeModel): void {
    if (model.field === "downloadedRecording") {
      setSelectedRecording(uploadedRecording);
      return;
    }
    if (model.field === "trimmedRecording") {
      setSelectedRecording(trimmedRecording);
      return;
    }

    if (model.field === "name") {
      if (/[^a-zA-Z0-9\&\ /]/.test(model.value.toString())) {
      } else {
        setFormState({
          ...formState,
          [model.field]: { error: model.error, value: model.value.toString() },
        });
      }
    } else {
      setFormState({
        ...formState,
        [model.field]: {
          error: model.error,
          value: model.value.toString().trim(),
        },
      });
    }
  }

  function handleUploadMethodChange(model: OnChangeModel): void {
    setUploadFile([]);
    setBlob(undefined);
    if (model.value === "Record an Audio") {
      setUploadMethod("Record");
    } else {
      setUploadMethod("Upload");
    }
  }

  function saveUser(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    let saveUserFn: Function = isCreate ? addProduct : editProduct;
    saveForm(formState, saveUserFn);
  }

  function saveForm(formState: IProductFormState, saveFn: Function): void {
    setLoading(true);
    if (blob) {
      setLoading(true);
      const recordingFile = new File(
        [blob],
        `${formState.name.value}-${formState.tag.value}.mp3`,
        {
          lastModified: 1534584790000,
        }
      );
      const storageRef = firebase.storage().ref(email);

      const fileRef = storageRef.child(
        `${formState.name.value}-${formState.tag.value}.mp3`
      );

      fileRef
        .put(recordingFile)
        .then(async () => {
          fileRef.getDownloadURL().then(async (downloadUrl) => {
            const urlParams = new URL(downloadUrl).searchParams;
            const token = urlParams.get("token");
            let obj = {
              email: email,
              file: downloadUrl.split("?")[0],
              token: token,
            };
            setUploadedRecording(downloadUrl);
            await axios
              .post("https://silence-removal.api.vodex.ai/silence", obj)
              .then((res) => {
                setTrimmedRecording(res.data.path);
                setPopup(true);
              })
              .catch((err) => {
                saveRecording1(downloadUrl);
              });
          });
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(true);

      const storageRef = firebase.storage().ref(email);

      if (uploadFile.length < 1) {
        setLoading(false);

        Swal.fire({
          title: "Warning!",
          text: "Please upload or record an audio first",
          icon: "warning",
          confirmButtonText: "Okay",
        });
        return;
      }
      setLoading(true);

      const fileRef = storageRef.child(
        `${uploadFile[0].name}-${formState.tag.value}`
      );
      fileRef.put(uploadFile[0]).then(() => {
        fileRef.getDownloadURL().then((downloadUrl) => {
          const urlParams = new URL(downloadUrl).searchParams;
          const token = urlParams.get("token");
          let obj = {
            email: email,
            file: downloadUrl.split("?")[0],
            token: token,
          };
          setUploadedRecording(downloadUrl);
          axios
            .post("https://silence-removal.api.vodex.ai/silence", obj)
            .then((res) => {
              setTrimmedRecording(res.data.path);
              setPopup(true);
            })
            .catch((err) => {
              saveRecording1(downloadUrl);
            });
        });
      });
    }
  }

  const saveRecording1 = (downloadUrl) => {
    let saveFn: Function = isCreate ? addProduct : editProduct;
    setButtonDisable(true);
    if (product) {
      dispatch(
        saveFn({
          ...product,
          email: email,
          project: "default",
          name: formState.name.value,
          uploadedRecording: uploadedRecording || "",
          trimmedRecording: trimmedRecording || "",
          downloadUrl: downloadUrl,
          tag: formState.tag.value,
          isDelete: product.isDelete
            ? product.isDelete
            : isCreate
            ? true
            : false,
          projectId: isCreate && localStorage.getItem("projectId"),
        })
      );

      dispatch(
        addNotification(
          "Recording created",
          `Recording ${formState.name.value} created by you`
        )
      );
      setButtonDisable(false);
      setLoading(false);
      dispatch(clearSelectedProduct());
      dispatch(setModificationState(ProductModificationStatus.None));
    }
    cancelForm();
    swalSuccessMessage("Recording Uploaded");
  };
  const saveRecording = (e) => {
    e.preventDefault();
    let saveFn: Function = isCreate ? addProduct : editProduct;
    if (!selectedRecording) {
      swalWarningMessage("Please choose a recording first");
      return;
    }
    setButtonDisable(true);
    if (product) {
      dispatch(
        saveFn({
          ...product,
          email: email,
          project: "default",
          name: formState.name.value,
          uploadedRecording: uploadedRecording,
          trimmedRecording: trimmedRecording,
          downloadUrl: selectedRecording,
          tag: formState.tag.value,
          isDelete: product.isDelete
            ? product.isDelete
            : isCreate
            ? true
            : false,
          projectId: isCreate && localStorage.getItem("projectId"),
        })
      );

      dispatch(
        addNotification(
          "Recording created",
          `Recording ${formState.name.value} created by you`
        )
      );
      setButtonDisable(false);
      setLoading(false);
      dispatch(clearSelectedProduct());
      dispatch(setModificationState(ProductModificationStatus.None));
    }
    cancelForm();
    swalSuccessMessage("Recording Uploaded");
  };

  function cancelForm(): void {
    dispatch(setModificationState(ProductModificationStatus.None));
  }

  function mouseEffect(): void {
    setMouseLeft(!mouseLeft);
    setRecording(!recording);
  }

  function onRecordingComplete(blob): void {
    setRecording(!recording);
    setBlob(blob);
    blobToBuffer(blob, (err, buffer) => {
      if (err) {
        console.error(err);
        return;
      }
      setRecording(!recording);
      if (recordingUrl) {
        window.URL.revokeObjectURL(recordingUrl);
      }
      setRecordingUrl(window.URL.createObjectURL(blob));
    });
  }

  function onRecordingError(): void {
    if (recordingUrl) {
      window.URL.revokeObjectURL(recordingUrl);
    }
    setRecordingUrl("");
  }
  return (
    <Fragment>
      <div className="col-xl-8 col-lg-8">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">
              {isCreate ? "Create a new" : product.name} Recording
            </h6>
          </div>
          <div className="card-body">
            <form onSubmit={saveUser}>
              {isCreate && (
                <div>
                  <div className="form-group">
                    <TextInput
                      id="input_email"
                      value={formState.name.value}
                      field="name"
                      onChange={hasFormValueChanged}
                      required={true}
                      maxLength={30}
                      label="Give this recording a name"
                      placeholder="e.g. Welcome Message"
                    />
                  </div>
                  <div className="form-group">
                    <TextInput
                      id="tag"
                      value={formState.tag.value}
                      field="tag"
                      onChange={hasFormValueChanged}
                      required={true}
                      maxLength={20}
                      label="Enter tag here"
                      placeholder="e.g. welcome"
                    />
                  </div>
                </div>
              )}

              <div className="form-group">
                <SelectInput
                  id="input_category"
                  field="category"
                  label="How would you provide a recording?"
                  options={["Upload a Recording File", "Record an Audio"]}
                  required={true}
                  onChange={handleUploadMethodChange}
                  value={formState.category.value}
                />
              </div>

              {uploadMethod === "Upload" && (
                <div className="form-row">
                  <DropzoneArea
                    onDelete={() => {
                      setUploadFile([]);
                    }}
                    acceptedFiles={["audio/mp3", "audio/MP3", "audio/mpeg"]}
                    dropzoneText={"Drag and drop recording file here or click"}
                    onChange={(file) =>
                      file.length > 0 ? setUploadFile(file) : null
                    }
                    filesLimit={1}
                  />
                </div>
              )}

              {uploadMethod === "Record" && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      minHeight: "40vh",
                    }}
                  >
                    <div>
                      <Recorder
                        onMouseDown={mouseEffect}
                        onMouseUp={() => setMouseLeft(mouseLeft)}
                        onRecordingComplete={onRecordingComplete}
                        onRecordingError={onRecordingError}
                        style={{
                          margin: "0 auto",
                        }}
                      />
                      <br />
                      <br />
                      {/* {recording ? "Recording........." : "Click and hold to start recording."} */}
                      {!recording
                        ? mouseLeft
                          ? "Click and Hold to Record Again."
                          : "Click and Hold to Start Recording."
                        : "Recording..."}
                      {/* <p>Click and hold to start recording.</p> */}
                      <br />
                      <br />
                      {recordingUrl && (
                        <div>
                          <ReactAudioPlayer
                            src={recordingUrl}
                            controls
                            controlsList="nodownload"
                            style={{
                              minWidth: "500px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group">
                <Checkbox
                  id="checkbox_expiry"
                  field="hasExpiryDate"
                  value={formState.hasExpiryDate.value}
                  label="Make this method default"
                  onChange={hasFormValueChanged}
                />
              </div>
              <button className="btn btn-danger" onClick={() => cancelForm()}>
                Cancel
              </button>
              <button
                type="submit"
                className={`btn btn-success ml-1`}
                disabled={loading}
              >
                {loading ? "Saving" : "Save"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Popup
        contentStyle={{ height: "350px", minWidth: "400px" }}
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="popup-modal">
          <div className="popup-title">Recordings Overview</div>
          <div className="popup-content">
            <form onSubmit={saveRecording}>
              <div className="m-3">
                <div className="d-flex m-1">
                  <span style={{ float: "left", marginTop: "3px" }}>
                    {" "}
                    Uploaded Recording
                  </span>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        This is uploaded recording
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle m-2"
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    ></i>
                  </OverlayTrigger>
                </div>
                <div className="d-flex">
                  <Checkbox
                    id="checkbox_expiry"
                    field="downloadedRecording"
                    value={
                      selectedRecording === uploadedRecording ? true : false
                    }
                    label=""
                    onChange={hasFormValueChanged}
                  />{" "}
                  <ReactAudioPlayer
                    src={uploadedRecording}
                    controls
                    style={{
                      minWidth: "40px",
                      height: "30px",
                    }}
                  />
                </div>
              </div>
              <div className="m-3">
                <div className="d-flex m-1">
                  <span style={{ float: "left", marginTop: "3px" }}>
                    Enhanced Recording{" "}
                  </span>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {" "}
                        This is Enhanced recording
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle m-2"
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    ></i>
                  </OverlayTrigger>
                </div>
                <div className="d-flex">
                  <Checkbox
                    id="checkbox_expiry"
                    field="trimmedRecording"
                    value={
                      selectedRecording === trimmedRecording ? true : false
                    }
                    label=""
                    onChange={hasFormValueChanged}
                  />

                  <ReactAudioPlayer
                    src={trimmedRecording}
                    controls
                    style={{
                      minWidth: "40px",
                      height: "30px",
                    }}
                  />
                </div>
              </div>
              <br />
              <button
                style={{ width: "30%" }}
                className="btn btn-success"
                type="submit"
              >
                {buttonDisable ? "Saving" : "Save"}
              </button>
            </form>
          </div>
        </div>
      </Popup>
    </Fragment>
  );
};

export default ProductForm;
