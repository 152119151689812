import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart() {
  const [state, setState] = useState({
    datesArray: [],
    campaignDates: [],
    showDates: {},
    max: 10,
  });
  const reducer: any = useSelector((state: any) => state);
  const { campaignRun } = reducer;
  const { datesArray, campaignDates, showDates, max } = state;

  useEffect(() => {
    let today = moment().format("YYYY-MM-DD");
    let sevenDayDates: any = [today];
    for (let i = 1; i < 8; i++) {
      const yesterday = moment().subtract(i, "days").format("YYYY-MM-DD");
      sevenDayDates.push(yesterday);
    }
    setState((prev) => ({
      ...prev,
      datesArray: sevenDayDates.reverse(),
    }));
  }, []);

  useEffect(() => {
    let campaign_Dates: any = [];
    campaignRun.campaigns.forEach((camp) => {
      let today = moment(camp.timeStamp).format("YYYY-MM-DD");
      campaign_Dates.push(today);
    });

    setState((prev) => ({
      ...prev,
      campaignDates: campaign_Dates,
    }));
  }, []);

  useEffect(() => {
    let con: any = {};
    for (const element of campaignDates) {
      if (con[element]) {
        con[element] += 1;
      } else {
        con[element] = 1;
      }
    }

    let arr: any = Object.values(con);

    let _max = Math.max(...arr);

    setState((prev) => ({
      ...prev,
      showDates: con,
      max: _max,
    }));
  }, [datesArray, campaignDates]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        min: 0,
        max: max > 10 ? max : 10,
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const labels = datesArray;

  const data = {
    labels,
    datasets: [
      {
        label: "Campaign Runs",
        data: datesArray.map((d) => showDates[d] || 0),
        backgroundColor: "green",
      },
    ],
  };

  return <Bar height={"180vh"} options={options} data={data} />;
}

export default BarChart;
