import { IProductState, IActionBase } from "../models/root.interface";
import {
  ADD_AUDIENCE,
  CHANGE_PRODUCT_PENDING_EDIT,
  CLEAR_PRODUCT_PENDING_EDIT,
  SET_MODIFICATION_STATE,
  CHANGE_PRODUCT_AMOUNT,
  REMOVE_AUDIENCE,
  ADD_AUDIENCE12,
  EDIT_AUDIENCE,
  FETCH_AUDIENCE,
} from "../actions/audience.action";
import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
import firebase from "../../firebase";

let localUserEmail = localStorage.getItem("user_email");

function getAudienceData() {
  let audienceData = [
    {
      id: "1",
      name: "Welcome",
      description: "This is Chocolate and it is Sweet",
      amount: 5,
      price: 4,
      hasExpiryDate: true,
      list: [],
    },
  ];

  const recordingRef = firebase.firestore().collection("audience");
  recordingRef
    .where("userEmail", "==", localUserEmail)
    .get()
    .then((snapshot) => {
      audienceData.shift();
      let index = 0;
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach((doc) => {
        audienceData.push({
          id: doc.id,
          name: doc.data().audienceListName,
          description: "This is Chocolate and it is Sweet",
          amount: 5,
          price: 4,
          hasExpiryDate: true,
          list: doc.data().audienceList,
        });
      });
    });

  return audienceData;
}

const initialState: any = {
  modificationState: ProductModificationStatus.None,
  selectedProduct: null,
  audience: getAudienceData(),
};

// products: [{
//     id: 1, name: "Welcome", description: "This is Chocolate and it is Sweet",
//     amount: 5, price: 4, hasExpiryDate: true, category: "Welcome"
// },
// {
//     id: 2, name: "Pitch Product", description: "This is Apple and it is healthy",
//     amount: 5, price: 2, hasExpiryDate: true, category: "Pitch"
// },
// {
//     id: 3, name: "Affirm", description: "This is Straw and you can use it for your drink",
//     amount: 100, price: 1, hasExpiryDate: false, category: "Affirm"
// },
// {
//     id: 4, name: "Deny", description: "This is Spoon and it is useful while eating",
//     amount: 3, price: 2, hasExpiryDate: false, category: "Deny"
// },
// {
//     id: 5, name: "Good Bye", description: "This is Sugar and it is to make your life sweet",
//     amount: 15, price: 5, hasExpiryDate: true, category: "Good Bye"
// }]

function productsReducer(state: any = initialState, action: IActionBase): any {
  switch (action.type) {
    case ADD_AUDIENCE: {
      // let maxId: number = Math.max.apply(Math, state.products.map(function(o) { return o.id; }));
      // action.product.id = maxId + 1;

      firebase
        .firestore()
        .collection("audience")
        .add({
          audienceListName: action.audience.name,
          source: "manual",
          audienceList: action.audience.list,
          userEmail: action.audience.email,
          projectId: localStorage.getItem("ProjectId"),
          tag: localStorage.getItem("Tags"),
        })
        .then((res) => {
          action.audience.id = res.id;
        });

      return { ...state, audience: [action.audience, ...state.audience] };
    }
    case ADD_AUDIENCE12: {
      return { ...state, audience: [action.audience, ...state.audience] };
    }
    case EDIT_AUDIENCE: {
      firebase
        .firestore()
        .collection("audience")
        .doc(action.product.id)
        .update({
          audienceList: action.product.list,
          audienceListName: action.product.name,
        })
        .then((res) => {});

      const foundIndex: number = state.audience.findIndex(
        (pr) => pr.id === action.product.id
      );
      let products: IProduct[] = state.audience;
      products[foundIndex] = action.product;

      return { ...state, audience: products };
    }
    case REMOVE_AUDIENCE: {
      firebase.firestore().collection("audience").doc(action.id).delete();

      return {
        ...state,
        audience: state.audience.filter((pr) => pr.id !== action.id),
      };

      // return { ...state, products: state.products.filter(pr => pr.id !== action.id) };
    }
    case CHANGE_PRODUCT_PENDING_EDIT: {
      return { ...state, selectedProduct: action.product };
    }
    case CLEAR_PRODUCT_PENDING_EDIT: {
      return { ...state, selectedProduct: null };
    }
    case SET_MODIFICATION_STATE: {
      return { ...state, modificationState: action.value };
    }
    case CHANGE_PRODUCT_AMOUNT: {
      const foundIndex: number = state.products.findIndex(
        (pr) => pr.id === action.id
      );
      let products: IProduct[] = state.products;
      products[foundIndex].amount = products[foundIndex].amount - action.amount;
      return { ...state, products: products };
    }
    case FETCH_AUDIENCE: {
      return { ...state, audience: getAudienceData() };
    }
    default:
      return state;
  }
}

export default productsReducer;
