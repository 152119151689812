import { IProductState, IActionBase } from "../models/root.interface";
import {
  ADD_CAMPAIGN,
  CHANGE_PRODUCT_PENDING_EDIT,
  EDIT_CAMPAIGN,
  REMOVE_CAMPAIGN,
  CLEAR_PRODUCT_PENDING_EDIT,
  SET_MODIFICATION_STATE,
  CHANGE_PRODUCT_AMOUNT,
} from "../actions/campaign.action";
import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
import firebase from "../../firebase";

let localUserEmail = localStorage.getItem("user_email");

function getCampaignData() {
  let campaignData = [
    {
      id: "1",
      name: "Welcome",
      description: "This is Chocolate and it is Sweet",
      amount: 5,
      price: 4,
      hasExpiryDate: true,
      listName: "Welcome",
      botPlatform: "dialogflow",
    },
  ];

  const campaignRef = firebase.firestore().collection("campaigns");
  campaignRef
    .where("userEmail", "==", localUserEmail)
    .get()
    .then((snapshot) => {
      campaignData.shift();
      let index = 0;
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach((doc) => {
        if (!doc.data().isDelete) {
          campaignData.push({
            id: doc.id,
            name: doc.data().campaignName,
            description: "This is Chocolate and it is Sweet",
            amount: 5,
            price: 4,
            hasExpiryDate: true,
            listName: doc.data().audienceListName,
            botPlatform: doc.data().botPlatform
              ? doc.data().botPlatform
              : "dialogflow",
          });
        }
      });
    });
  return campaignData;
}

const initialState: any = {
  modificationState: ProductModificationStatus.None,
  selectedProduct: null,
  campaigns: getCampaignData(),
};

// products: [{
//     id: 1, name: "Welcome", description: "This is Chocolate and it is Sweet",
//     amount: 5, price: 4, hasExpiryDate: true, category: "Welcome"
// },
// {
//     id: 2, name: "Pitch Product", description: "This is Apple and it is healthy",
//     amount: 5, price: 2, hasExpiryDate: true, category: "Pitch"
// },
// {
//     id: 3, name: "Affirm", description: "This is Straw and you can use it for your drink",
//     amount: 100, price: 1, hasExpiryDate: false, category: "Affirm"
// },
// {
//     id: 4, name: "Deny", description: "This is Spoon and it is useful while eating",
//     amount: 3, price: 2, hasExpiryDate: false, category: "Deny"
// },
// {
//     id: 5, name: "Good Bye", description: "This is Sugar and it is to make your life sweet",
//     amount: 15, price: 5, hasExpiryDate: true, category: "Good Bye"
// }]

function campaignReducer(
  state: any = initialState,
  action: IActionBase
): IProductState {
  switch (action.type) {
    case ADD_CAMPAIGN: {
      firebase
        .firestore()
        .collection("campaigns")
        .add({
          campaignName: action.campaign.name,
          source: "manual",
          audienceListName: action.campaign.listName,
          userEmail: action.campaign.email,
          botPlatform: action.campaign.botPlatform,
        })
        .then((res) => {
          action.campaign.id = res.id;
        });
      return { ...state, campaigns: [action.campaign, ...state.campaigns] };
    }
    case EDIT_CAMPAIGN: {
      const foundIndex: number = state.products.findIndex(
        (pr) => pr.id === action.product.id
      );
      let products: IProduct[] = state.products;
      products[foundIndex] = action.product;
      return { ...state, products: products };
    }
    case REMOVE_CAMPAIGN: {
      var campaignsRef = firebase.firestore().collection("campaigns");

      campaignsRef.doc(action.campaignName).delete();

      // campaignsRef
      //   .where("userEmail", "==", localUserEmail)
      //   .where("campaignName", "==", action.campaignName)
      //   .get()
      //   .then((snapshot) => {
      //     snapshot.forEach((doc) => campaignsRef.doc(doc.id).delete());
      //   })
      //   .catch((err) => {
      //     console.log("Error getting documents", err);
      //   });
      return {
        ...state,
        campaigns: state.campaigns.filter(
          (cp) => cp.id !== action.campaignName
        ),
      };
    }
    case CHANGE_PRODUCT_PENDING_EDIT: {
      return { ...state, selectedProduct: action.product };
    }
    case CLEAR_PRODUCT_PENDING_EDIT: {
      return { ...state, selectedProduct: null };
    }
    case SET_MODIFICATION_STATE: {
      return { ...state, modificationState: action.value };
    }
    case CHANGE_PRODUCT_AMOUNT: {
      const foundIndex: number = state.products.findIndex(
        (pr) => pr.id === action.id
      );
      let products: IProduct[] = state.products;
      products[foundIndex].amount = products[foundIndex].amount - action.amount;
      return { ...state, products: products };
    }
    default:
      return state;
  }
}

export default campaignReducer;
