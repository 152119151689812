import { API, axiosClient, BASE_URL_API } from "../apiConstant";

export const getTokenData = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.LOGIN.GET_TOKEN}`,
      data
    );
    if (res?.status === 200) {
      return res?.data?.token;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getGoogleTokenData = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.LOGIN.GOOGLE_TOKEN}`,
      { id: data }
    );
    if (res?.status === 200) {
      return res?.data?.token;
    }
  } catch (err) {
    console.log(err);
  }
};
export const profileCheck = async (data) => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API}${API.USER.PROFILE_CHECK}`);
    if (res?.status === 200) {
      return res?.data;
    }
  } catch (err) {
    console.log(err);
  }
};
