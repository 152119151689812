import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import * as scheduleAction from "../../store/Store/Schedule/schedule.action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ScheduleList({ campaignId }) {
  const reducer = useSelector((state) => state);
  const {
    scheduleReducer: { scheduleList },
  } = reducer;
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      await dispatch(scheduleAction.getScheduleCampaign(campaignId));
    };
    init();
  }, [dispatch]);

  const [state, setState] = useState({
    scheduleLocalList: [],
    spin: false,
    buttonDisable: false,
    popup: false,
    id: "",
  });
  const { scheduleLocalList, spin, buttonDisable, popup, id } = state;

  useEffect(() => {
    if (scheduleList) {
      setState((prev) => ({
        ...prev,
        scheduleLocalList: scheduleList,
        spin: true,
      }));
    }
  }, [scheduleList]);

  const deleteSchedule = async () => {
    await dispatch(scheduleAction.deleteScheduleCampaign(id));
    await dispatch(scheduleAction.getScheduleCampaign(campaignId));
    setState((prev) => ({ ...prev, popup: false, id: "" }));
  };
  const productElements = () => {
    return scheduleLocalList.map((campaign, idx) => (
      <tr key={idx}>
        <th scope="row">{idx + 1}</th>
        <td>{campaign?.time.split(" ")[0]}</td>
        <td>{campaign?.time.split(" ")[1]}</td>
        <td>{campaign?.timezone}</td>
        <td>
          {campaign.completed === false
            ? "Pending"
            : campaign?.result?.status === 200
            ? "Completed"
            : "Failed"}
          {campaign?.result?.status !== 200 && (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{campaign?.result?.errMsg}</Tooltip>}
            >
              <i
                className="fa fa-info-circle m-2"
                style={{ fontSize: "20px", cursor: "pointer" }}
              ></i>
            </OverlayTrigger>
          )}
        </td>
        <td>
          <button
            className="btn btn-danger"
            onClick={() =>
              setState((prev) => ({ ...prev, popup: true, id: campaign.id }))
            }
          >
            Delete
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="table-responsive portlet">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Schedule Date</th>
            <th scope="col">Time</th>
            <th scope="col">Timezone</th>
            <th scope="col">Status</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {scheduleLocalList.length > 0 ? (
            productElements()
          ) : (
            <tr className={"table-row"}>
              {spin && (
                <td style={{ minWidth: 300 }}>
                  No Campaigns Scheduled. Click on "Schedule" to schedule a new
                  campaign
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>

      {!spin && (
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Loading ...
        </div>
      )}

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setState((prev) => ({ ...prev, popup: false, id: "" }))}
        closeOnDocumentClick={buttonDisable ? false : true}
      >
        <div className="popup-modal" style={{ height: "90px" }}>
          <div className="popup-title" style={{ height: "73px" }}>
            Are you sure you want to delete selected scheduled campaign?
          </div>
          <div className="popup-content">
            <div style={{ marginTop: "30px" }}>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteSchedule()}
                disabled={buttonDisable}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default ScheduleList;
