import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { swalSuccessMessage } from "../../Constant/swalMessage";
import "./Prompt.css";

function Prompt() {
  const [msg, setMsg] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const init = async () => {
      setLoading(true);

      await db
        .collection("promptMessage")
        .where("userEmail", "==", localUserEmail)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            if (doc.exists) {
              setMsg(doc.data().message);
              setId(doc.id);
            }
          });
        });
      setLoading(false);
    };
    init();
  }, []);
  let localUserEmail = localStorage.getItem("user_email");

  const savePrompt = async (e) => {
    e.preventDefault();
    setLoading(true);
    // if (id) {
    //   db.collection("promptMessage")
    //     .doc(id)
    //     .update({
    //       message: msg,
    //     })
    //     .then((res) => {
    //       swalSuccessMessage("Message  Updated");
    //       setLoading(false);
    //     });
    // } else {
    //   db.collection("promptMessage")
    //     .add({
    //       message: msg,
    //       userEmail: localUserEmail,
    //     })
    //     .then((res) => {
    //       swalSuccessMessage("Message  Uploaded");
    //       setLoading(false);
    //     });
    // }

    await db
      .collection("promptMessage")
      .where("userEmail", "==", localUserEmail)
      .get()
      .then(async (res) => {
        if (res.empty) {
          let res1 = await db.collection("promptMessage").add({
            message: msg,
            userEmail: localUserEmail,
          });

          swalSuccessMessage("Message  Uploaded");
          setLoading(false);
        } else {
          res.forEach((doc) => {
            db.collection("promptMessage")
              .doc(doc.id)
              .update({
                message: msg,
              })
              .then((res) => {
                swalSuccessMessage("Message  Updated");
                setLoading(false);
              });
          });
        }
      });
  };
  return (
    <>
      <h1 className="h3 mb-2 text-gray-800">Prompt </h1>
      <p className="mb-4">Add your Prompt message here</p>

      <>
        <div>
          <div className="card shadow mb-4 full-width">
            <div className="card-body">
              <form onSubmit={savePrompt}>
                <label>Message</label>
                <div className="form-group">
                  <textarea
                    className="form-control full-height"
                    id="input_email"
                    value={msg}
                    name="name"
                    onChange={(e) => setMsg(e.target.value)}
                    required={true}
                  />
                </div>
                <span>
                  <button
                    type="submit"
                    className={`btn btn-success ml-1 w-200`}
                    disabled={loading}
                  >
                    Save
                  </button>
                </span>
                <span className="float-right">
                  <a
                    className="btn btn-info mr-3"
                    href={`https://links.vodex.ai/chatgpt-prompt-generator?email=${localUserEmail}`}
                    target="_blank"
                  >
                    Generate Prompt
                  </a>
                  <a
                    className="btn btn-primary"
                    href={`https://links.vodex.ai/chatgpt-prompt-tester?email=${localUserEmail}`}
                    target="_blank"
                  >
                    Test Prompt
                  </a>
                </span>
              </form>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default Prompt;
