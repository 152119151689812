import React, { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import { swalSuccessMessage } from "../../Constant/swalMessage";
import { db } from "../../firebase";
import * as campaignAction from "../../store/Store/campaigns/campaigns.action";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { addManualAudienceListExcel } from "../../store/Store/Audience/audience.action";
import { DropzoneArea } from "material-ui-dropzone";

function ProductList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const reducer: any = useSelector((state) => state);
  const { mainCampaignReducer, audience, campaignRun } = reducer;
  const user_email = localStorage.getItem("user_email");
  const { campaignsList } = mainCampaignReducer;

  const campaignsRuns = campaignRun.campaigns;
  const [state, setState] = useState<any>({
    campaignLocalList: [],
    spin: false,
    selectedCampaign: { id: "", campaignName: "" },
    popup: false,
    buttonDisable: false,
    deleteButton: false,
    file: [],
    uploadPopup: false,
    campaignData: {},
    excelSpin: false,
  });
  const {
    campaignLocalList,
    spin,
    popup,
    selectedCampaign,
    buttonDisable,
    deleteButton,
    file,
    uploadPopup,
    campaignData,
    excelSpin,
  } = state;

  console.log("campaignsListcampaignsList", campaignsList);
  useEffect(() => {
    let campaignsList1 = [];
    if (campaignsList) {
      campaignsList1 = campaignsList;
    }
    setState((prev) => ({
      ...prev,
      campaignLocalList: campaignsList1,
      spin: true,
    }));
  }, [campaignsList]);

  const goToCampaignRunList = async (campaign) => {
    localStorage.setItem("campaign", JSON.stringify(campaign));
    history.push(`/campaign-runs?${campaign?.id}`);
  };

  const buttonDisablefun = (value) => {
    setState((prev) => ({
      ...prev,
      buttonDisable: value,
    }));
  };

  const checkCampaignAlreadyRan = (id) => {
    let camp_find = campaignsRuns.find((o) => o.campaignId === id);
    if (camp_find) {
      return false;
    } else {
      return true;
    }
  };
  const arrayListFind = (listName) => {
    let arr: any = [];
    if (Array.isArray(listName)) {
      listName.forEach((val) => {
        let kl = audience.audience.find((data) => data.id === val);
        if (kl) {
          arr.push(...kl.list);
        }
      });
      return arr;
    } else {
      let audienceArray = audience.audience.find((o) => o.id === listName);
      if (audienceArray) {
        return audienceArray.list;
      }
    }
  };

  const checkCallerId = async (listName) => {
    let callerRes = false;
    let list: any = arrayListFind(listName);
    await db
      .collection("callerId")
      .where("userEmail", "==", user_email)
      .get()
      .then((res) => {
        if (res.empty) {
          callerRes = true;
        }
        res.forEach((doc) => {
          if (doc.exists) {
            let callerId = list.find(
              (o) => o.phone.toString() === doc.data().callerIdNo.toString()
            );
            if (callerId) {
              return false;
            } else {
              callerRes = true;
            }
          } else {
            callerRes = true;
          }
        });
      });
    if (!callerRes) {
      buttonDisablefun(false);
    }
    return callerRes;
  };

  const runCampaign = async (campaign) => {
    Swal.fire({
      title: "Choose",
      text: "Choose the service provider (test)",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Voximplant",
      denyButtonText: `Vodex`,
      denyButtonColor: "#7066e0",
      confirmButtonColor: "#dc3741",
      willClose: () => {
        buttonDisablefun(false);
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        buttonDisablefun(true);
      } else if (result.isDenied) {
        let res: any = await dispatch(
          campaignAction.triggerCampaign1(campaign?.id)
        );
        if (res?.status === 200) {
          let obj = {
            type: "main",
            timeStamp: res?.timeStamp,
          };
          localStorage.setItem("campaign", JSON.stringify(campaign));
          localStorage.setItem("campaignRuns", JSON.stringify(obj));
          history.push(`/campaign-run-details?${res?.campaignRunId}`);
        } else {
          setState((prev) => ({
            ...prev,
            buttonDisable: false,
          }));
        }
        return;
      }
    });

    buttonDisablefun(true);

    return;
    if (checkCampaignAlreadyRan(campaign?.id)) {
      await runCampaignWithCallerIdCheck(campaign);
    } else {
      Swal.fire({
        title: "Warning!",
        text: "The campaign has already run, do you want to run it again?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Cancel",
        denyButtonText: `Proceed Anyway!`,
        denyButtonColor: "#7066e0",
        confirmButtonColor: "#dc3741",
        willClose: () => {
          buttonDisablefun(false);
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          buttonDisablefun(false);
          return false;
        } else if (result.isDenied) {
          await runCampaignWithCallerIdCheck(campaign);
        }
      });
    }
  };

  const runCampaignWithCallerIdCheck = async (campaign) => {
    buttonDisablefun(true);
    let calledRes = await checkCallerId(campaign?.audienceListName);
    if (calledRes) {
      await finalCampaignRun(campaign);
    } else {
      Swal.fire({
        title: "Warning!",
        text: "List contains Caller Id number, Do you want to proceed?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Cancel",
        denyButtonText: `Proceed Anyway!`,
        denyButtonColor: "#7066e0",
        confirmButtonColor: "#dc3741",
        willClose: () => {
          buttonDisablefun(false);
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          buttonDisablefun(false);
        } else if (result.isDenied) {
          await finalCampaignRun(campaign);
        }
      });
    }
  };

  const goToSchedule = (data) => {
    localStorage.setItem("scheduleCampaignData", JSON.stringify(data));
    history.push("/schedule-campaign");
  };
  const finalCampaignRun = async (campaign) => {
    buttonDisablefun(true);
    let res: any = await dispatch(campaignAction.triggerCampaign(campaign?.id));
    if (res?.status === 200) {
      let obj = {
        type: "main",
        timeStamp: res?.timeStamp,
      };
      localStorage.setItem("campaign", JSON.stringify(campaign));
      localStorage.setItem("campaignRuns", JSON.stringify(obj));
      history.push(`/campaign-run-details?${res?.campaignRunId}`);
    } else {
      setState((prev) => ({
        ...prev,
        buttonDisable: false,
      }));
    }
  };

  const shortenString = (text, max) => {
    if (text && text.length > max) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="text-tooltip" id={`tooltip_${Date.now()}`}>
              {text}
            </Tooltip>
          }
        >
          <span>{`${text.slice(0, max)}...`}</span>
        </OverlayTrigger>
      );
    }
    return text;
  };

  const downloadSampleExcel = (campaign) => {
    let list: any = arrayListFind(campaign?.audienceListName);
    if (list.length > 0) {
      let final: any = [];
      for (const item of list) {
        let obj = {
          FirstName: item.firstName,
          LastName: item.firstName,
          PhoneNum: item.phone,
        };
        final.push(obj);
      }
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(final);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Campaign List file` + fileExtension);
    }
  };

  const uploadExcel = async () => {
    setState((prev) => ({
      ...prev,
      excelSpin: true,
    }));
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("campaignId", campaignData.id);
    await dispatch(addManualAudienceListExcel(formdata));
    setState((prev) => ({
      ...prev,
      excelSpin: false,
    }));
    toggleModal();
  };

  const openExcelPopup = (campaign) => {
    setState((prev) => ({
      ...prev,
      campaignData: campaign,
      uploadPopup: true,
    }));
  };
  const productElements = () => {
    return campaignLocalList.map((campaign: any, idx) => (
      <tr key={idx}>
        <th scope="row">{idx + 1}</th>
        <td>{campaign?.campaignName}</td>
        <td>{shortenString(campaign?.audienceList?.name, 20)}</td>
        <td className="text-center">{campaign?.audienceList?.count}</td>
        <td>
          <div className="btn-group">
            <button
              type="button"
              disabled={buttonDisable}
              className="btn btn-warning"
              onClick={() => runCampaign(campaign)}
            >
              Run Campaign
            </button>

            {/* <button
              type="button"
              className="btn btn-warning dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">Dropdown toggle</span>
            </button> */}
            {/* <div className="dropdown-menu">
              <button
                className="dropdown-item"
                onClick={() => goToSchedule(campaign)}
              >
                Schedule For Later
              </button>
              {/* <Link className="dropdown-item" to="/schedule-campaign">
                Schedule For Later
              </Link> */}
            {/* <Link className="dropdown-item" to="/comingsoon">
                Campaign Settings
              </Link>
            </div>  */}
          </div>{" "}
        </td>

        <td>
          <button
            className="btn btn-info"
            onClick={() => goToSchedule(campaign)}
          >
            Schedule For Later
          </button>
        </td>
        <td>
          <button
            className="btn btn-info"
            onClick={() => goToCampaignRunList(campaign)}
          >
            View Campaign Runs
          </button>
        </td>
        <td>
          <div className="d-flex">
            <button
              disabled={excelSpin}
              className="mr-1 btn btn-info"
              onClick={() => openExcelPopup(campaign)}
            >
              Upload Excel
            </button>

            <button
              className="btn btn-danger"
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  selectedCampaign: campaign,
                  popup: true,
                }));
              }}
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    ));
  };

  const deleteCampaign = async () => {
    setState((prev) => ({
      ...prev,
      deleteButton: true,
    }));
    let res: any = await dispatch(
      campaignAction.deleteCampaignById(selectedCampaign?.id)
    );
    if (res) {
      await dispatch(campaignAction.getCampaignPageWidgetsCount());
      await dispatch(campaignAction.getCampaignList());
      setState((prev) => ({
        ...prev,
        popup: false,
        selectedCampaign: { id: "", campaignName: "" },
      }));
      swalSuccessMessage("Campaign Deleted Successfully");
    }
    setState((prev) => ({
      ...prev,
      deleteButton: false,
    }));
  };

  const onSave = (_file) => {
    let saveFil: any = _file.length > 0 ? _file[0] : null;
    setState((prev) => ({
      ...prev,
      file: saveFil,
    }));
  };

  const toggleModal = () => {
    setState((prev) => ({
      ...prev,
      uploadPopup: !prev.uploadPopup,
    }));
  };
  return (
    <div className="table-responsive portlet">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Campaign Name</th>
            <th scope="col">Audience List Name</th>
            <th scope="col">Audience Count</th>
            <th scope="col">Run Campaign</th>
            <th scope="col">Schedule Campaign</th>
            <th scope="col">View Campaign Runs</th>
            <th scope="col" style={{ textAlign: "center" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {campaignLocalList.length > 0 ? (
            productElements()
          ) : (
            <tr className={"table-row"}>
              {spin && (
                <td style={{ minWidth: 300 }}>
                  No Campaign. Click on "Add Campaign" to add a new campaign
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>

      {!spin && (
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Loading ...
        </div>
      )}

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setState((prev) => ({ ...prev, popup: false }))}
        closeOnDocumentClick={deleteButton ? false : true}
      >
        <div className="popup-modal" style={{ height: "90px" }}>
          <div className="popup-title" style={{ height: "73px" }}>
            Remove {selectedCampaign?.campaignName} campaign?
          </div>
          <div className="popup-content">
            <div style={{ marginTop: "30px" }}>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteCampaign()}
                disabled={deleteButton}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </Popup>

      <Modal show={uploadPopup} onHide={toggleModal}>
        <Modal.Header closeButton>Upload Excel</Modal.Header>
        <Modal.Body>
          <button
            title="download sample excel"
            className="ml-1 btn btn-link"
            onClick={() => downloadSampleExcel(campaignData)}
          >
            <i className="fas fa-download mr-1"></i>Download Campaign Audience
            File
          </button>
          <DropzoneArea
            acceptedFiles={[
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
            ]}
            dropzoneText={
              "Drag and drop excel file here or click"
              // : "Data is not in proper format"
            }
            onChange={(file) => onSave(file)}
            filesLimit={1}
          />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={toggleModal} className="btn btn-danger">
            Cancel
          </button>
          <button
            onClick={uploadExcel}
            disabled={excelSpin}
            className="btn btn-success"
          >
            {excelSpin ? "Uploading..." : "Upload"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductList;
