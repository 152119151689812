import * as campaignService from "../../../Constant/services/campaign.service";
import { swalSuccessMessage } from "../../../Constant/swalMessage";
import { campaignsType } from "./campaigns.type";
export const getCampaignList = () => async (dispatch) => {
  let res = await campaignService.getCampaign();
  if (res) {
    await dispatch({
      type: campaignsType.GET_CAMPAIGN_LIST,
      payload: res.data,
    });
  }
};

export const getRunCampaignList = (id, count) => async (dispatch) => {
  let res = await campaignService.getRunCampaign(id, count);
  if (res) {
    await dispatch({
      type: campaignsType.GET_RUN_CAMPAIGN_LIST,
      payload: res,
    });
  }
};

export const getCampaignDetailsList = (id, filterData) => async (dispatch) => {
  let res = await campaignService.getCampaignRunDetails(id, filterData);
  if (res) {
    await dispatch({
      type: campaignsType.GET_RUN_CAMPAIGN_DETAILS_LIST,
      payload: res,
    });
  }
};

export const getCampaignPageWidgetsCount = () => async (dispatch) => {
  let res = await campaignService.getCampaignPageWidgetsCount();
  if (res) {
    await dispatch({
      type: campaignsType.GET_CAMPAIGN_WIDGET_COUNT,
      payload: res,
    });
  }
};

export const getCampaignRunPageWidgetsCount = (id) => async (dispatch) => {
  let res = await campaignService.getCampaignRunsPageWidgetsCount(id);
  if (res) {
    await dispatch({
      type: campaignsType.GET_CAMPAIGN_RUN_WIDGET_COUNT,
      payload: res,
    });
  }
};

export const getCampaignRunDetailsPageWidgetsCount =
  (id) => async (dispatch) => {
    let res = await campaignService.getCampaignRunsDetailsPageWidgetsCount(id);
    if (res) {
      await dispatch({
        type: campaignsType.GET_CAMPAIGN_RUN_DETAILS_WIDGET_COUNT,
        payload: res,
      });
    }
  };
export const triggerCampaign = (id) => async (dispatch) => {
  let res = await campaignService.triggerCampaign(id);
  if (res) {
    swalSuccessMessage("Calling Numbers");
    return res;
  }
};

export const triggerCampaign1 = (id) => async (dispatch) => {
  let res = await campaignService.triggerCampaign1(id);
  if (res) {
    swalSuccessMessage("Calling Numbers");
    return res;
  }
};
export const exportExcelFile = (id, filterData) => async (dispatch) => {
  let res = await campaignService.exportExcelFile(id, filterData);
  if (res) {
    return res;
  }
};

export const saveCampaign = (data) => async (dispatch) => {
  let res = await campaignService.saveCampaign(data);
  if (res) {
    return res;
  }
};

export const deleteCampaignById = (id) => async (dispatch) => {
  let res = await campaignService.deleteCampaignById(id);
  if (res) {
    return res;
  }
};

export const saveFilterdCampaign = (data) => async (dispatch) => {
  let res = await campaignService.saveFilterdCampaign(data);
  if (res) {
    return res;
  }
};
