import React, { Component } from "react";
import axios from "axios";
import { db } from "../../../firebase/index";
import "./Integration.css";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../Constant/swalMessage";
const Swal = require("sweetalert2");
let mount = false;

class FileUpload extends Component {
  state = {
    spin: false,
    mountData: this.readData,
    buttonText: "Preparing your bot... Please wait...",
    languageCode: localStorage.getItem("langCode"),
    languageCode1: localStorage.getItem("langCode"),
    projectStyle: {
      padding: "3px",
      border: "1px solid #ced4da",
      width: "30%",
      borderRadius: "4px",
    },
    show: false,
    show1: false,
    buttonShow: false,
    buttonShow1: false,
    botPlatform: "",
    id: "",
  };

  componentDidMount() {
    mount = true;
    this.readData();
  }
  readData = () => {
    db.collection("projects")
      .where("userEmail", "==", localStorage.getItem("user_email"))
      .get()
      .then((snap) => {
        if (snap.empty) {
          return false;
        } else {
          snap.forEach((doc) => {
            this.setState({
              projectId: doc.data().projectId,
              languageCode: doc.data().langCode,
              languageCode1: doc.data().langCode,
              show: true,
              show1: true,
              botPlatform: doc.data().botPlatform,
              id: doc.id,
            });

            return true;
          });
        }
      });
  };

  onChangeHandler = (event) => {
    var file = event.target.files[0];

    if (!file.name.match(/\.(json|JSON)$/)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Upload Json File",
      });
      return false;
    } else {
      if (this.validateSize(event)) {
        this.setState({
          selectedFile: file,
        });
      }
    }
  };

  fileUploadHandler = (e) => {
    e.preventDefault();
    const data = new FormData();
    if (this.state.selectedFile === undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Upload File",
      });
    } else {
      if (!this.state.selectedFile.name.match(/\.(json|JSON)$/)) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Upload Json File",
        });
        return false;
      }

      data.append("file", this.state.selectedFile);
      this.setState({ spin: true, buttonShow: true });
      axios
        .post(`${process.env.REACT_APP_BASE_DIALOGFLOW}`, data)
        .then((res) => {
          this.setState({ projectId: res.data.projectId });
          db.collection("projects")
            .where("userEmail", "==", localStorage.getItem("user_email"))
            .get()
            .then((snap) => {
              if (snap.empty) {
                db.collection("projects").add({
                  projectId: res.data.projectId,
                  userEmail: localStorage.getItem("user_email"),
                });
              } else {
                snap.forEach((doc) => {
                  db.collection("projects").doc(doc.id).update({
                    projectId: res.data.projectId,
                  });
                });
              }
            });
          swalSuccessMessage("File Uploaded");

          this.setState({ buttonShow: false, show: true, spin: false });
        });
    }
  };

  validateSize = (event) => {
    let file = event.target.files[0];
    let size = 30000;
    let err = "";
    if (file.size > size) {
      err = file.type + "is too large, please pick a smaller file\n";
    }
    return true;
  };
  uploadBotPlatform = (botPlatform) => {
    if (this?.state?.id) {
      db.collection("projects")
        .doc(this.state.id)
        .update({
          botPlatform: botPlatform,
        })
        .then((res) => {});
    } else {
      db.collection("projects")
        .add({
          botPlatform: botPlatform,
          userEmail: localStorage.getItem("user_email"),
        })
        .then((res) =>
          this.setState({
            botPlatform: res.id,
          })
        );
    }
  };

  render() {
    const {
      projectId,
      show,
      show1,
      languageCode,
      buttonShow,
      buttonShow1,
      languageCode1,
      botPlatform,
    } = this.state;

    return (
      <div className="container ml-0">
        <span className="mb-10">
          <label>Please Select Platform</label>
          <select
            placeholder="Select Bot Platform"
            className="form-control w-20"
            onChange={(e) => {
              this.setState({
                botPlatform: e.target.value,
              });
              this.uploadBotPlatform(e.target.value);
            }}
            value={this.state.botPlatform}
          >
            <option value={""}>Choose...</option>
            <option value={"chatgpt"}>Chat GPT</option>
            <option value={"dialogflow"}>Dialogflow</option>
            <option value={"rasa"}>Rasa</option>
          </select>
        </span>
        {botPlatform === "dialogflow" && (
          <div style={{ marginTop: "29px" }}>
            <div>
              <h4 className="ml-2">Dialogflow integration</h4>
            </div>

            <div className="row">
              <div className="col">
                {show ? (
                  <div>
                    <div className="card-footer m-1">
                      Project Id : <strong>{projectId}</strong>
                    </div>
                    <button
                      className="btn btn-info m-2"
                      onClick={() => this.setState({ show: false })}
                    >
                      Edit Project Id
                    </button>
                  </div>
                ) : (
                  <form className="card m-2" onSubmit={this.fileUploadHandler}>
                    <div className="card-header">
                      Connect with Dialogflow. Upload JSON Key file here
                    </div>
                    <div className="card-body">
                      <input
                        style={{ height: "50px" }}
                        required
                        type="file"
                        name="file"
                        className="form-control "
                        onChange={this.onChangeHandler}
                      />

                      <a
                        href="https://dialogflow.cloud.google.com/#/getStarted"
                        className="btn btn-link"
                        target="_blank"
                      >
                        Click here to go to Dialogflow
                      </a>
                    </div>
                    <div className="card-footer">
                      <button
                        className="btn btn-success m-1"
                        disabled={buttonShow}
                        type="submit"
                      >
                        {buttonShow ? "Uploading" : "Upload"}
                      </button>
                      <button
                        className="btn btn-danger m-1"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ show: true });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
                <hr />
              </div>
              <div className="col">
                <iframe
                  style={{ marginLeft: "8%" }}
                  width="420"
                  height="315"
                  src="https://www.youtube.com/embed/CXF9YlKhNUU?controls=0"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FileUpload;

{
  /* <form method="post" action="#" id="#">
<div>
  <label></label>

  <br />
  <br />
  <label>Language code </label>
  <input
    value={this.state.languageCode}
    required
    placeholder="Enter language code"
    type="text"
    name="language code"
    className="form-control"
    onChange={(e) =>
      this.setState({ languageCode: e.target.value })
    }
  />
</div>
<div>
  <button
    className="btn btn-success"
    variant="success"
    disabled={this.state.spin}
    onClick={this.fileUploadHandler}
  >
    {this.state.spin ? "Uploading" : " Upload File"}
  </button>
</div>
{/* <br /> */
  //}
  //{/* <br />; */}
  //{
  /* <div style={this.state.projectStyle}>
  <h5>
    Project ID:
    {`${this.state.projectId ? this.state.projectId : ""}`}
  </h5>
  <hr />
//   <h5> Language Code : {this.state.languageCode}</h5>
// </div> */
}
// </form> */}
