import { API, axiosClient, BASE_URL_API } from "../apiConstant";
import { swalErrorMessage, swalSuccessMessage } from "../swalMessage";

export const addAudience = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.AUDIENCE.AUDIENCE}`,
      { ...data }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addAudienceExcel = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.AUDIENCE.EXCEL_AUDIENCE}`,
      data
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getAudience = async () => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API}${API.AUDIENCE.AUDIENCE}`);
    if (res?.status === 200) {
      return res.data;
    } else {
      swalErrorMessage(res?.msg);
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const editAudience = async (data) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API}${API.AUDIENCE.AUDIENCE}/${data.id}`,
      {
        ...data.audienceInformation,
      }
    );
    if (res?.status === 200) {
      return res.data;
    } else {
      swalErrorMessage(res?.msg);
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const deleteAudience = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API}${API.AUDIENCE.AUDIENCE}/${id}`
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const deleteCampaignById = async (id) => {
  try {
    console.log(id);
    let res = await axiosClient.delete(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGN1}/${id}`
    );
    if (res?.data?.status === 200) {
      return res.data;
    } else {
      swalErrorMessage(res?.data?.errMsg);
    }
  } catch (err) {
    console.log("errerrerrerr", err);
    throw err;
  }
};

export const addManualAudienceExcel = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.AUDIENCE.EXCEL_NEW_AUDIENCE}`,
      data
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
