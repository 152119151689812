import React, { Fragment, Dispatch, useState, useEffect } from "react";
import CampaignList from "./TemplateList";
import TemplateForm from "./TemplateForm";
import TopCard from "../../../common/components/TopCard";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../../store/actions/root.actions";
import {
  IProductState,
  IStateType,
  IRootPageStateType,
} from "../../../store/models/root.interface";
import Popup from "reactjs-popup";
import {
  removeTemplate,
  clearSelectedProduct,
  setModificationState,
  changeSelectedProduct,
} from "../../../store/actions/template.action";
import { addNotification } from "../../../store/actions/notifications.action";
import {
  ProductModificationStatus,
  IProduct,
} from "../../../store/models/product.interface";
import { useHistory } from "react-router-dom";
import { IAccount } from "../../../store/models/account.interface";
import { db } from "../../../firebase";
const Swal = require("sweetalert2");

const Templates: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const products: IProductState = useSelector(
    (state: IStateType) => state.products
  );
  const templates: any = useSelector((state: any) => state.templateReducer);
  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );

  const [popup, setPopup] = useState(false);
  const adminAccess = [];

  useEffect(() => {
    dispatch(clearSelectedProduct());
    dispatch(updateCurrentPath("templates", "list"));
  }, [path.area, dispatch]);

  function onProductSelect(product: IProduct): void {
    dispatch(changeSelectedProduct(product));
  }

  function onProductRemove() {
    if (products.selectedProduct) {
      setPopup(true);
    }
  }
  const account: IAccount = useSelector((state: IStateType) => state.account);
  const history = useHistory();
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    const dataFetch = async () => {
      await db
        .collection("adminAccess")
        .get()
        .then((res) => {
          if (!res.empty) {
            res.forEach((doc) => {
              if (doc.data().userEmail === account.email) {
                if (doc.data().access === true) {
                  setVisibility(true);
                }
              }
            });
          }
        });
    };

    dataFetch();
  }, []);

  // useEffect(() => {
  //   if (account.email === "founders@lilchirp.io") {
  //   } else {
  //     history.push("/");
  //   }
  // }, []);

  return visibility ? (
    <Fragment>
      <h1 className="h3 mb-2 text-gray-800">Templates</h1>
      <p className="mb-4">Build your template here</p>
      <div className="row">
        <TopCard
          title="Total Templates"
          text={`${templates.template.length}`}
          icon="box"
          class="primary"
        />
      </div>
      {templates.modificationState === ProductModificationStatus.Create ||
      (templates.modificationState === ProductModificationStatus.Edit &&
        templates.selectedProduct) ? (
        <TemplateForm />
      ) : null}

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">
                Templates List
              </h6>
              <div className="header-buttons">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    dispatch(
                      setModificationState(ProductModificationStatus.Create)
                    )
                  }
                >
                  <i className="fas fa fa-plus"></i> Add Template
                </button>
                &nbsp;
                {/* <button className="btn btn-success btn-blue" onClick={() =>
                  dispatch(setModificationState(ProductModificationStatus.Edit))}>
                  <i className="fas fa fa-pen"></i>
                </button> */}
                {/* <button className="btn btn-success btn-red" onClick={() => onProductRemove()}>
                  <i className="fas fa fa-times"></i>
                </button> */}
              </div>
            </div>
            <div className="card-body">
              <CampaignList onSelect={onProductSelect} />
            </div>
          </div>
        </div>
      </div>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">Remove this campaign?</div>
          <div className="popup-content">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!products.selectedProduct) {
                  return;
                }
                dispatch(
                  addNotification(
                    "Campaign removed",
                    `Campaign ${templates.selectedProduct.name} was removed`
                  )
                );
                dispatch(removeTemplate(templates.selectedProduct.id));
                dispatch(clearSelectedProduct());
                setPopup(false);
                Swal.fire({
                  title: "Successful!",
                  text: "Campaign removed",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
    </Fragment>
  ) : null;
};

export default Templates;
