import * as scheduleAction from "../../../Constant/services/schedule.service";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../Constant/swalMessage";
import { scheduleType } from "./schedule.type";

export const scheduleCampaign = (data) => async () => {
  let res = await scheduleAction.scheduleCampaign(data);
  console.log("resresres", res);
  if (res.status === 200) {
    swalSuccessMessage(res.msg);
  } else {
    swalErrorMessage(res.msg);
  }
};
export const getScheduleCampaign = (data) => async (dispatch) => {
  let res = await scheduleAction.getScheduleCampaign(data);
  if (res) {
    await dispatch({
      type: scheduleType.GET_SCHEDULE_LIST,
      payload: res.data,
    });
  }
};

export const deleteScheduleCampaign = (data) => async (dispatch) => {
  let res = await scheduleAction.deleteScheduleCampaign(data);
  if (res.status === 200) {
    swalSuccessMessage(res.msg);
  } else {
    swalErrorMessage(res.data.errMsg);
  }
};
