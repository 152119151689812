import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import timezones from "timezones-list";
import Select from "react-select";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  swalErrorMessage,
  swalSuccessMessage,
  swalWarningMessage,
} from "../../Constant/swalMessage";
import ScheduleList from "./ScheduleList";
import * as scheduleAction from "../../store/Store/Schedule/schedule.action";
import "./ScheduleCampaign.css";
import Swal from "sweetalert2";

function ScheduleCampaign() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [timeZoneOptions, setTimeZoneoptions] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  let locaStorageData = JSON.parse(
    localStorage.getItem("scheduleCampaignData")
  );
  const timeOptions = [
    { label: "00:00", value: "00:00:00" },
    { label: "01:00", value: "01:00:00" },
    { label: "02:00", value: "02:00:00" },
    { label: "03:00", value: "03:00:00" },
    { label: "04:00", value: "04:00:00" },
    { label: "05:00", value: "05:00:00" },
    { label: "06:00", value: "06:00:00" },
    { label: "07:00", value: "07:00:00" },
    { label: "08:00", value: "08:00:00" },
    { label: "09:00", value: "09:00:00" },
    { label: "10:00", value: "10:00:00" },
    { label: "11:00", value: "11:00:00" },
    { label: "12:00", value: "12:00:00" },
    { label: "13:00", value: "13:00:00" },
    { label: "14:00", value: "14:00:00" },
    { label: "15:00", value: "15:00:00" },
    { label: "16:00", value: "16:00:00" },
    { label: "17:00", value: "17:00:00" },
    { label: "18:00", value: "18:00:00" },
    { label: "19:00", value: "19:00:00" },
    { label: "20:00", value: "20:00:00" },
    { label: "21:00", value: "21:00:00" },
    { label: "22:00", value: "22:00:00" },
    { label: "23:00", value: "23:00:00" },
  ];
  useEffect(() => {
    let kl = [];
    timezones.forEach((dat) => {
      let newDat = { ...dat };
      newDat["value"] = dat.tzCode;
      kl.push(newDat);
    });
    setTimeZoneoptions(kl);
  }, []);

  const validateTime = (finalmomentObj) => {
    const enteredTime = moment(finalmomentObj);
    const currentTime = moment(); // This will give you the current time

    if (enteredTime.isBefore(currentTime)) {
      swalWarningMessage("please enter valid time");
      return false;
    } else {
      if (selectedTimeZone) {
        return true;
      } else {
        swalWarningMessage("please select timezone");
      }
    }
  };
  const saveScheduleCampaign = async () => {
    if (!startDate) {
      swalWarningMessage("Please Select Date");
      return;
    }
    if (!startTime) {
      swalWarningMessage("Please Select Time");
      return;
    }
    if (!selectedTimeZone) {
      swalWarningMessage("please select timezone");
      return true;
    }

    Swal.fire({
      title: "Warning!",
      text: "Please keep sufficient balance in your account to run the campaign.",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Cancel",
      denyButtonText: `Schedule`,
      denyButtonColor: "#7066e0",
      confirmButtonColor: "#dc3741",
      willClose: () => {
        setButtonDisable(false);
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setButtonDisable(false);
        return false;
      } else if (result.isDenied) {
        setButtonDisable(true);
        // const momentObj = moment(startTime);
        const time = startTime.value;
        const momentObj1 = moment(startDate);
        const date = momentObj1.format("YYYY-MM-DD");
        let obj = {
          timeZone: selectedTimeZone?.tzCode,
          time: `${date} ${time}`,
          campaignId: locaStorageData?.id,
        };
        await dispatch(scheduleAction.scheduleCampaign(obj));
        setStartDate(new Date());
        setStartTime(null);
        setSelectedTimeZone("");
        await dispatch(scheduleAction.getScheduleCampaign(locaStorageData?.id));
        setButtonDisable(false);
      }
    });
  };
  console.log("startDate", startDate);
  return (
    <>
      <h1 className="h3 mb-2 text-gray-800 text-capitalize">
        Schedule {locaStorageData?.campaignName} Campaign
      </h1>
      <p className="mb-4">schedule your campaign here</p>
      <div className="row m-1 mb-5">
        <div className="col">
          <DatePicker
            minDate={new Date()}
            className="form-control"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Click to select date"
          />
        </div>
        <div className="col">
          {/* <DatePicker
            className="form-control width50"
            selected={startTime}
            onChange={(date) => setStartTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            placeholderText="Click to select time"
          /> */}
          <Select
            value={startTime}
            options={timeOptions}
            onChange={(val) => {
              console.log(val);
              setStartTime(val);
            }}
            placeholder="Select Time"
          />
        </div>
        <div className="col">
          <Select
            value={selectedTimeZone}
            options={timeZoneOptions}
            onChange={(val) => {
              setSelectedTimeZone(val);
            }}
            placeholder="Select Timezone"
          />
        </div>
        <div className="col">
          <button
            className="btn btn-success"
            disabled={buttonDisable}
            onClick={saveScheduleCampaign}
          >
            Schedule
          </button>
        </div>
      </div>

      <div className="m-3">
        <ScheduleList campaignId={locaStorageData?.id} />
      </div>
    </>
  );
}

export default ScheduleCampaign;
