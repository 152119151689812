import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart() {
  const campaignsRunsDetails: any = useSelector(
    (state: any) => state.campaignRunDetails
  );

  const [state, setState] = useState({
    connectedCall: 0,
    failedCall: 0,
    totalCalls: 0,
  });
  const { connectedCall, failedCall, totalCalls } = state;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  useEffect(() => {
    if (campaignsRunsDetails.campaigns.length > 0) {
      let connect_Call = 0;
      let failed_Call = 0;
      campaignsRunsDetails.campaigns.forEach((da) => {
        if (
          da.callStatus === "Call Disconnected" ||
          da.callStatus === "Disconnected"
        ) {
          connect_Call = connect_Call + 1;
        }
        if (da.callStatus === "Call Failed") {
          failed_Call = failed_Call + 1;
        }
      });

      setState((prev) => ({
        ...prev,
        connectedCall: connect_Call,
        failedCall: failed_Call,
        totalCalls: campaignsRunsDetails.campaigns.length,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        connectedCall: 1,
        failedCall: 1,
        totalCalls: 1,
      }));
    }
  }, []);

  let da = ["No Data"];
  let show = false;
  if (connectedCall === 1 && failedCall === 1) {
    show = true;
  }

  const data = {
    labels: show ? da : ["Call Connected", "Call Failed"],
    datasets: [
      {
        label: "# Calls",
        data: show ? [1] : [connectedCall, failedCall],
        backgroundColor: show ? ["#808080"] : ["green", "red"],
        borderColor: show ? ["#808080"] : ["green", "red"],
        borderWidth: 1,
      },
    ],
  };

  return <Pie width={200} data={data} options={options} />;
}

export default PieChart;
