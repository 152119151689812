import React, {
  useState,
  useContext,
  useRef,
  Dispatch,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import firebase from "firebase/app";
import { auth, db } from "../../firebase";
import "./NewLogin.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./css/util.css";
import "./css/main.css";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../Constant/swalMessage";
import { login } from "../../store/actions/account.actions";
import { getGoogleToken, getToken } from "../../store/Store/User/user.action";
import axios from "axios";
import Checkbox from "../../common/components/Checkbox";
import { getTermsConditionsUrl } from "../../Constant/CommonService/Common.service";
import { Link } from "react-router-dom";

function Register1() {
  const dispatch = useDispatch();
  const [spin, setSpin] = useState(false);
  const adminRef = db.collection("userPricingConfig");

  const [googleButtonDisable, setGooogleButtonDisable] = useState(false);
  const [state, setState] = useState<any>({
    email: "",
    password: "",
    cpassword: "",
    agree: "",
    url: "",
  });
  const [msg, setMsg] = useState("");

  const { email, password, cpassword, agree, url } = state;

  useEffect(() => {
    setTimeout(() => setMsg(""), 5000);
  }, [msg !== ""]);
  useEffect(() => {
    const init = async () => {
      let res: any = await dispatch(getTermsConditionsUrl());
      console.log(res);
      setState((prev) => ({
        ...prev,
        url: res,
      }));
    };
    init();
  }, []);
  const uploadLanCodeAndBotPlatform = async () => {
    console.log("I am herere");
    await db
      .collection("projects")
      .where("userEmail", "==", email)
      .get()
      .then(async (res) => {
        console.log("Resss", res.empty);
        if (res.empty) {
          let res1 = await db.collection("projects").add({
            userEmail: email,
            botPlatform: "chatgpt",
            langCode: "en",
          });
          console.log(res1);
        }
      });
  };

  const uploadRecordings = async () => {
    let arr = [
      {
        name: "Greeting",
        tag: "greeting",
      },
      {
        name: "Fallback",
        tag: "fallback",
      },
    ];

    await Promise.all(
      arr.map(async (tag) => {
        await db
          .collection("recordings")
          .add({
            userEmail: email,
            projectName: "default",
            recordingName: tag.name,
            recordingUrl: "",
            projectId: null,
            tag: tag.tag,
            isDelete: true,
            helperText: "",
            uploadedRecording: "",
            trimmedRecording: "",
          })
          .then((res) => {});
      })
    );
  };
  const registerWithEmailAndPassword = async (e) => {
    e.preventDefault();
    if (!agree) {
      setMsg(`Please accept terms and conditions.`);
      return;
    }
    if (password === cpassword) {
      setMsg(``);
      setSpin(true);
      try {
        let balance = 1;
        const res = await auth.createUserWithEmailAndPassword(email, password);
        const user = res.user || { uid: "" };
        let obj = {
          email: email,
          password: password,
        };
        await uploadLanCodeAndBotPlatform();
        await uploadRecordings();
        await dispatch(getToken(obj));
        await axios.get("https://ipapi.co/json/").then(async (response) => {
          let data = response.data;
          let curr = {
            currency: "usd",
            unit: "min",
            userEmail: email,
          };
          if (data.country_name === "India") {
            curr.currency = "inr";
            balance = 100;
          }
          await adminRef.add(curr);
        });

        await db
          .collection("accountBalance")
          .where("userEmail", "==", email)
          .get()
          .then((res) => {
            if (res.empty) {
              db.collection("accountBalance")
                .add({
                  userEmail: email,
                  balance: balance,
                  updated: true,
                })
                .then((res) => {
                  const url = process.env.REACT_APP_API_BASE_URL || "";

                  axios
                    .post(url.concat("sendregisteremail"), { email: email })
                    .then((res) => {
                      localStorage.setItem("user_email", email);
                      swalSuccessMessage("Login successful");
                      setSpin(false);
                      dispatch(login(email));
                    });
                });
            } else {
              localStorage.setItem("user_email", email);
              swalSuccessMessage("Login successful");
              dispatch(login(email));
              setSpin(false);
            }
          });
      } catch (err) {
        console.error(err);
        let c: any = err;
        swalErrorMessage(c.message);
        setSpin(false);
      }
    } else {
      setMsg(`Password doesn't match `);
      setTimeout(() => {
        setMsg("");
      }, 5000);
    }
  };

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form
            className="login100-form validate-form"
            onSubmit={registerWithEmailAndPassword}
          >
            <span className="login100-form-title p-b-49">Register</span>

            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Username is reauired"
            >
              <span className="label-input100">Email</span>
              <input
                className="input100"
                type="email"
                name="email"
                placeholder="Type your email"
                required={true}
                value={email}
                onChange={(e) =>
                  handleChange("email", e.target.value.toLowerCase())
                }
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>

            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Password is required"
            >
              <span className="label-input100">Password</span>
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Type your password"
                required={true}
                value={password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <span className="focus-input100" data-symbol="&#xf190;"></span>
            </div>
            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Password is required"
            >
              <span className="label-input100">Confirm Password</span>
              <input
                className="input100"
                type="password"
                name="cpassword"
                placeholder="Type your password"
                required={true}
                value={cpassword}
                onChange={(e) => handleChange("cpassword", e.target.value)}
              />
              <span className="focus-input100" data-symbol="&#xf190;"></span>
            </div>

            <div className="text-left p-t-8 p-b-31">
              {/* <a href="#">Forgot password?</a> */}
              <div className="form-group">
                <Checkbox
                  id="checkbox_expiry"
                  field="terms"
                  value={agree}
                  label="I agree to the Vodex terms"
                  onChange={() => handleChange("agree", !agree)}
                  link={url}
                />
              </div>
              {msg && (
                <span className="m-1" style={{ color: "red" }}>
                  {msg}
                </span>
              )}
            </div>

            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  disabled={spin}
                >
                  {spin ? "Registering..." : "Register"}
                </button>
              </div>
            </div>
            <div className="flex-col-c p-t-50 p-b-10">
              <span className="txt1 p-b-17">Or </span>
            </div>
            <div>
              <span className="text-right p-t-8 p-b-31 ">
                <Link to={"/reset-password"} className="bottom-span">
                  Reset Password ?
                </Link>
              </span>
              <span className="text-left p-t-8 p-b-31 p-l-230">
                <Link to={"/login"} className="bottom-span">
                  Login
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register1;
