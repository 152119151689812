import axios from "axios";
import { swalWarningMessage } from "./swalMessage";

export const API = {
  LOGIN: {
    GET_TOKEN: "/token",
    GOOGLE_TOKEN: "/google-token",
  },
  USER: {
    GET_USER_PROFILE: "get-user-name",
    PROFILE_CHECK: "/profile-check",
  },
  ACCOUNT_DETAILS: {
    GET_ACCOUNT_BALANCE: "get-account-balance",
  },
  BALANCE: {
    CHECK_SUFFICIENT_BALANCE: "check-sufficient-balance",
  },
  TERMS: {
    GET_TERMS_CONDITIONS: "get-terms-conditions",
  },
  CALL_SETTINGS: {
    SAVE_DISCONNECTED_TIME: "save-disconnect-time",
    GET_DISCONNECTED_TIME: "get-disconnect-time",
  },
  CAMPAIGN: {
    CAMPAIGN: "campaign",
    CAMPAIGN1: "/campaigns",
    CAMPAIGN_RUNS: "/run-campaign",
    CAMPAIGN_RUNS_DETAILS: "/run-campaign-details",
    CAMPAIGNS_WIDGET_COUNT: "/campaign-widgets",
    CAMPAIGNS_RUNS_WIDGET_COUNT: "/campaign-run-dashboard",
    CAMPAIGNS_RUNS_DETAILS_WIDGET_COUNT: "/campaign-run-details-widgets",
    EXCEL_EXPORT: "/excel-export",
    SAVE_FILTERED_CAMPAIGN: "/save-filtered-campaign",
    SCHEDULE_CAMPAIGN: "/schedule-call",
  },
  AUDIENCE: {
    AUDIENCE: "/audience",
    EXCEL_AUDIENCE: "/audience-excel",
    EXCEL_NEW_AUDIENCE: "/audience/excel/new",
  },
  PAYMENT: {
    SAVE_PAYMENT: "/topup",
    UPDATE_PAYMENT: "/stripe/balance",
    INVOICE: "/invoice",
    ORDER: "/razorpay/order",
    VERIFY: "/razorpay/verify",
  },
};
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const BASE_URL_API_LINK = process.env.REACT_APP_BASE_URL_API;

export const BASEURL = `${BASE_URL}`;
export const BASE_URL_API = `${BASE_URL_API_LINK}`;
export const axiosClient = axios.create();
let token = localStorage.getItem("token");
axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axiosClient.defaults.headers.post["Content-Type"] = "application/json";
// axiosClient.defaults.headers.common["content-type"] = "application/json";

console.log("token", token);
axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.toString() === "Error: Request failed with status code 403") {
      swalWarningMessage("Please login again");
      localStorage.clear();
      window.location.href = "/";
    }
    // localStorage.clear();
    // window.location.href = "/";
  }
);
