import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { swalSuccessMessage } from "../../Constant/swalMessage";
import axios from "axios";
import { useLocation } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function InsightsViewModal({
  insightsPopup,
  toggle,
  campaignRunListData,
  campaignName,
}) {
  const [state, setState] = useState({
    questions: [{ question: "", key: "", type: "" }],
    about: "",
    data: [{ phone_number: "", output: {}, recording_url: "" }],
    apiPopup: false,
    isDisabled: false,
    exportSpin: false,
    selectedData: [],
  });

  const [apiData, setApiData] = useState({});

  const location = useLocation();
  const { apiPopup, isDisabled, exportSpin, selectedData } = state;
  useEffect(() => {
    const init = async () => {
      let res = await axios.get(
        `https://flow-creator-docs.api.lilchirp.io/prompt/entities?campaign_name=${campaignName}&campaign_run_id=${
          location.search.split("?")[1]
        }`
      );
      console.log(res.data);

      // let res = await axios.get(
      //   `https://flow-creator-docs.api.lilchirp.io/prompt/entities?campaign_name=.*&campaign_run_id=.*`
      // );
      // console.log(res.data);
      setState((prev) => ({
        ...prev,
        data: res.data,
      }));
    };

    init();

    return () => {
      setState((prev) => ({
        ...prev,
        data: [],
      }));
    };
  }, []);

  const getData = (output) => {
    if (typeof output === "string") {
      return output;
    }
    let outputData: Array<string> = [];
    for (const key in output) {
      console.log("keykey", key);
      let strinOb = `${key} : ${output[key]}`;
      outputData.push(strinOb);
    }
    return outputData.join(",");
  };

  const handleChange = (e) => {
    e.persist();

    setApiData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    setState((prev) => ({
      ...prev,
      isDisabled: true,
    }));
    e.preventDefault();
    let obj = {
      ...apiData,
      campaign_name: campaignName,
      campaign_run_id: location.search.split("?")[1],
      data: selectedData.map((da: any) => {
        return {
          recording_url: da.recording_url,
          phone_number: da.phone_number,
          output: da.output,
        };
      }),
    };
    let res = await axios.post(
      "https://flow-creator-docs.api.lilchirp.io/prompt/export",
      obj
    );

    setState((prev) => ({
      ...prev,
      isDisabled: false,
      apiPopup: false,
    }));
    swalSuccessMessage(res?.data?.status);
  };

  const handlePopup = () => {
    setState((prev) => ({
      ...prev,
      apiPopup: !prev.apiPopup,
    }));
  };

  const ExportCSV = async () => {
    if (selectedData.length > 0) {
      let modifiedArr: Array<object> = [];
      selectedData.forEach((data: any) => {
        let obj = {
          "Phone Number": data.phone_number,
          "Recording Url": data.recording_url,
          Output: getData(data?.output),
        };
        modifiedArr.push(obj);
      });
      setState((prev) => ({
        ...prev,
        exportSpin: true,
      }));
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(modifiedArr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `Vodex Campaign Report ${campaignName}` + fileExtension
      );
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };

  const handleCheckboxChange = (e, data, idx) => {
    if (idx === "all") {
      if (!e.target.checked) {
        setState((prev) => ({
          ...prev,
          selectedData: [],
        }));
        return;
      }
      let selectedCopy: any = state.data;
      selectedCopy = selectedCopy.map((da, idx) => {
        return {
          recording_url: da.recording_url,
          phone_number: da.phone_number,
          output: da.output,
          idx: idx,
        };
      });
      setState((prev) => ({
        ...prev,
        selectedData: selectedCopy,
      }));
      return;
    }
    let selectedCopy: any = selectedData;
    if (!e.target.checked) {
      selectedCopy.splice(idx, 1);
    } else {
      let obj = {
        recording_url: data.recording_url,
        phone_number: data.phone_number,
        output: data.output,
        idx: idx,
      };
      selectedCopy.push(obj);
    }

    setState((prev) => ({
      ...prev,
      selectedData: selectedCopy,
    }));
  };
  console.log("selectedData", selectedData);
  const getCheckedStatus = (index) => {
    let selectedCopy: any = selectedData;
    return selectedCopy.find((p) => p?.idx === index) ? true : false;
  };
  return (
    <div>
      <Modal size="lg" keyboard={false} show={insightsPopup} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title> View Insights</Modal.Title>

          <div style={{ marginLeft: "40%" }}>
            <button className="btn btn-info m-1" onClick={handlePopup}>
              {" "}
              Export via api
            </button>
            <button
              className="btn btn-info m-1"
              disabled={exportSpin || selectedData.length === 0}
              onClick={ExportCSV}
            >
              {exportSpin ? "Exporting" : "Export Excel"}
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <table className="table border">
            <thead>
              <th>
                <input
                  key={`keyall`}
                  className="mr-1"
                  type="checkbox"
                  onChange={(e) => handleCheckboxChange(e, {}, "all")}
                  checked={state.data.length === selectedData.length}
                />
              </th>
              <th>Number</th>
              <th>Output</th>
            </thead>
            <tbody>
              {state?.data.length > 0 ? (
                state?.data?.map((data, idx) => (
                  <tr key={idx}>
                    <td>
                      <input
                        key={`key${idx}`}
                        className="mr-1"
                        type="checkbox"
                        onChange={(e) => handleCheckboxChange(e, data, idx)}
                        checked={getCheckedStatus(idx)}
                      />
                    </td>
                    <td>{data?.phone_number || "--"}</td>
                    <td>{getData(data?.output)}</td>
                  </tr>
                ))
              ) : (
                <td colSpan={2}> No Data found</td>
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <Modal size="lg" keyboard={false} show={apiPopup} onHide={handlePopup}>
        <form onChange={handleChange} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title> Save Insights</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              placeholder="Enter Url"
              required
              className="form-control m-1"
              name="url"
            />
            <input
              placeholder="Enter Authorization token"
              required
              className="form-control m-1"
              name="authorization_token"
            />
            <select
              required
              name="authenticated"
              placeholder="Select Authenticated"
              className="form-control m-1"
            >
              <option value={""}>Select Is API Authenticated</option>
              <option value={"true"}>True</option>
              <option value={"false"}>False</option>
            </select>
            <input
              placeholder="Enter Authorization Key"
              required
              className="form-control m-1"
              name="authorization_key"
            />
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-success" type="submit">
              {isDisabled ? "Exporting" : "Submit"}
            </button>
            <button className="btn btn-info" onClick={handlePopup}>
              Close
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default InsightsViewModal;
