import React, { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { swalErrorMessage } from "../../Constant/swalMessage";
import axios from "axios";
import { useLocation } from "react-router-dom";

function InsightsModal({
  insightsPopup,
  toggle,
  campaignRunListData,
  campaignName,
}) {
  console.log("campaignRunListData", campaignRunListData);
  const [state, setState] = useState({
    questions: [{ question: "", key: "", type: "" }],
    about: "",
    buttonDisable: false,
  });

  const location = useLocation();
  console.log("locat", location.search);

  useEffect(() => {
    let localObj: any = localStorage.getItem("insightsObj");
    let item: any = JSON.parse(localObj);
    if (item) {
      setState((prev) => ({
        ...prev,
        questions: [...item?.questions],
        about: item.about,
      }));
    }
  }, []);

  const { questions, about, buttonDisable } = state;

  const addMoreColumn = () => {
    let add = { question: "", key: "", type: "" };

    setState((prev) => ({
      ...prev,
      questions: [add, ...prev.questions],
    }));
  };

  const handleChange = (e, idx, val) => {
    let cloneData = state.questions;
    if (val === "key") {
      cloneData[idx][val] = e.target.value.split(" ").join("");
    } else {
      cloneData[idx][val] = e.target.value;
    }
    setState((prev) => ({
      ...prev,
      questions: cloneData,
    }));
  };
  const handleSubmit = async (e) => {
    setState((prev) => ({ ...prev, buttonDisable: true }));
    e.preventDefault();
    let sendRecordingData: Array<object> = [];
    for (const item of campaignRunListData) {
      let obj = {
        recording_url: item.callRecordingUrl,
        phone_number: item.phone,
      };
      sendRecordingData.push(obj);
    }

    let err = 0;
    let required = ["question", "type", "key"];
    for (const item of questions) {
      required.forEach((o) => {
        if (!item[o]) {
          err++;
        }
      });
    }
    if (err) {
      swalErrorMessage("Please fill all the field first");
    } else {
      let obj = {
        campaign_name: campaignName,
        campaign_run_id: location.search.split("?")[1],
        questions: [...questions],
        about: about,
        recording_data: sendRecordingData,
      };
      let res = await axios.post(
        "https://flow-creator-docs.api.lilchirp.io/prompt/entities",
        obj
      );

      localStorage.setItem("insightsObj", JSON.stringify(obj));
      console.log("resres", res);
    }

    setState((prev) => ({ ...prev, buttonDisable: false }));
    toggle();
  };
  const removeQuestion = (idx) => {
    let cloneData = state.questions;
    cloneData.splice(idx, 1);
    setState((prev) => ({
      ...prev,
      questions: cloneData,
    }));
  };

  const overLay = (text) => {
    return (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="button-tooltip-2">{text}</Tooltip>}
      >
        <i
          className="fa fa-info-circle m-2"
          style={{ fontSize: "20px", cursor: "pointer" }}
        ></i>
      </OverlayTrigger>
    );
  };
  return (
    <div>
      <Modal size="lg" keyboard={false} show={insightsPopup} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title> Create Insights</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>About Call</label>
          <textarea
            required
            onChange={(e) => {
              e.persist();
              setState((prev) => ({ ...prev, about: e.target.value }));
            }}
            value={about}
            placeholder="Please define about the campaign"
            className="form-control mb-2"
          />
          {/* <div className="card"> */}
          {/* <div className="card-header">
              <span className="float-right">
                <button className="btn btn-info" onClick={addMoreColumn}>
                  <span className="fas fa-plus  text-gray-300"></span>
                </button>
              </span>
            </div> */}
          {/* <div className="card-body"> */}
          <table className="table border">
            <thead>
              <th>
                Question{" "}
                {overLay(
                  "What information you want to extract,eg: What is the address said by user"
                )}
              </th>
              <th>
                Name{" "}
                {overLay(
                  "What do you want to name the extracted value, eg:'address'"
                )}
              </th>
              <th>
                Type{" "}
                {overLay("In what format do you want the value, eg: 'Text'")}
              </th>
              <th>Action</th>
            </thead>
            <tbody>
              {state.questions.map((data, idx) => (
                <tr key={idx}>
                  <td>
                    <input
                      required
                      placeholder="Enter Question"
                      className="form-control"
                      value={data.question}
                      onChange={(e) => handleChange(e, idx, "question")}
                    />
                  </td>
                  <td>
                    <input
                      required
                      placeholder="Enter Name"
                      className="form-control"
                      value={data.key}
                      onChange={(e) => handleChange(e, idx, "key")}
                    />
                  </td>
                  <td>
                    <select
                      required
                      placeholder="Select Type"
                      className="form-control"
                      value={data.type}
                      onChange={(e) => handleChange(e, idx, "type")}
                    >
                      <option value="">Please Choose</option>
                      <option value="string">Text</option>
                      <option value="integer">Number</option>
                      <option value="boolean">True/false</option>
                    </select>
                  </td>
                  <td>
                    {idx === 0 && (
                      <button className="btn btn-info" onClick={addMoreColumn}>
                        <span className="fas fa-plus  text-gray-300"></span>
                      </button>
                    )}
                    {idx !== 0 && (
                      <button
                        className="btn btn-danger"
                        onClick={() => removeQuestion(idx)}
                      >
                        <span className="fas fa-minus  text-gray-300"></span>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            type="submit"
            disabled={buttonDisable}
            onClick={handleSubmit}
          >
            {" "}
            {buttonDisable ? "Saving" : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default InsightsModal;
