import * as paymentService from "../../../Constant/services/payment.service";
import {
  swalSuccessMessage,
  swalErrorMessage,
} from "../../../Constant/swalMessage";
import { save } from "save-file";

export const savePayment = (data) => async (dispatch) => {
  let res = await paymentService.savePayment(data);
  console.log("resre", res);
  if (res.status === 200) {
    swalSuccessMessage(res?.msg);
  } else {
    swalErrorMessage("Something went wrong");
  }
};

export const getInvoices = () => async () => {
  let res = await paymentService.getInvoices();
  console.log("resre", res);
  if (res.status === 200) {
    return res.data;
  } else {
    swalErrorMessage("Something went wrong");
  }
};
export const createOrder = (data) => async () => {
  let res = await paymentService.createOrder(data);
  if (res.status === 200) {
    return res.data;
  } else {
    swalErrorMessage("Something went wrong");
  }
};
export const verifyOrder = (data) => async () => {
  let res = await paymentService.verifyOrder(data);
  if (res.status === 200) {
    return res.data;
  } else {
    swalErrorMessage("Something went wrong");
  }
};
export const downloadInvoice = (data) => async () => {
  let res = await paymentService.downloadPdf(data);
  console.log(res, "paymentId");
  // if (res?.status === 200) {
  // const url = window.URL.createObjectURL(new Blob([res]));
  // const link = document.createElement("a");
  // link.href = url;
  // link.setAttribute("download", "yourcoolpdf.pdf");
  // document.body.appendChild(link);
  // link.click();

  await save(res, "invo.pdf");

  // } else {
  //   swalErrorMessage("Something went wrong");
  // }
};

export const stripePayment = (data) => async (dispatch) => {
  let res = await paymentService.stripePayment(data);
  if (res.status === 200) {
    // swalSuccessMessage(res?.msg);
    return res;
  } else {
    swalErrorMessage("Something went wrong");
  }
};

export const updateAccountPayment = (data) => async (dispatch) => {
  let res = await paymentService.updateAccount(data);
  if (res.status === 200) {
    return res.status;
  } else {
    swalErrorMessage("Something went wrong");
  }
};
