import { combineReducers, Reducer } from "redux";
import { UPDATE_CURRENT_PATH } from "../actions/root.actions";
import {
  IRootStateType,
  IActionBase,
  IStateType,
} from "../models/root.interface";
import productsReducer from "./products.reducer";
import audienceReducer from "./audience.reducer";
import campaignReducer from "./campaign.reducer";
import campaignRunReducer from "./campaignRun.reducer";
import campaignRunDetailsReducer from "./campaignRunDetails.reducer";
import advanceSettingReducer from "./advanceSetting.reducer";
import notificationReducer from "./notification.reducer";
import userReducer from "./users.reducer";
import orderReducer from "./order.reducer";
import accountReducer from "./account.reducer";
import paymentReducer from "./paymentSetting.reducer";
import whatsappReducer from "./whatsapp.reducer";
import templateReducer from "./template.reducer";
import mainUserReducer from "../Store/User/user.reducer";
import mainCampaignReducer from "../Store/campaigns/campaigns.reducer";
import mainAudienceReducer from "../Store/Audience/audience.reducer";
import scheduleReducer from "../Store/Schedule/schedule.reducer";

const initialState: IRootStateType = {
  page: { area: "home", subArea: "" },
};

function rootReducer(
  state: IRootStateType = initialState,
  action: IActionBase
): IRootStateType {
  switch (action.type) {
    case UPDATE_CURRENT_PATH:
      return { ...state, page: { area: action.area, subArea: action.subArea } };
    default:
      return state;
  }
}

const rootReducers: Reducer<IStateType> = combineReducers({
  root: rootReducer,
  products: productsReducer,
  audience: audienceReducer,
  campaign: campaignReducer,
  campaignRun: campaignRunReducer,
  campaignRunDetails: campaignRunDetailsReducer,
  notifications: notificationReducer,
  users: userReducer,
  orders: orderReducer,
  account: accountReducer,
  advanceSetting: advanceSettingReducer,
  paymentSetting: paymentReducer,
  whatsappReducer: whatsappReducer,
  templateReducer: templateReducer,
  mainUserReducer: mainUserReducer,
  mainCampaignReducer: mainCampaignReducer,
  mainAudienceReducer: mainAudienceReducer,
  scheduleReducer: scheduleReducer,
});

export default rootReducers;
