import { RouteProps } from "react-router";
import React from "react";
import { useSelector } from "react-redux";
import { IStateType } from "../../store/models/root.interface";
import { IAccount } from "../../store/models/account.interface";
import Login from "../../components/Account/Login";
import { Switch, Route } from "react-router-dom";
import NewLogin from "../../components/NewLogin/NewLogin";
import Register1 from "../../components/NewLogin/Register";
import Reset1 from "../../components/NewLogin/ResetPassword";

export function PrivateRoute({ children, ...rest }: RouteProps): JSX.Element {
  const account: IAccount = useSelector((state: IStateType) => state.account);

  return (
    <Route
      {...rest}
      render={() =>
        account.email ? (
          children
        ) : (
          <Switch>
            <Route path={`/register`}>
              <Register1 />
            </Route>
            <Route path={`/reset-password`}>
              <Reset1 />
            </Route>
            <Route path={"/"}>
              <NewLogin />
            </Route>
          </Switch>
        )
      }
    />
  );
}
