import React from 'react'

function ComingSoon() {




    return (
        <div style={{ marginLeft: '1%', fontWeight: 'bolder' }} >
            Coming Soon
        </div>
    )
}

export default ComingSoon
