import React, { useState, FormEvent, Dispatch, Fragment } from "react";
import {
  IStateType,
  IProductState,
} from "../../../store/models/root.interface";
import { useSelector, useDispatch } from "react-redux";
import {
  IProduct,
  ProductModificationStatus,
} from "../../../store/models/product.interface";
import TextInput from "../../../common/components/TextInput";
import {
  clearSelectedProduct,
  setModificationState,
  addTemplate,
  editTemplate,
} from "../../../store/actions/template.action";
import { addNotification } from "../../../store/actions/notifications.action";
import { OnChangeModel } from "../../../common/types/Form.types";
import { DropzoneArea } from "material-ui-dropzone";
import firebase from ".././../../firebase";

const Swal = require("sweetalert2");

const ProductForm: React.FC = (props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const templates = useSelector((state: any) => state.templateReducer);
  let product: any | null = templates.selectedProduct;
  const isCreate: boolean =
    templates.modificationState === ProductModificationStatus.Create;

  if (!product || isCreate) {
    product = {
      id: 0,
      name: "",
      description: "",
      recordingArray: [],
    };
  }

  let inde = 0;

  const [formState, setFormState] = useState({
    name: { error: "", value: product.name },
    description: { error: "", value: product.description },
    projectId: { error: "", value: product.projectId },
    language: { error: "", value: product.language },
    recordingArray: product.id
      ? product.recordingArray.length > 0
        ? product.recordingArray
        : [{ recordingName: "", recordingTag: "", helpingText: "" }]
      : [{ recordingName: "", recordingTag: "", helpingText: "" }],
    imgUrl: product.imgUrl,
  });

  const [show, setShow] = useState(formState.imgUrl ? false : true);

  const [dataFormat, setDataFormat] = useState(true);
  const [saveFormData, setSaveFormData] = useState(false);
  const email: string = useSelector((state: IStateType) => state.account.email);
  const [msg, setMsg] = useState("");
  const [disabled, setDisabled] = useState(false);

  function hasFormValueChanged(model: any): void {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });
  }
  function hasFormValueChanged1(e): void {
    setFormState({
      ...formState,
      [e.target.name]: { error: "", value: e.target.value },
    });
  }

  function onInputChange(e, index): void {
    switch (e.field) {
      case "recordingName":
        {
          const recordingArray = [...formState.recordingArray];
          recordingArray[index].recordingName = e.value;
          setFormState({ ...formState, recordingArray: recordingArray });
        }
        break;
      case "recordingTag":
        {
          const recordingArray = [...formState.recordingArray];
          recordingArray[index].recordingTag = e.value;
          setFormState({ ...formState, recordingArray: recordingArray });
        }
        break;
      case "helpingText":
        {
          const recordingArray = [...formState.recordingArray];
          recordingArray[index].helpingText = e.value;
          setFormState({ ...formState, recordingArray: recordingArray });
        }
        break;
      default: {
      }
    }
  }

  function saveUser(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    if (!dataFormat) {
      Swal.fire({
        title: "Bad file format!",
        text: "File format is not correct.",
        icon: "error",
        confirmButtonText: "Okay",
      });
      return;
    }
    let saveUserFn: Function = isCreate ? addTemplate : editTemplate;
    saveForm(formState, saveUserFn);
  }

  function saveForm(formState: any, saveFn: Function): void {
    if (formState.name.value == "") {
      Swal.fire({
        title: "Template Name is missing!",
        icon: "failure",
        confirmButtonText: "Okay",
      });
    } else {
      Swal.fire({
        title: "Successful!",
        text: "Template Created",
        icon: "success",
        confirmButtonText: "Okay",
      });

      dispatch(
        saveFn({
          id: product.id ? product.id : "",
          email: email,
          name: formState.name.value,
          recordingArray: formState.recordingArray,
          description: formState.description.value,
          projectId: formState.projectId.value,
          language: formState.language.value,
          imgUrl: formState.imgUrl,
        })
      );

      dispatch(
        addNotification(
          isCreate ? "Template Created" : "Template Edited",
          `Template ${formState.name.value} created by you`
        )
      );
      dispatch(clearSelectedProduct());
      dispatch(setModificationState(ProductModificationStatus.None));
      cancelForm();
      setSaveFormData(false);
    }
  }

  function cancelForm(): void {
    dispatch(setModificationState(ProductModificationStatus.None));
  }

  function addAudienceForm(): void {
    setSaveFormData(false);

    setFormState({
      ...formState,
      recordingArray: [
        ...formState.recordingArray,
        { recordingName: "", recordingTag: "", helpingText: "" },
      ],
    });
  }

  function removeAudienceForm(): void {
    setSaveFormData(false);
    const recordingList = [...formState.recordingArray];
    if (recordingList.length > 1) {
      recordingList.pop();
      setFormState({ ...formState, recordingArray: recordingList });
    }
  }

  function showFormToAddNumbers(index) {
    inde = index;
    return (
      <div>
        <div className="alert-warning m-1 p-1">Recording {index + 1}</div>
        <div className="form-group ">
          <TextInput
            id="input_email"
            value={formState.recordingArray[index].recordingName}
            field="recordingName"
            onChange={(e) => onInputChange(e, index)}
            required={true}
            maxLength={50}
            label="Recording Name"
            placeholder="e.g. John"
          />
        </div>
        <div className="form-group ">
          <TextInput
            id="input_email"
            value={formState.recordingArray[index].recordingTag}
            field="recordingTag"
            onChange={(e) => onInputChange(e, index)}
            required={false}
            maxLength={20}
            label="Recording Tag"
            placeholder="e.g. welcome"
          />
        </div>
        <div className="form-group ">
          <div>
            <TextInput
              id="input_email"
              value={formState.recordingArray[index].helpingText}
              field="helpingText"
              onChange={(e) => onInputChange(e, index)}
              required={true}
              maxLength={100}
              label="Recording Help Text"
              placeholder="e.g."
            />
          </div>
        </div>
      </div>
    );
  }

  function getDisabledClass(): string {
    let isError: boolean = isFormInvalid();
    return isError ? "disabled" : "";
  }

  function isFormInvalid(): boolean {
    return false;
  }
  const handleChange = (files) => {
    if (files.length > 0) {
      setDisabled(true);
      const storageRef = firebase.storage().ref(email);
      const fileRef = storageRef.child(files[0].name);
      fileRef.put(files[0]).then(() => {
        fileRef.getDownloadURL().then((downloadUrl) => {
          setFormState({ ...formState, imgUrl: downloadUrl });
          setDisabled(false);
        });
      });
    }
  };

  return (
    <Fragment>
      <div className="col-xl-8 col-lg-12 col-sd-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">
              {isCreate ? "Create a new" : "Edit your"} Template{" "}
            </h6>
          </div>
          <div className="card-body">
            <form onSubmit={saveUser}>
              <div className="form-group">
                <TextInput
                  id="input_email"
                  value={formState.name.value}
                  field="name"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={100}
                  label="Template Name"
                  placeholder="e.g. Real Estate Template"
                />
              </div>
              <label>Template Description</label>

              <div className="form-group">
                <textarea
                  name="description"
                  className="form-control"
                  id="input_email"
                  defaultValue={formState.description.value}
                  onChange={hasFormValueChanged1}
                  required={true}
                  placeholder="Description of the template"
                />
              </div>
              <div className="form-group">
                <TextInput
                  id="input_email"
                  value={formState.projectId.value}
                  field="projectId"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={100}
                  label="Project Id"
                  placeholder="project id..."
                />
              </div>
              <div className="form-group">
                <TextInput
                  id="input_email"
                  value={formState.language.value}
                  field="language"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={20}
                  label="Template Language"
                  placeholder="language ..."
                />
              </div>
              {formState.imgUrl && (
                <>
                  <img src={formState.imgUrl} height="200px" width="200px" />
                  <br />
                  <button
                    type="button"
                    className="btn btn-info m-1"
                    onClick={() => setShow(!show)}
                  >
                    Update Image
                  </button>
                </>
              )}
              <br />
              {show && (
                <>
                  <label>Template Image</label>

                  <DropzoneArea onChange={handleChange} />
                </>
              )}

              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    {formState.recordingArray.map((_audience, index) =>
                      showFormToAddNumbers(index)
                    )}
                    {msg && (
                      <span className="m-2" style={{ color: "red" }}>
                        {" "}
                        {msg}
                        <br />
                        <br />
                      </span>
                    )}

                    <button
                      type="button"
                      onClick={() => removeAudienceForm()}
                      className={`btn btn-danger`}
                    >
                      Remove last
                    </button>
                    <button
                      type="button"
                      onClick={() => addAudienceForm()}
                      className={`btn btn-success left-margin ${getDisabledClass()}`}
                    >
                      Add Recording
                    </button>
                  </div>
                </div>
              </div>

              <div className="form-group"></div>
              <button className="btn btn-danger" onClick={() => cancelForm()}>
                Cancel
              </button>
              <button
                disabled={disabled}
                type="submit"
                className={`btn btn-success left-margin ${getDisabledClass()}`}
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductForm;
