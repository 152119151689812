import React, { Fragment, Dispatch, useState, useEffect } from "react";
import ProductList from "./WhatsappList";
import ProductForm from "./WhatsappForm";
import TopCard from "../../common/components/TopCard";
import "./Whatsapp.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IProductState,
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import {
  clearSelectedProduct,
  setModificationState,
  changeSelectedProduct,
} from "../../store/actions/products.action";
import { ProductModificationStatus } from "../../store/models/product.interface";
import { setModificationState1 } from "../../store/actions/whatsapp.action";

const Products: React.FC = () => {
  const products1: any = useSelector((state: any) => state.whatsappReducer);

  const numberItemsCount: number = products1.audience.length;

  const dispatch: Dispatch<any> = useDispatch();
  const products: IProductState = useSelector(
    (state: any) => state.whatsappReducer
  );
  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );

  useEffect(() => {
    dispatch(clearSelectedProduct());
    dispatch(updateCurrentPath("whatsapp", "list"));
  }, [path.area, dispatch]);
  function onProductSelect(product) {
    dispatch(changeSelectedProduct(product));
    dispatch(setModificationState(ProductModificationStatus.None));
  }

  return (
    <Fragment>
      <h1 className="h3 mb-2 text-gray-800">Whatsapp</h1>
      <div>
        <p className="mb-4">Add your Whatsapp message here</p>

        <div className="row">
          <TopCard
            title="MESSAGE COUNT"
            text={`${numberItemsCount}`}
            icon="box"
            class="primary"
          />
        </div>

        {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end">

        <button type="button" className="btn btn-success" onClick={onClickLogout}>Logout</button>
      </div> */}
        <div className="row">
          {/* <TopCard title="MESSAGE COUNT" text={`${numberItemsCount}`} icon="box" class="primary" /> */}
        </div>
        {products.modificationState === ProductModificationStatus.Create ||
        (products.modificationState === ProductModificationStatus.Edit &&
          products.selectedProduct) ? (
          <ProductForm />
        ) : null}

        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-green">
                  Message List
                </h6>
                <div className="header-buttons">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      onProductSelect({});
                      dispatch(
                        setModificationState1(ProductModificationStatus.None)
                      );

                      dispatch(
                        setModificationState1(ProductModificationStatus.Create)
                      );
                    }}
                  >
                    <i className="fas fa fa-plus"></i> Add Message
                  </button>
                  &nbsp;
                  {/* <button className="btn btn-success btn-blue" onClick={() =>
                  dispatch(setModificationState(ProductModificationStatus.Edit))}>
                  <i className="fas fa fa-pen"></i>
                </button>
                <button className="btn btn-success btn-red" onClick={() => onProductRemove()}>
                  <i className="fas fa fa-times"></i>
                </button> */}
                </div>
              </div>
              <div className="card-body">
                <ProductList onSelect={onProductSelect} />
              </div>
              {/* <button onClick={sendMessage}>Send Message</button> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Products;
