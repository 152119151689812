import React, { useState, Dispatch, Fragment, useEffect } from "react";
import { IStateType, IProductState } from "../../store/models/root.interface";
import { useSelector, useDispatch } from "react-redux";
import { ProductModificationStatus } from "../../store/models/product.interface";
import TextInput from "../../common/components/TextInput";
import {
  clearSelectedProduct,
  setModificationState,
} from "../../store/actions/audience.action";
import { addNotification } from "../../store/actions/notifications.action";
import SelectInput from "../../common/components/Select";
import { OnChangeModel } from "../../common/types/Form.types";
import { DropzoneArea } from "material-ui-dropzone";
import "react-voice-recorder/dist/index.css";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import * as audienceAction from "../../store/Store/Audience/audience.action";
import { swalWarningMessage } from "../../Constant/swalMessage";

const Swal = require("sweetalert2");

const ProductForm: React.FC = (props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const products: IProductState | null = useSelector(
    (state: IStateType) => state.products
  );
  const reducer: any = useSelector((state) => state);
  const { mainAudienceReducer } = reducer;
  const { audienceList } = mainAudienceReducer;
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState({
    buttonDisabled: false,
    file: "",
  });
  const { buttonDisabled, file } = state;
  let [duplicate, setDuplicate] = useState<any>([]);
  let [carray, setCarray] = useState<any>([]);
  useEffect(() => {
    let cArray: any = [];
    audienceList?.map((o) => {
      o?.audienceList?.map((o) => {
        cArray.push(o);
      });
    });
    setCarray(cArray);
  }, [audienceList]);

  let product: any | null = products.selectedProduct;
  const isCreate: boolean =
    products.modificationState === ProductModificationStatus.Create;

  if (!product || isCreate) {
    product = {};
  }

  let inde = 0;
  let obj1 = {
    excel: "Upload an Excel File",
    Excel: "Upload an Excel File",
    manual: "Manually add Numbers",
    string: "Manually add Numbers",
  };
  const [formState, setFormState] = useState({
    name: { error: "", value: product?.audienceListName || "" },
    audienceData: product?.id
      ? product?.audienceList?.length > 0
        ? product?.audienceList
        : [{ firstName: "", lastName: "", phone: "" }]
      : [{ firstName: "", lastName: "", phone: "" }],
    source: { error: "", value: obj1[product.source || product.sourceType] },
  });

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      name: { error: "", value: product?.audienceListName || "" },
      audienceData: product?.id
        ? product?.audienceList?.length > 0
          ? product?.audienceList
          : [{ firstName: "", lastName: "", phone: "" }]
        : [{ firstName: "", lastName: "", phone: "" }],
      source: { error: "", value: obj1[product.source || product.sourceType] },
    }));
  }, [products]);

  const { source, name, audienceData } = formState;
  const [msg, setMsg] = useState("");
  function hasFormValueChanged(model: OnChangeModel): void {
    setFormState({
      ...formState,
      [model.field]: { error: model.error, value: model.value },
    });
  }

  function onInputChange(e, index): void {
    if (e.field === "phone") {
      let cd = audienceData.find((o) => o.phone === e.value.trim());
      if (cd) {
        setMsg(`Duplicate Entry ${cd.phone}`);
        setTimeout(() => {
          setMsg("");
        }, 5000);
      }
    }

    switch (e.field) {
      case "firstName":
        {
          const audienceList1 = [...audienceData];
          audienceList1[index].firstName = e.value;
          setFormState({ ...formState, audienceData: audienceList1 });
        }
        break;
      case "lastName":
        {
          const audienceList1 = [...audienceData];
          audienceList1[index].lastName = e.value;
          setFormState({ ...formState, audienceData: audienceList1 });
        }
        break;
      case "phone":
        {
          const audienceList1 = [...audienceData];
          audienceList1[index].phone = e.value;
          setFormState({ ...formState, audienceData: audienceList1 });
        }
        break;
      default: {
      }
    }
  }

  function cancelForm() {
    dispatch(setModificationState(ProductModificationStatus.None));
  }
  function addAudienceForm() {
    if (formState.audienceData[inde].phone.length < 6) {
      setMsg(`Enter Valid no.`);
      setTimeout(() => {
        setMsg("");
      }, 5000);
      formState.audienceData[inde].phone = "";
    } else {
      setFormState({
        ...formState,
        audienceData: [
          ...formState.audienceData,
          { firstName: "", lastName: "", phone: "" },
        ],
      });
    }
  }

  function removeAudienceForm() {
    const audienceList1 = [...audienceData];
    if (audienceList1.length > 1) {
      audienceList1.pop();
      setFormState({ ...formState, audienceData: audienceList1 });
    }
  }

  function showFormToAddNumbers(index) {
    inde = index;
    return (
      <div className="form-row">
        <div className="form-group col-xl-4 col-md-4 col-lg-4">
          <TextInput
            id="input_email"
            value={audienceData[index].firstName}
            field="firstName"
            onChange={(e) => onInputChange(e, index)}
            required={false}
            maxLength={20}
            label="First Name"
            placeholder="e.g. John"
          />
        </div>
        <div className="form-group col-xl-4 col-md-4 col-lg-4">
          <TextInput
            id="input_email"
            value={audienceData[index].lastName}
            field="lastName"
            onChange={(e) => onInputChange(e, index)}
            required={false}
            maxLength={20}
            label="Last Name"
            placeholder="e.g. Doe"
          />
        </div>
        <div className="form-group col-xl-4 col-md-4 col-lg-4">
          <div style={{ display: "flex" }}>
            <TextInput
              id="input_email"
              value={audienceData[index].phone}
              field="phone"
              onChange={(e) => onInputChange(e, index)}
              required={true}
              maxLength={20}
              label="Phone"
              placeholder="e.g. 18899776655"
              type="number"
              info={
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={"top"}>
                      Please enter the number with country code and without +
                      symbol e.g 18899....
                    </Tooltip>
                  }
                >
                  <i
                    style={{ fontSize: 15 }}
                    className={`fas fa-info-circle `}
                  ></i>
                </OverlayTrigger>
              }
            />
          </div>
        </div>
      </div>
    );
  }
  let obj = {
    "Upload an Excel File": "excel",
    "Manually add Numbers": "manual",
  };

  const saveAudience = async (e) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      buttonDisabled: true,
    }));
    if (product?.id) {
      let sendObj = {
        id: product.id,
        audienceInformation: {
          audienceList: audienceData,
          audienceListName: name.value,
        },
      };
      await dispatch(audienceAction.editAudienceList(sendObj));
    } else {
      let sendObj = {
        audienceListName: name.value,
        // sourceType: obj[source.value],
        audienceList: audienceData,
      };
      if (obj[source.value] === "manual") {
        let dup: any = [];
        sendObj.audienceList.map((c) => {
          let kl = carray.find((o) => o.phone === c.phone);
          if (kl) {
            dup.push(kl);
          }
        });
        if (dup.length > 0) {
          setPopup(true);
          setDuplicate(dup);
          setState((prev) => ({
            ...prev,
            buttonDisabled: false,
          }));
          return;
        } else {
          await dispatch(audienceAction.addAudienceList(sendObj));
        }
      } else {
        if (file) {
          let formdata = new FormData();
          formdata.append("file", file);
          formdata.append("audienceListName", name.value);
          await dispatch(audienceAction.addAudienceListExcel(formdata));
        } else {
          swalWarningMessage("Please upload a file first");
          setState((prev) => ({
            ...prev,
            buttonDisabled: false,
          }));
          return;
        }
      }
    }
    await dispatch(audienceAction.getAudienceList());
    cancelForm();
    setState((prev) => ({
      ...prev,
      buttonDisabled: false,
    }));
  };

  const saveDup = async () => {
    setState((prev) => ({
      ...prev,
      buttonDisabled: true,
    }));
    let sendObj = {
      audienceListName: name.value,
      // sourceType: obj[source.value],
      audienceList: audienceData,
    };
    if (obj[source.value] === "manual") {
      await dispatch(audienceAction.addAudienceList(sendObj));
      await dispatch(audienceAction.getAudienceList());
      setPopup(false);
      setState((prev) => ({
        ...prev,
        buttonDisabled: false,
      }));
    }
  };
  const onSave = (_file) => {
    let saveFil: any = _file.length > 0 ? _file[0] : null;
    setState((prev) => ({
      ...prev,
      file: saveFil,
    }));
  };

  return (
    <Fragment>
      <div className="col-xl-8 col-lg-12 col-sd-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">
              {isCreate ? "Create a new" : "Edit your"} Audience List
            </h6>
          </div>
          <div className="card-body">
            <form onSubmit={saveAudience}>
              <div className="form-group">
                <TextInput
                  id="input_email"
                  value={formState.name.value}
                  field="name"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={20}
                  label="Name this audience list"
                  placeholder="e.g. Doctor's list"
                />
              </div>

              {!product.id && (
                <div className="form-group">
                  <SelectInput
                    id="input_category"
                    field="source"
                    label="How would you provide the phone numbers"
                    options={["Upload an Excel File", "Manually add Numbers"]}
                    required={true}
                    onChange={hasFormValueChanged}
                    value={formState?.source?.value}
                  />
                </div>
              )}

              {formState?.source?.value === "Manually add Numbers" && (
                <div className="form-group">
                  {audienceData.map((audience, index) =>
                    showFormToAddNumbers(index)
                  )}
                  {msg && (
                    <span className="m-2" style={{ color: "red" }}>
                      {" "}
                      {msg}
                      <br />
                      <br />
                    </span>
                  )}

                  <button
                    type="button"
                    onClick={() => removeAudienceForm()}
                    className={`btn btn-danger`}
                  >
                    Remove last
                  </button>
                  <button
                    type="button"
                    onClick={() => addAudienceForm()}
                    className={`btn btn-success left-margin`}
                  >
                    Add more
                  </button>
                </div>
              )}

              {formState?.source?.value === "Upload an Excel File" && (
                <div className="form-row">
                  <a
                    href="https://lilchirp.io/files/lilchirp-upload-contacts-template.xlsx"
                    download={true}
                    className="m-1 linkColour"
                  >
                    <i className="fas fa-download mr-1"></i>
                    Download sample excel file here{" "}
                  </a>

                  <DropzoneArea
                    acceptedFiles={[
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    ]}
                    dropzoneText={
                      "Drag and drop excel file here or click"
                      // : "Data is not in proper format"
                    }
                    onChange={(file) => onSave(file)}
                    filesLimit={1}
                  />
                </div>
              )}
              <div className="form-group"></div>
              <button className="btn btn-danger" onClick={() => cancelForm()}>
                Cancel
              </button>
              <button
                disabled={buttonDisabled}
                type="submit"
                className={`btn btn-success left-margin`}
              >
                Save
              </button>
            </form>
          </div>
        </div>

        <Modal
          backdrop="static"
          size="lg"
          onExit={() => {
            setDuplicate([]);
          }}
          keyboard={false}
          show={popup}
          onHide={() => {
            setPopup(false);
            setDuplicate([]);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Duplicate Numbers Found</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <td>#</td>
                  <td>Phone</td>
                  <td>First Name</td>
                  <td>Last Name</td>
                </tr>
              </thead>
              <tbody>
                {duplicate.map((o, idx) => {
                  let selec = false;
                  let ckFind = audienceData?.find(
                    (dk) => dk?.phone === o?.phone
                  );
                  if (ckFind) {
                    selec = true;
                  }

                  return (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>
                        <input
                          className="mr-1"
                          defaultChecked={true}
                          type="checkbox"
                          checked={selec}
                          onChange={(e) => {
                            let audCopy = audienceData;
                            if (!e.target.checked) {
                              if (audCopy.length > 1) {
                                let c = audCopy?.findIndex(
                                  (o) => o.phone === o.phone
                                );
                                audCopy.splice(c, 1);
                                setFormState((prev) => ({
                                  ...prev,
                                  audienceData: audCopy,
                                }));
                              } else {
                                swalWarningMessage(
                                  "There is only one item in list, you can not deselect this number"
                                );
                              }
                            } else {
                              audCopy.push(o);
                              setFormState((prev) => ({
                                ...prev,
                                audienceData: audCopy,
                              }));
                            }
                          }}
                        />
                        {o.phone}
                      </td>
                      <td>{o.firstName}</td>
                      <td>{o.lastName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setPopup(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
            <button
              onClick={saveDup}
              disabled={audienceData?.length === 0 || buttonDisabled}
              className="btn btn-primary"
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

export default ProductForm;
