import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { db } from "../../../firebase";

function Coupon() {
  const [couponData, setCouponData] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [show, setShow] = useState(false);
  const history = useHistory();
  let collectionRef = db.collection("adminPaymentDiscount");
  const account: any = useSelector((state: any) => state.account);

  useEffect(() => {
    if (account.email === "founders@lilchirp.io") {
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    collectionRef.get().then((res) => {
      let saveData;
      res.forEach((re) => {
        saveData = {
          id: re.id,
          data: re.data(),
        };
      });
      setData(saveData);
    });
  }, []);
  const onChange = (e) => {
    setCouponData({ ...couponData, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setShow(true);
    let save: any = {
      usdCoupon: couponData.coupon,
      usdDiscount: couponData.discount,
    };
    if (couponData.currency === "inr") {
      save = {
        inrCoupon: couponData.coupon,
        inrDiscount: couponData.discount,
      };
    }

    collectionRef
      .doc(data.id)
      .update(save)
      .then((res) => {
        setShow(false);
      });
  };
  return (
    <div>
      {Object.keys(data).length > 0 && (
        <ul>
          <li>
            Coupon: {data.data.inrCoupon}{" "}
            <strong className="ml-2">
              Discount : &#x20B9; {data.data.inrDiscount}
            </strong>
          </li>
          <li>
            Coupon : {data.data.usdCoupon}
            <strong className="ml-2">Discount: ${data.data.usdDiscount}</strong>
          </li>
        </ul>
      )}
      <form
        className="card"
        style={{ margin: "auto", width: "80%" }}
        onChange={onChange}
        onSubmit={onSubmit}
      >
        <div className="card-header"> Add Coupon</div>
        <div className="card-body">
          <select required name="currency" className="form-control m-2">
            <option value={""}>Select</option>
            <option value={"inr"}>INR</option>
            <option value={"usd"}> USD</option>
          </select>

          <input
            placeholder="Enter Coupon"
            required
            name="coupon"
            className="form-control m-2"
          />
          <input
            placeholder="Enter Discount"
            required
            name="discount"
            className="form-control m-2"
          />
        </div>
        <div className="card-footer">
          <button
            type="submit"
            className="btn btn-success"
            style={{ width: "150px" }}
            disabled={show}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default Coupon;
