import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import TopCard from "../../common/components/TopCard";
import { swalSuccessMessage } from "../../Constant/swalMessage";
import * as campaignAction from "../../store/Store/campaigns/campaigns.action";

function ProductList(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const reducer: any = useSelector((state) => state);
  const { mainCampaignReducer } = reducer;
  const {
    runCampaignList,
    totalCampaignRunPageCampaignRun,
    totalCampaignRunPageCallConnected,
    totalCampaignRunPageCallMade,
  } = mainCampaignReducer;
  let id = window.location.href.split("?")[1];
  let campaignDetails: any = localStorage.getItem("campaign");
  let campaignData = JSON.parse(campaignDetails);

  const [state, setState] = useState({
    campaignRunList: [],
    spin: false,
  });
  const { campaignRunList, spin } = state;

  useEffect(() => {
    if (runCampaignList !== null) {
      setState((prev) => ({
        ...prev,
        campaignRunList: runCampaignList,
        spin: true,
      }));
    }
  }, [runCampaignList]);

  const viewCampaignRunDetails = async (campaignRun) => {
    let obj = {
      ...campaignRun,
      type: false,
    };
    localStorage.setItem("campaignRuns", JSON.stringify(obj));
    history.push(`/campaign-run-details?${campaignRun.id}`);
  };

  const suspendCalls = (listId, campaignRun, idx) => {
    Swal.fire({
      title: "Warning!",
      text: "Do you want to terminate the campaign?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Cancel",
      denyButtonText: `Terminate`,
      denyButtonColor: "#7066e0",
      confirmButtonColor: "#dc3741",
      willClose: () => {},
    }).then((result) => {
      if (result.isConfirmed) {
        return false;
      } else if (result.isDenied) {
        const url = process.env.REACT_APP_API_BASE_URL || "";
        axios
          .post(url.concat("stop-call-list"), {
            listId: listId,
            campaignrunId: campaignRun,
          })
          .then(async (res) => {
            if (res.status === 200) {
              await dispatch(
                campaignAction.getRunCampaignList(
                  id,
                  campaignData.audienceList.count
                )
              );
              swalSuccessMessage("Campaign Terminated");
            }
          });
      }
    });
  };
  const productElements1: (JSX.Element | null)[] = campaignRunList?.map(
    (campaignRun: any, idx) => {
      if (!campaignRun) {
        return null;
      }

      return (
        <tr key={idx}>
          <th scope="row">{idx + 1}</th>
          <td>{campaignRun?.timeStamp}</td>
          <td
            className={`text-capitalize ${
              campaignRun?.status === "completed" ? "completed" : "inProgress"
            }`}
          >
            {campaignRun?.status}
          </td>
          <td className="text-center">{campaignRun?.audienceCount}</td>
          <td scope="col" className="text-center">
            {campaignRun?.cost}
          </td>
          <td>
            <button
              className="btn btn-info"
              onClick={() => viewCampaignRunDetails(campaignRun)}
            >
              View Campaign Run Details
            </button>
          </td>
          <td>
            <button
              disabled={
                campaignRun?.status === "completed" || campaignRun?.suspended
                  ? true
                  : false
              }
              className="btn btn-danger"
              onClick={() => {
                suspendCalls(campaignRun.listId, campaignRun.id, idx);
              }}
            >
              Stop Campaign
            </button>
          </td>
        </tr>
      );
    }
  );

  let modifiedName;

  function hasAndAfterSpace(string) {
    return /\s(?=and)/.test(string);
  }
  if (
    campaignData?.campaignName &&
    hasAndAfterSpace(campaignData?.campaignName)
  ) {
    modifiedName = campaignData?.campaignName.toString().replace("and", "&");
  }
  return (
    <Fragment>
      <h1
        className="h3 mb-2 text-gray-800"
        style={{ textTransform: "capitalize" }}
      >
        {modifiedName ? modifiedName : campaignData?.campaignName}
      </h1>
      <p className="mb-4">View your campaign runs here</p>
      <div className="row">
        <TopCard
          title="Total Campaign Runs"
          text={`${totalCampaignRunPageCampaignRun}`}
          icon="warehouse"
          class="danger"
        />

        <TopCard
          title="Total Calls Connected"
          text={`${totalCampaignRunPageCallConnected}`}
          icon="box"
          class="primary"
        />
        <TopCard
          title="Total Calls Made"
          text={`${totalCampaignRunPageCallMade}`}
          icon="phone-volume"
          class="success"
        />
      </div>

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">
                Campaign Run List
              </h6>
              <div className="header-buttons">
                <button
                  className="btn btn-success"
                  onClick={
                    () => {
                      history.push("/campaigns");
                    }
                    // dispatch(setModificationState(ProductModificationStatus.Create))
                  }
                >
                  <i className="fas fa fa-chevron-left"></i> Back
                </button>
                &nbsp;
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive portlet">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Campaign Run Started At</th>
                      <th scope="col">Campaign Run Status</th>
                      <th scope="col">Audience Count</th>
                      <th scope="col">Campaign Cost</th>
                      <th scope="col">View Campaign Run Details</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaignRunList.length > 0 && productElements1}
                  </tbody>
                </table>
              </div>

              {spin && campaignRunList?.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  No Campaign run found
                </div>
              )}

              {!spin && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bolder",
                  }}
                >
                  Loading ...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ProductList;
