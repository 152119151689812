import React, { useState, Dispatch, Fragment, useEffect } from "react";
import { IStateType, IProductState } from "../../store/models/root.interface";
import { useSelector, useDispatch } from "react-redux";
import { ProductModificationStatus } from "../../store/models/product.interface";
import TextInput from "../../common/components/TextInput";
import { setModificationState } from "../../store/actions/campaign.action";
import { addNotification } from "../../store/actions/notifications.action";
import { OnChangeModel } from "../../common/types/Form.types";
import "react-voice-recorder/dist/index.css";
import Multiselect from "multiselect-react-dropdown";
import * as campaignAction from "../../store/Store/campaigns/campaigns.action";
import { swalSuccessMessage } from "../../Constant/swalMessage";
import { getAudienceList } from "../../store/Store/Audience/audience.action";

const ProductForm: React.FC = () => {
  useEffect(() => {
    const init = async () => {
      await dispatch(getAudienceList());
    };
    init();
  }, []);
  const dispatch: Dispatch<any> = useDispatch();
  const products: IProductState | null = useSelector(
    (state: IStateType) => state.products
  );
  const reducer: any = useSelector((state) => state);
  const { mainAudienceReducer } = reducer;
  const { audienceList } = mainAudienceReducer;
  const audience: any = useSelector((state: any) => state.audience);
  const isCreate: boolean =
    products.modificationState === ProductModificationStatus.Create;

  const [formState, setFormState] = useState({
    name: { error: "", value: "" },
    audiences: [{ value: "" }],
  });
  const [msg, setMsg] = useState("");
  const [selectedAudiences, setSelectedAudieces] = useState<any>([]);
  const [spin, setSpin] = useState(false);

  function hasFormValueChanged(model: OnChangeModel): void {
    if (model.field === "name") {
      if (/[^a-zA-Z0-9\&\ /]/.test(model.value.toString())) {
        setMsg("Name can only contain alphanumeric characters, ampersand(&)");
        setTimeout(() => {
          setMsg("");
        }, 5000);
      } else {
        setFormState({
          ...formState,
          [model.field]: { error: model.error, value: model.value.toString() },
        });
      }
    } else {
      setFormState({
        ...formState,
        [model.field]: { error: model.error, value: model.value },
      });
    }
  }

  async function saveForm(e) {
    e.preventDefault();
    if (isFormInvalid()) {
      return;
    }
    setSpin(true);
    if (selectedAudiences.length < 1) {
      setMsg("please select Audience");
      setSpin(false);
      return;
    }
    let audiencArray: any = [];
    selectedAudiences.forEach((val) => {
      audiencArray.push(val.id);
    });
    let obj = {
      campaignName: formState.name.value,
      audienceListId: audiencArray,
      botPlatform: "dialogflow",
      project: "default",
    };

    let res: any = await dispatch(campaignAction.saveCampaign(obj));
    if (res) {
      await dispatch(campaignAction.getCampaignPageWidgetsCount());
      await dispatch(campaignAction.getCampaignList());
      dispatch(
        addNotification(
          "Campaign created",
          `Campaign ${formState.name.value} created by you`
        )
      );
      swalSuccessMessage("Campaign Created");
      setSpin(false);
      cancelForm();
    } else {
      setSpin(false);
    }
  }

  function cancelForm(): void {
    dispatch(setModificationState(ProductModificationStatus.None));
  }

  function getDisabledClass(): string {
    let isError: boolean = isFormInvalid();
    return isError ? "disabled" : "";
  }

  function isFormInvalid(): boolean {
    return (formState.name.error || !formState.name.value) as boolean;
  }

  const handleChangeList = (e) => {
    setSelectedAudieces(e);
  };

  return (
    <Fragment>
      <div className="col-xl-8 col-lg-8">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">
              {isCreate ? "Create a new" : "Edit your"} Campaign
            </h6>
          </div>
          <div className="card-body">
            <form onSubmit={saveForm}>
              <div className="form-group">
                <TextInput
                  id="input_email"
                  value={formState.name.value}
                  field="name"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={30}
                  label="Give this campaign a name"
                  placeholder="e.g. Real Estate Campaign"
                />
              </div>

              <Multiselect
                className="multi-select"
                options={audienceList || []}
                selectedValues={selectedAudiences}
                onSelect={handleChangeList}
                onRemove={handleChangeList}
                displayValue="audienceListName"
                placeholder="Select Audiences"
                avoidHighlightFirstOption={true}
              />

              <p style={{ color: "red" }}>{msg}</p>
              <button className="btn btn-danger" onClick={() => cancelForm()}>
                Cancel
              </button>
              <button
                disabled={spin}
                type="submit"
                className={`btn btn-success left-margin ${getDisabledClass()}`}
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductForm;
