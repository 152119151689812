import React, { Fragment, Dispatch, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import CampaignList from "./CampaignDetailsList";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import * as campaignAction from "../../store/Store/campaigns/campaigns.action";
import SkeletonLoding from "../../Constant/SkeletonLoding";

const Products: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    dispatch(updateCurrentPath("campaigns", "list"));
  }, [path.area, dispatch]);
  let campaignDetails: any = localStorage.getItem("campaign");
  let campaignRunDetails: any = localStorage.getItem("campaignRuns");
  let campaignRunDetailsData = JSON.parse(campaignRunDetails);
  let data = JSON.parse(campaignDetails);
  let id = window.location.href.split("?")[1];
  let modifiedName;
  function hasAndAfterSpace(string) {
    return /\s(?=and)/.test(string);
  }
  if (data?.campaignName && hasAndAfterSpace(data?.campaignName)) {
    modifiedName = data?.campaignName.toString().replace("and", "&");
  }
  useEffect(() => {
    let callFn = async () => {
      await dispatch(campaignAction.getCampaignDetailsList(id, ""));
      await dispatch(campaignAction.getCampaignRunDetailsPageWidgetsCount(id));
      setSpin(true);
    };
    callFn();
  }, [dispatch]);

  return spin ? (
    <Fragment>
      <h1
        className="h3 mb-2 text-gray-800"
        style={{ textTransform: "capitalize" }}
      >
        {modifiedName ? modifiedName : data?.campaignName} at{" "}
        {campaignRunDetailsData?.timeStamp?.includes("/")
          ? campaignRunDetailsData?.timeStamp
          : `${campaignRunDetailsData?.timeStamp} IST`}
      </h1>{" "}
      <p className="mb-4">View your campaign run details here</p>
      <CampaignList
        name={` ${modifiedName ? modifiedName : data?.campaignName} at ${
          campaignRunDetailsData?.timeStamp
        } IST`}
        nameOnly={modifiedName ? modifiedName : data?.campaignName}
      />{" "}
    </Fragment>
  ) : (
    <SkeletonLoding />
  );
};

export default Products;
