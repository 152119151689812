import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import StripeCheckOut from "react-stripe-checkout";
import { useHistory } from "react-router-dom";
import { updateCurrentPath } from "../../store/actions/root.actions";
import { useDispatch } from "react-redux";
import "./NewPayment/payment.css";
import {
  createOrder,
  savePayment,
  stripePayment,
  updateAccountPayment,
  verifyOrder,
} from "../../store/Store/Payment/payment.action";
import { profileCheck } from "../../store/Store/User/user.action";
import {
  swalErrorMessage,
  swalSuccessMessage,
  swalWarningMessage,
} from "../../Constant/swalMessage";
import SkeletonLoding from "../../Constant/SkeletonLoding";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import Popup from "reactjs-popup";
import "./Topup.css";

const useStyles = makeStyles({
  root: {
    marginTop: "10%",
    backgroundColor: "whitesmoke",
    color: "white",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    backgroundColor: "whitesmoke",
    color: "white",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    margin: "20px",
    marginLeft: "50px",
  },
});

function TopupAccount() {
  const classes = useStyles();
  const history = useHistory();
  let email1 = localStorage.getItem("user_email");
  const [show, setShow] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [currency, setCurrency] = useState("usd");
  const [product, setProduct] = useState({
    name: email1,
    price: currency === "inr" ? "5000" : "100",
    productBy: "Vodex",
  });
  const [spin, setSpin] = useState(false);
  const [spin1, setSpin1] = useState(true);
  const [msg, setMsg] = useState("");

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [signature, setSignature] = useState("");
  const [popup, setPopup] = useState(false);

  const dispatch = useDispatch();
  const makepayment = async (token) => {
    setSpin(true);
    const body = {
      token,
      product,
    };
    await dispatch(savePayment(body));
    setSpin(false);
  };
  let indianCost = ["5000", "25000", "50000"];
  let usdCost = ["100", "500", "1000"];
  let arr = currency === "inr" ? indianCost : usdCost;
  let cost = [...arr, "Enter Manually"];

  dispatch(updateCurrentPath("topup", ""));
  useEffect(() => {
    const init = async () => {
      let res = await dispatch(profileCheck());
      console.log("ressss", res);
      setCurrency(res?.currency);
      setName(res?.name);
      setPhone(res?.phone);
      setProduct((prev) => ({
        ...prev,
        price: res?.currency === "inr" ? "5000" : "100",
      }));
      let str =
        res?.currency === "inr"
          ? "Enter Amount, minimum amount is ₹1000"
          : "Enter Amount, minimum amount is $10";
      setMsg(str);
      if (!res?.profile_completed) {
        swalWarningMessage("Please complete your profile first");
        history.push("/users");
      }
      setSpin1(false);
    };
    init();
  }, []);

  const handlePayment = async () => {
    setSpin(true);
    let obj = {
      amount: product.price,
      notes: {
        user: name,
      },
    };
    const response = await dispatch(createOrder(obj));
    setOrderId(response.orderId);
    const options = {
      image: "favicon1.ico",
      key: process.env.REACT_APP_BASE_RAZORPAYKEY,
      amount: response.amount,
      currency: currency.toUpperCase(),
      name: "Vodex",
      description: `Vodex Topup of ${currency.toUpperCase()}${response.amount}`,
      order_id: response.orderId,
      handler: function (response) {
        setPaymentId(response.razorpay_payment_id);
        setSignature(response.razorpay_signature);
        verifyPayment(
          response.razorpay_payment_id,
          response.razorpay_order_id,
          response.razorpay_signature
        );
      },
      modal: {
        ondismiss: function () {
          setSpin(false);
        },
      },
      prefill: {
        name: name,
        email: email1,
        contact: phone,
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const verifyPayment = async (paymentId, orderId, signature) => {
    setSpin(true);

    let obj = {
      paymentId: paymentId,
      orderId: orderId,
      signature: signature,
      price: product.price,
    };
    await dispatch(verifyOrder(obj));
    setSpin(false);
    history.push("/dashboard");
  };

  const stripe = useStripe();
  const elements = useElements();

  const pay = async () => {
    try {
      setSpin(true);
      const response = await dispatch(stripePayment({ amount: product.price }));
      const data = response;
      const cardElement = elements.getElement(CardElement);
      const confirmPayment = await stripe.confirmCardPayment(
        data.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name,
            },
          },
        }
      );
      const { paymentIntent } = confirmPayment;
      if (paymentIntent.status === "succeeded") {
        let finalRes = await dispatch(
          updateAccountPayment({ amount: product.price })
        );
        if (finalRes === 200) {
          swalSuccessMessage("Payment Successful");
          history.push("/dashboard");
        }
      } else swalErrorMessage("Payment Successful");
    } catch (err) {
      setSpin(false);
      swalWarningMessage("There was an error in payment");
    }
  };
  return spin1 ? (
    <SkeletonLoding />
  ) : (
    <div>
      <h4 className="ml-4">Topup Account</h4> <hr />{" "}
      <div className=" payCard2 bg-gradient-primary-green ">
        <span>
          <p className="planName"> Topup Account With </p>
          <div className="row row2">
            {cost.map((cost) => (
              <div className="col col1 ">
                <button
                  defaultValue={"10"}
                  className={
                    cost === product.price
                      ? "button1 btn btn-success m-1"
                      : "button1 btn btn-light m-1"
                  }
                  onClick={() => {
                    if (cost === "Enter Manually") {
                      setProduct({ ...product, price: "" });
                      setShow(true);
                    } else {
                      setProduct({ ...product, price: cost });
                      setShow(false);
                    }
                  }}
                >
                  {cost === "Enter Manually"
                    ? "Enter Manually"
                    : ` ${currency === "inr" ? `₹${cost}` : `$${cost}`}   `}
                </button>
              </div>
            ))}
          </div>
        </span>
        {show ? (
          <div style={{ justifyContent: "center" }}>
            <input
              placeholder="Enter amount"
              type="number"
              className="form-control m-3"
              onChange={(e) => {
                if (e.target.value.includes("e")) {
                  setMsg("Enter only numbers");
                  setTimeout(() => {
                    setMsg("Enter Amount, minimnum amount is 10");
                  }, 4000);
                } else {
                  setProduct({ ...product, price: e.target.value });
                }
              }}
              value={product.price}
              required
              style={{ width: "95%" }}
            />
            <p className="m-3" style={{ color: "whitesmoke" }}>
              {" "}
              {msg}
            </p>
          </div>
        ) : null}
        {parseInt(product.price) >= (currency === "inr" ? 1000 : 10) &&
          (currency === "inr" ? (
            <button
              disabled={spin}
              className="btn btn-success mt-3"
              style={{ width: "20%" }}
              onClick={handlePayment}
            >
              {spin ? "Processing" : "Pay"}
            </button>
          ) : (
            <button
              className="btn btn-success"
              style={{ width: "20%" }}
              onClick={() => setPopup(true)}
            >
              Next
            </button>
          ))}

        <Popup
          contentStyle={{ height: "285px", minWidth: "615px" }}
          className="popup-modal"
          open={popup}
          onClose={() => setPopup(false)}
          closeOnDocumentClick={!spin}
          closeOnEscape={!spin}
        >
          <div className="popup-modal ">
            <div
              className="popup-title "
              style={{ height: "90px", fontSize: "20px", fontWeight: "bolder" }}
            >
              Payment Details
              <br />
              <label
                style={{ color: "red", margin: "5px", fontWeight: "bolder" }}
              >
                {" "}
                Amount ${product.price}
              </label>
            </div>
            <br />
            <div className="popup-content ">
              <div className="checkout">
                <CardElement />
              </div>

              <br />
              <button
                className="pay-button"
                disabled={spin}
                onClick={pay}
                style={{ width: "30%" }}
              >
                {spin ? "Loading..." : "Pay"}
              </button>
            </div>
          </div>
        </Popup>

        {/* ) : null} */}
      </div>
    </div>
  );
}

export default TopupAccount;
