import React, { Dispatch, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IStateType,
  IProductState,
} from "../../../store/models/root.interface";
import {
  IProduct,
  ProductModificationStatus,
} from "../../../store/models/product.interface";
import Popup from "reactjs-popup";
import { addNotification } from "../../../store/actions/notifications.action";

import {
  removeTemplate,
  clearSelectedProduct,
  setModificationState,
  changeSelectedProduct,
} from "../../../store/actions/template.action";

const Swal = require("sweetalert2");

let localUserEmail = localStorage.getItem("user_email");

export type productListProps = {
  onSelect?: (product: IProduct) => void;
  children?: React.ReactNode;
};

function ProductList(props: productListProps): JSX.Element {
  const dispatch: Dispatch<any> = useDispatch();
  const [popup, setPopup] = useState(false);

  const products: IProductState = useSelector(
    (state: IStateType) => state.products
  );

  const templates: any = useSelector((state: any) => state.templateReducer);

  templates.template.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const productElements: (JSX.Element | null)[] = templates.template.map(
    (temp, idx) => {
      if (!temp) {
        return null;
      }

      return (
        <tr
          onClick={() => {
            if (props.onSelect) props.onSelect(temp);
          }}
          key={`product_${temp.id}`}
        >
          <th scope="row">{idx + 1}</th>
          <td>{temp.name}</td>
          <td className="text-truncate" style={{ maxWidth: "150px" }}>
            {temp.description}
          </td>
          <td>{temp.language}</td>
          <td>{temp.recordingArray.length}</td>
          <td>
            <button
              className="btn btn-primary m-1"
              onClick={() => {
                if (props.onSelect) props.onSelect(temp);
                dispatch(setModificationState(ProductModificationStatus.Edit));
              }}
            >
              Copy
            </button>
            <button
              className="btn btn-primary m-1"
              onClick={() => {
                if (props.onSelect) props.onSelect(temp);
                dispatch(setModificationState(ProductModificationStatus.Edit));
              }}
            >
              Edit
            </button>
            <button
              className="btn btn-danger m-1"
              onClick={() => {
                if (props.onSelect) props.onSelect(temp);

                setPopup(true);
              }}
            >
              Delete
            </button>
          </td>
        </tr>
      );
    }
  );

  return (
    <div className="table-responsive portlet">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col"> Name</th>
            <th scope="col "> Description</th>
            <th scope="col"> Language</th>
            <th scope="col">Recording Count</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {templates.template.length > 0 ? (
            productElements
          ) : (
            <tr className={"table-row"}>
              <td></td>
              <td style={{ minWidth: 300 }}>
                No Templates. Click on "Add Template" to add a new template
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">
            Remove {templates.selectedProduct && templates.selectedProduct.name}{" "}
            Template?
          </div>
          <div className="popup-content">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!templates.selectedProduct) {
                  return;
                }
                dispatch(
                  addNotification(
                    "Template removed",
                    `Template ${templates.selectedProduct.name} was removed`
                  )
                );
                dispatch(removeTemplate(templates.selectedProduct.id));
                dispatch(clearSelectedProduct());
                setPopup(false);
                Swal.fire({
                  title: "Success!",
                  text: "Template removed",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default ProductList;
