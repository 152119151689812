import React, { Dispatch, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IProduct } from "../../store/models/product.interface";
import Popup from "reactjs-popup";
import { addNotification } from "../../store/actions/notifications.action";

import { clearSelectedProduct } from "../../store/actions/products.action";
import {
  removeMessage,
  setModificationState1,
} from "../../store/actions/whatsapp.action";

import { ProductModificationStatus } from "../../store/models/product.interface";

export type productListProps = {
  onSelect?: (product: IProduct) => void;
  children?: React.ReactNode;
};

function ProductList(props: productListProps): JSX.Element {
  const dispatch: Dispatch<any> = useDispatch();
  const [popup, setPopup] = useState(false);

  const products: any = useSelector((state: any) => state.whatsappReducer);

  products.audience.sort(function (a, b) {
    if (a.tag.toLowerCase() < b.tag.toLowerCase()) {
      return -1;
    }
    if (a.tag.toLowerCase() > b.tag.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const productElements: (JSX.Element | null)[] = products.audience.map(
    (product, idx) => {
      if (!product) {
        return null;
      }
      return (
        <tr key={`product_${product.id}`}>
          <th scope="row">{idx + 1}</th>
          <td className="text-truncate" style={{ maxWidth: "200px" }}>
            {product.message}
          </td>
          <td>{product.tag}</td>

          <td>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (props.onSelect) props.onSelect(product);
                dispatch(setModificationState1(ProductModificationStatus.Edit));
              }}
            >
              Edit
            </button>
          </td>
          <td>
            <button
              className="btn btn-danger"
              onClick={() => {
                if (props.onSelect) props.onSelect(product);

                setPopup(true);
              }}
            >
              Delete
            </button>
          </td>
        </tr>
      );
    }
  );

  return (
    <div className="table-responsive portlet">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Message Name</th>
            <th scope="col">Message Tag</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {products.audience.length > 0 ? (
            productElements
          ) : (
            <tr className={"table-row"}>
              <td></td>
              <td>No Messages. Click on "Add Message" to add a new message</td>
            </tr>
          )}
        </tbody>
      </table>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
        contentStyle={{ height: "auto" }}
      >
        <div className="popup-modal">
          <div
            className="popup-title text-truncate"
            style={{ height: "100px" }}
          >
            Remove{" "}
            {products.selectedProduct
              ? products.selectedProduct.message
              : "this"}{" "}
            message?
            <br />
            <button
              type="button"
              className="btn btn-danger mt-4"
              onClick={() => {
                if (!products.selectedProduct) {
                  return;
                }
                dispatch(
                  addNotification(
                    "Message removed",
                    `Message ${products.selectedProduct.message} was removed`
                  )
                );
                dispatch(removeMessage(products.selectedProduct));
                dispatch(clearSelectedProduct());
                setPopup(false);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default ProductList;
