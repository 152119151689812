import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProductModificationStatus } from "../../store/models/product.interface";
import { clearSelectedProduct } from "../../store/actions/products.action";
import { addNotification } from "../../store/actions/notifications.action";
import {
  addMessage,
  editMessage,
  setModificationState1,
} from "../../store/actions/whatsapp.action";
import useUnsavedChangesWarning from "../Hooks/useUnsavedChangesWarning";
const WhatsappForm = () => {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state);
  const { whatsappReducer, products } = reducer;
  let product = whatsappReducer.selectedProduct;
  const isCreate =
    whatsappReducer.modificationState === ProductModificationStatus.Create;

  if (!product || isCreate) {
    product = {
      id: 0,
      name: "",
      tag: "",
    };
  }

  const [formState, setFormState] = useState({
    name: { error: "", value: product.message },
    tag: { error: "", value: product.tag },
  });

  const [htmlMsg, setHtmlMsg] = useState("");
  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      name: { error: "", value: product.message || "" },
      tag: { error: "", value: product.tag },
    }));
  }, [whatsappReducer?.selectedProduct]);

  const [loading, setLoading] = useState(false);
  const email = useSelector((state) => state.account.email);
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  useEffect(() => {
    return () => {
      dispatch(setModificationState1(ProductModificationStatus.None));
    };
  }, []);

  const msgChange = (e) => {
    setDirty();
    setFormState({
      ...formState,
      [e.target.name]: { error: "", value: e.target.value },
    });
  };
  function saveUser(e) {
    e.preventDefault();
    let saveUserFn = isCreate ? addMessage : editMessage;
    saveForm(formState, saveUserFn);
  }

  function saveForm(formState, saveFn) {
    if (formState.tag.value && formState.name.value) {
      setLoading(true);
      setPristine();

      if (product) {
        dispatch(
          saveFn({
            ...product,
            email: email,
            name: formState.name.value,
            tag: formState.tag.value,
            htmlMsg: htmlMsg,
          })
        );

        if (isCreate) {
          dispatch(
            addNotification(
              "Message Created",
              `Message ${formState.name.value} Created by you`
            )
          );
        } else {
          dispatch(
            addNotification(
              "Message Edited",
              `Message ${formState.name.value} Edited by you`
            )
          );
        }

        dispatch(clearSelectedProduct());
        dispatch(setModificationState1(ProductModificationStatus.None));
        setLoading(false);
      }
    }
  }

  function cancelForm() {
    dispatch(setModificationState1(ProductModificationStatus.None));
  }

  useEffect(() => {
    const addLinebreaks = (anyString) => {
      const htmlString = anyString.replace(/(\r\n|\n|\r)/gm, "\\n");
      setHtmlMsg(htmlString);
    };
    if (formState?.name?.value) {
      addLinebreaks(formState.name.value);
    }
  }, [formState.name.value]);
  return (
    <Fragment>
      {Prompt}
      <div className="col-xl-8 col-lg-8">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-green">
              {isCreate ? "Create a new" : "Edit your"} Message
            </h6>
          </div>
          <div className="card-body">
            <form onSubmit={saveUser}>
              <div className="form-group2">
                {/* <TextInput
                  id="tag"
                  value={formState.tag.value}
                  field="tag"
                  onChange={hasFormValueChanged}
                  required={true}
                  maxLength={20}
                  label="When this recording is played"
                  placeholder="e.g. welcome"
                /> */}

                <label>When this Recording is played...</label>
                <select
                  value={formState.tag.value}
                  className="form-control mb-1"
                  name="tag"
                  onChange={msgChange}
                >
                  <option>Choose...</option>
                  {products.products.map((record) => (
                    <option value={record.tag}>{record.name}</option>
                  ))}
                </select>
              </div>
              <label>Then send this Whatsapp message</label>
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="input_email"
                  value={formState.name.value}
                  name="name"
                  onChange={msgChange}
                  required={true}
                  placeholder="e.g. Hi! How may I help you?"
                />
              </div>

              <button className="btn btn-danger" onClick={() => cancelForm()}>
                Cancel
              </button>

              <button
                type="submit"
                className={`btn btn-success ml-1`}
                disabled={loading}
              >
                {loading ? "Saving" : "Save"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WhatsappForm;
