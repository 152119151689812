import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactAudioPlayer from "react-audio-player";
import { db } from "../../firebase/index";
import "../../styles/callStatus.css";
import {
  addCampaignRunDetails,
  editCamapaignRunDetails,
} from "../../store/actions/campaignRunsDetail.actions";
import {
  Dropdown,
  Modal,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import Drawer from "@material-ui/core/Drawer";
import { fetchAudience } from "../../store/actions/audience.action";
import { addNotification } from "../../store/actions/notifications.action";
import Popup from "reactjs-popup";
import { Link, useHistory } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TopCard from "../../common/components/TopCard";
import InfiniteScroll from "react-infinite-scroller";
import "./CampaignDetails.css";
import * as campaignAction from "../../store/Store/campaigns/campaigns.action";
import { swalSuccessMessage } from "../../Constant/swalMessage";
import SummaryModel from "./SummaryModel";
import InsightsModal from "./InsightsModal";
import InsightsViewModal from "./InsightsViewModal";

const Swal = require("sweetalert2");

let localUserEmail = localStorage.getItem("user_email");

function ProductList({ name, nameOnly }): JSX.Element {
  let campaignDetails: any = localStorage.getItem("campaign");
  let data = JSON.parse(campaignDetails);
  let campaignRunDetails1: any = localStorage.getItem("campaignRuns");
  let campaignRunDetailsData1 = JSON.parse(campaignRunDetails1);
  const [campaignRunDetailsData, setCampaignRunDetailsData] = useState<any>(
    campaignRunDetailsData1
  );

  let id = window.location.href.split("?")[1];
  const reducer: any = useSelector((state) => state);
  const { mainCampaignReducer } = reducer;
  const {
    runCampaignDetailsList,
    totalCampaignRunDetailsPageCallFailed,
    totalCampaignRunDetailsPageCallConnected,
    totalCampaignRunDetailsPageCallMade,
  } = mainCampaignReducer;
  const [state, setState] = useState<any>({
    campaignRunListData: [],
    showCreateButton: false,
    mainSpin: true,
    lastKey: 0,
    withoutPaginationData: [],
    exportSpin: false,
    newCampaignData: [],
    summaryModal: false,
    recordingUrl: "",
    selectedCampaignRun: "",
    insightsPopup: false,
    insightsPopupView: false,
  });

  const {
    campaignRunListData,
    showCreateButton,
    mainSpin,
    lastKey,
    withoutPaginationData,
    exportSpin,
    newCampaignData,
    summaryModal,
    insightsPopup,
    insightsPopupView,
  } = state;
  const toggle = () => {
    setState((prev) => ({
      ...prev,
      summaryModal: !prev.summaryModal,
    }));
  };
  const [hasMore, setHasMore] = useState(
    campaignRunDetailsData?.type === "main" ? false : true
  );

  useEffect(() => {
    if (runCampaignDetailsList) {
      setState((prev) => ({
        ...prev,
        withoutPaginationData: runCampaignDetailsList,
        campaignRunListData: runCampaignDetailsList.slice(0, 100),
        mainSpin: false,
        lastKey: 100,
      }));
    }
  }, [runCampaignDetailsList]);

  useEffect(() => {
    const callFn = async () => {
      setState((prev) => ({
        ...prev,
        mainSpin: true,
      }));
      await dispatch(campaignAction.getCampaignDetailsList(id, ""));
      await dispatch(campaignAction.getCampaignRunDetailsPageWidgetsCount(id));
      setState((prev) => ({
        ...prev,
        mainSpin: false,
      }));
    };
    callFn();
  }, [campaignRunDetailsData?.type !== "main"]);

  //old code

  const dispatch = useDispatch();
  const products: any = useSelector((state: any) => state.products);
  let trackO = products.products.filter((o) => o.track === true);
  const campaigns: any = useSelector((state: any) => state.campaignRunDetails);
  const audience: any = useSelector((state: any) => state.audience);
  const history = useHistory();

  const [spin, setSpin] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [buttonSpin, setButtonSpin] = useState(true);
  const [filterArray1, setFilterArray1] = useState<any>([]);
  const [campaignName, setCampaignName] = useState("");
  const [audienceName, setAudienceName] = useState("");
  const [popup, setPopup] = useState(false);
  const [popup1, setPopup1] = useState(false);
  const [spin1, setSpin1] = useState(true);
  const [outcomeArray, setOutcomeArray] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [filterdata, setFilterData] = useState<any>([]);
  const [campaignRunData, setCampaignRunData] = useState<any>([]);
  const [callConnNo, setCallConnNo] = useState(0);
  const [callFailedNo, setCallFailedNo] = useState(0);
  const [totalData, setTotalData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  let alld: any = [];
  function Modification(doc) {
    let data = {
      callRecordingUrl: doc.data().callRecordingUrl,
      callStatus: doc.data().callStatus,
      campaignRunId: doc.data().campaignRunId,
      phone: doc.data().phone,
      userEmail: doc.data().userEmail,
      cost: doc.data().cost,
      newCost: doc.data().newCost,
      id: doc.id,
      outcomes: doc.data().outcomes,
    };

    let c = campaigns.campaigns.find((o) => o.id === doc.id);
    if (!c) {
      dispatch(addCampaignRunDetails(data));
    }

    setCampaignRunData((oldArray) => [
      ...oldArray,
      {
        callRecordingUrl: doc.data().callRecordingUrl,
        callStatus: doc.data().callStatus,
        campaignRunId: doc.data().campaignRunId,
        phone: doc.data().phone,
        userEmail: doc.data().userEmail,
        cost: doc.data().cost,
        newCost: doc.data().newCost,
        id: doc.id,
        outcomes: doc.data().outcomes,
      },
    ]);

    alld.push(data);
  }
  let unsubscribe;

  // Live code for number loading

  const modified = (doc) => {
    const newArr = alld.map((element) => {
      if (element.id === doc.id) {
        let id = doc.id;
        let data = doc.data();
        dispatch(editCamapaignRunDetails({ id, ...data }));
        return { id, ...data };
      }
      return element;
    });
    setTotalData(newArr);
    alld = newArr;
    setCampaignRunData(newArr);
  };

  const campaignRunRef = db
    .collection("campaignRunDetails")
    .where("userEmail", "==", localUserEmail)
    .where("campaignRunId", "==", id)
    .orderBy("phone", "desc");
  const fetchData = () => {
    unsubscribe = campaignRunRef
      .where("userEmail", "==", localUserEmail)
      .where("campaignRunId", "==", id)
      .onSnapshot((snapshot) => {
        let changes = snapshot.docChanges();
        changes.forEach((change) => {
          change.doc.data();
          if (change.type == "added") {
            Modification(change.doc);
          } else if (change.type == "modified") {
            modified(change.doc);
          }
        });
        setSpin(true);
      });
  };
  useEffect(() => {
    return () => {
      let obj = {
        ...campaignRunDetails1,
        type: "",
      };

      if (unsubscribe) {
        unsubscribe();
        localStorage.setItem("campaignRuns", JSON.stringify(obj));
      } else {
        console.log("unmounted");
        localStorage.setItem("campaignRuns", JSON.stringify(obj));
      }
    };
  }, [campaignRunDetailsData.type]);

  useEffect(() => {
    if (campaignRunDetailsData.type === "main") {
      fetchData();
    }
  }, []);

  const shortenString = (text, max) => {
    if (text && text.length > max) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="text-tooltip" id={`tooltip_${Date.now()}`}>
              {text}
            </Tooltip>
          }
        >
          <span>{`${text.slice(0, max)}...`}</span>
        </OverlayTrigger>
      );
    }
    return text;
  };

  const getOutcome = (data) => {
    let trackResult: any = [];
    if (data?.outcomes) {
      data.outcomes.forEach((da) => {
        let kl = trackO.find((kl) => kl.tag === da);
        if (kl) {
          trackResult.push(kl.name);
        }
      });

      // trackO?.forEach((track) => {
      //   if (data.outcomes.includes(track.tag)) {
      //     trackResult.push(track.name);
      //   }
      // });
      // return shortenString(trackResult.join(","), 20);
      return trackResult.length > 0 ? trackResult[0] : "";
    } else {
      return "";
    }
  };

  const productElements1 = campaignRunData.map((campaign, idx) => {
    const { callStatus } = campaign;

    if (!campaign) {
      return null;
    }
    if (campaign.duration) {
    }
    return (
      <tr key={idx}>
        <th scope="row">{idx + 1}</th>
        <td>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-disabled">
                {" "}
                {/* {getName(campaign.phone)} */}
              </Tooltip>
            }
          >
            <span className="d-inline-block">
              <span style={{ pointerEvents: "none" }}>{campaign.phone} </span>
            </span>
          </OverlayTrigger>
        </td>
        <td
          style={{ width: "150px" }}
          className={`${
            (callStatus === "Call Connected" && "connected") ||
            (callStatus === "Calling..." && "calling") ||
            (callStatus === "Call Disconnected" && "disconnected") ||
            (callStatus === "Call Failed" && "failed") ||
            (callStatus === "Invalid Number" && "failed")
          }`}
        >
          {campaign.callStatus === "Call Disconnected"
            ? "Call Ended"
            : campaign.callStatus}
        </td>
        <td>{getOutcome(campaign)}</td>
        <td>{campaign.newCost || "--"}</td>
        <td className="tableRightCol">
          <ReactAudioPlayer
            src={campaign.callRecordingUrl}
            controls
            style={{
              minWidth: "40px",
              height: "30px",
            }}
          />
        </td>
      </tr>
    );
  });

  //Live code end
  const arrayListFind = (listName) => {
    let arr: any = [];
    if (Array.isArray(listName)) {
      listName.forEach((val) => {
        let kl = audience.audience.find((data) => data.id === val);
        if (kl) {
          arr.push(...kl.list);
        }
      });
      return arr;
    } else {
      let audienceArray = audience.audience.find((o) => o.id === listName);
      if (audienceArray) {
        return audienceArray.list;
      }
    }
  };
  const getName = (phone) => {
    const numberList = arrayListFind(data?.audienceListName);
    let nameFind = numberList.find((num) => num.phone === phone);
    if (nameFind) {
      return `${nameFind.firstName || ""} ${nameFind.lastName || ""}`;
    } else {
      return "No Name";
    }
  };

  const popover = (data) => {
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Call Information</Popover.Title>
        <Popover.Content>
          Call Reference Id : <strong>{data}</strong>
        </Popover.Content>
      </Popover>
    );
  };

  const openModal = (selectedCampaignRun) => {
    setState((prev) => ({
      ...prev,
      summaryModal: true,
      recordingUrl: selectedCampaignRun.recordingUrl,
      selectedCampaignRun: selectedCampaignRun,
    }));
  };
  const productElementsAlreadyRun: (JSX.Element | null)[] =
    campaignRunListData?.map((campaign: any, idx) => {
      const { callStatus } = campaign;

      if (!campaign) {
        return null;
      }
      if (campaign.duration) {
      }
      return (
        <tr key={idx}>
          <th scope="row">{idx + 1}</th>
          <td>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-disabled">
                  {" "}
                  {getName(campaign.phone)}
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <span style={{ pointerEvents: "none" }}>{campaign.phone} </span>
              </span>
            </OverlayTrigger>
          </td>
          <td
            style={{ width: "150px" }}
            className={`${
              (callStatus === "Call Connected" && "connected") ||
              (callStatus === "Calling..." && "calling") ||
              (callStatus === "Call Ended" && "disconnected") ||
              (callStatus === "Call Failed" && "failed") ||
              (callStatus === "Invalid Number" && "failed")
            }`}
          >
            {campaign.callStatus === "Call Disconnected"
              ? "Call Ended"
              : campaign.callStatus}
          </td>
          <td>
            <div className="d-flex">
              {getOutcome(campaign)}

              {campaign?.call_uuid && (
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popover(campaign?.call_uuid)}
                >
                  <span className="d-inline-block">
                    <span
                      className={`fas fa-info `}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                    </span>
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </td>
          <td>{campaign.newCost || "--"}</td>
          <td>
            <button
              className="btn btn-info"
              onClick={() => openModal(campaign)}
            >
              View Response
            </button>
          </td>
          <td className="tableRightCol">
            <ReactAudioPlayer
              src={campaign.callRecordingUrl}
              controls
              style={{
                minWidth: "40px",
                height: "30px",
              }}
            />
          </td>
        </tr>
      );
    });

  const onFilter = async (e) => {
    setState((prev) => ({
      ...prev,
      mainSpin: true,
    }));
    if (e === "clearFilter") {
      await dispatch(campaignAction.getCampaignDetailsList(id, ""));
      setFilterName("");
      setState((prev) => ({
        ...prev,
        showCreateButton: false,
      }));
    } else {
      setFilterName(e);
      let obj = {
        "Call Disconnected": "callDisconnected",
        "Call Failed": "callFailed",
      };
      await dispatch(campaignAction.getCampaignDetailsList(id, obj[e]));
      setState((prev) => ({
        ...prev,
        showCreateButton: true,
        mainSpin: false,
      }));
    }
  };

  const trackResult = async (clear) => {
    setState((prev) => ({
      ...prev,
      mainSpin: true,
    }));
    setPopup1(false);

    if (clear) {
      setShow(false);
      setFilterData([]);
      setPopup1(false);
      setState((prev) => ({
        ...prev,
        showCreateButton: false,
      }));
      await dispatch(campaignAction.getCampaignDetailsList(id, ""));
    } else {
      if (filterdata.length <= 0) {
        Swal.fire({
          title: "Warning!",
          text: `Please select at least one field`,
          icon: "warning",
          confirmButtonText: "Okay",
        });
        setState((prev) => ({
          ...prev,
          mainSpin: false,
        }));
        return;
      }
      await dispatch(campaignAction.getCampaignDetailsList(id, filterdata));
      setState((prev) => ({
        ...prev,
        showCreateButton: true,
      }));
      setPopup1(false);
    }
    setState((prev) => ({
      ...prev,
      mainSpin: false,
    }));
  };

  const ExportCSV = async () => {
    setState((prev) => ({
      ...prev,
      exportSpin: true,
    }));
    let send;
    if (filterdata.length > 0) {
      send = filterdata;
    }
    if (filterName !== "") {
      send = filterName;
    }

    let res: any = await dispatch(campaignAction.exportExcelFile(id, send));
    if (res) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(res);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Vodex Campaign Report ${name}` + fileExtension);
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };
  const fetchData1 = () => {
    if (withoutPaginationData?.length > 0) {
      let newSlice = withoutPaginationData.slice(lastKey, lastKey + 100);
      setState((prev) => ({
        ...prev,
        lastKey: lastKey + 100,
        campaignRunListData: [...prev.campaignRunListData, ...newSlice],
      }));
    }
  };

  const createCampaign = async (w) => {
    setButtonSpin(false);
    w.preventDefault();
    let numbersArray: any = [];
    newCampaignData.forEach((num: any) => {
      let obj = {
        firstName: "",
        lastName: "",
        phone: num.phone,
      };
      numbersArray.push(obj);
    });
    let obj = {
      audienceListId: data.audienceListName,
      campaignName: campaignName,
      audienceName: audienceName,
      numbers: numbersArray,
      botPlatform: "dialogflow",
      project: "default",
    };

    await dispatch(campaignAction.saveFilterdCampaign(obj));
    dispatch(fetchAudience());
    dispatch(
      addNotification(
        "Campaign Created",
        `Campaign ${campaignName} created by you
         Audience List ${audienceName} created by you
          `
      )
    );
    setPopup(false);
    setOpen(false);
    swalSuccessMessage(`
    Campaign ${campaignName} created &
    Audience List ${audienceName} created`);
    setButtonSpin(true);
    history.push("/campaigns");
  };

  const refreshPage = async () => {
    setState((prev) => ({
      ...prev,
      mainSpin: true,
    }));
    setCampaignRunDetailsData((prev) => ({
      ...prev,
      type: "",
    }));
    await dispatch(campaignAction.getCampaignDetailsList(id, ""));
    await dispatch(campaignAction.getCampaignRunDetailsPageWidgetsCount(id));
    setState((prev) => ({
      ...prev,
      mainSpin: false,
    }));
  };

  const onFilterWithDuration = async (start, end) => {
    setState((prev) => ({
      ...prev,
      mainSpin: true,
    }));

    setFilterName(`${start}-${end} sec`);
    await dispatch(
      campaignAction.getCampaignDetailsList(id, `duration||${start}||${end}`)
    );
    setState((prev) => ({
      ...prev,
      showCreateButton: true,
      mainSpin: false,
    }));
  };
  const filterWithDurationHtml = () => {
    return (
      <Dropdown className="m-1" style={{ float: "right" }}>
        <Dropdown.Toggle
          variant="success"
          disabled={campaignRunDetailsData?.type === "main" ? true : false}
        >
          Filter Calls with duration <i className="fas fa-filter"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onSelect={(e) => onFilterWithDuration(0, 15)}>
            0-15 sec
          </Dropdown.Item>
          <Dropdown.Item onSelect={(e) => onFilterWithDuration(15, 30)}>
            15-30 sec
          </Dropdown.Item>
          <Dropdown.Item onSelect={(e) => onFilterWithDuration(30, 45)}>
            30-45 sec
          </Dropdown.Item>
          <Dropdown.Item onSelect={(e) => onFilterWithDuration(45, 600)}>
            45+ sec
          </Dropdown.Item>
          <Dropdown.Item onSelect={(e) => onFilter("clearFilter")}>
            Clear Filter
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const filterWithStatusHtml = () => {
    return (
      <Dropdown className="m-1" style={{ float: "right" }}>
        <Dropdown.Toggle
          variant="success"
          disabled={campaignRunDetailsData?.type === "main" ? true : false}
        >
          Filter Calls <i className="fas fa-filter"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onSelect={(e) => onFilter("Call Disconnected")}>
            Call Ended
          </Dropdown.Item>
          <Dropdown.Item onSelect={(e) => onFilter("Call Failed")}>
            Call Failed
          </Dropdown.Item>
          <Dropdown.Item onSelect={(e) => onFilter("clearFilter")}>
            Clear Filter
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const topCards = () => {
    return (
      <div className="row">
        <TopCard
          title="Total Calls Failed"
          text={`${totalCampaignRunDetailsPageCallFailed}`}
          icon="warehouse"
          class="danger"
        />
        <TopCard
          title="Total Calls Connected"
          text={`${totalCampaignRunDetailsPageCallConnected}`}
          icon="box"
          class="primary"
        />
        <TopCard
          title="Total Calls Made"
          text={`${
            campaignRunDetailsData?.type === "main"
              ? campaignRunData?.length
              : totalCampaignRunDetailsPageCallMade || 0
          }`}
          icon="phone-volume"
          class="success"
        />
      </div>
    );
  };
  return (
    <div>
      {topCards()}
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4" style={{ minHeight: "600px" }}>
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Campaign List</h6>
              <div className="header-buttons">
                <button
                  className="btn btn-info mr-1"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      insightsPopupView: true,
                    }))
                  }
                >
                  View Insights
                </button>
                <button
                  className="btn btn-info  mr-1"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      insightsPopup: true,
                    }))
                  }
                >
                  Create Insights
                </button>
                <button
                  disabled={
                    withoutPaginationData?.length > 0 ? exportSpin : true
                  }
                  className="btn btn-info"
                  onClick={ExportCSV}
                  style={{ float: "right" }}
                >
                  {exportSpin ? "Exporting" : "Export"}
                </button>
                <button
                  title="Refresh page to enable filter options once campaigns run is complete"
                  className="btn btn-success mr-1"
                  onClick={refreshPage}
                >
                  <i className="fas fa fa-refresh"></i> Refresh
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    history.push(`/campaign-runs?${data?.id}`);
                  }}
                >
                  <i className="fas fa fa-chevron-left"></i> Back
                </button>
                &nbsp;
              </div>
            </div>
            <div className="card-body">
              {showCreateButton && (
                <button
                  disabled={campaignRunListData.length === 0}
                  className="btn btn-info m-1"
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      newCampaignData: [...withoutPaginationData],
                    }));
                    setOpen(true);
                  }}
                  style={{ float: "left" }}
                >
                  Create New Campaign
                </button>
              )}
              <button
                disabled={
                  campaignRunDetailsData?.type === "main" ? true : false
                }
                className="btn btn-info m-1"
                onClick={() => setPopup1(true)}
                style={{ float: "right" }}
              >
                Filter Outcome
              </button>
              {filterWithDurationHtml()}
              {filterWithStatusHtml()}

              <div
                className="table-responsive portlet"
                style={{ minHeight: "100vh" }}
              >
                <div style={{ height: "100vh", overflow: "auto" }}>
                  <InfiniteScroll
                    pageStart={0}
                    initialLoad={false}
                    loadMore={fetchData1}
                    hasMore={hasMore}
                    isReverse={false}
                    loader={
                      loading && (
                        <div className="loader" key={0}>
                          Loading ...
                        </div>
                      )
                    }
                    useWindow={false}
                  >
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col" style={{ width: "200px" }}>
                            {campaignRunDetailsData.type === "main"
                              ? "Calling Status: Live"
                              : "Calling Status"}
                          </th>
                          <th scope="col">Outcomes</th>
                          <th scope="col">Call Cost</th>
                          {campaignRunDetailsData.type !== "main" && (
                            <th scope="col">View Response</th>
                          )}
                          <th className="tableRightCol">
                            Listen Call Recording
                          </th>
                        </tr>
                      </thead>

                      {campaignRunDetailsData?.type === "main" ? (
                        <tbody>{spin && productElements1}</tbody>
                      ) : (
                        <tbody>
                          {mainSpin ? (
                            <tr>
                              <td colSpan={5}>
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "18px",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  Loading Calls ...
                                </div>
                              </td>
                            </tr>
                          ) : (
                            productElementsAlreadyRun
                          )}
                        </tbody>
                      )}
                    </table>
                    {campaignRunDetailsData.type === "main" &&
                      campaignRunData.length === 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bolder",
                          }}
                        >
                          Initiating Calls ...
                        </div>
                      )}

                    {!campaignRunDetailsData.type &&
                      !mainSpin &&
                      campaignRunListData?.length === 0 && (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bolder",
                          }}
                        >
                          No Data Found
                        </div>
                      )}
                  </InfiniteScroll>
                </div>

                <Drawer
                  anchor={"right"}
                  open={open}
                  onClose={() => setOpen(false)}
                >
                  <div className="rightDrawer">
                    <p className="changePlan">{filterName}</p>
                    <hr />

                    <table
                      className="table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Calling Status</th>
                          <th scope="col">Outcomes</th>
                          <th scope="col">Call Cost</th>
                          <th scope="col">Listen Call Recording</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaignRunListData?.map((campaign: any, idx) => {
                          const { callStatus } = campaign;
                          console.log(callStatus);

                          if (!campaign) {
                            return null;
                          }
                          return (
                            <tr key={idx}>
                              <th scope="row">
                                <input
                                  className="mr-1"
                                  defaultChecked={true}
                                  type="checkbox"
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      let c = newCampaignData?.findIndex(
                                        (o) => o.id === campaign.id
                                      );

                                      newCampaignData.splice(c, 1);
                                    } else {
                                      newCampaignData.push(campaign);
                                    }

                                    if (newCampaignData?.length > 0) {
                                      setSpin1(true);
                                    } else {
                                      setSpin1(false);
                                    }
                                  }}
                                />
                                {idx + 1}
                              </th>
                              <td>{campaign.phone}</td>
                              <td
                                style={{ width: "150px" }}
                                className={`${
                                  (callStatus === "Call Connected" &&
                                    "connected") ||
                                  (callStatus === "Calling..." && "calling") ||
                                  (callStatus === "Call Ended" &&
                                    "disconnected") ||
                                  (callStatus === "Call Failed" && "failed") ||
                                  (callStatus === "Invalid Number" && "failed")
                                }`}
                              >
                                {campaign.callStatus === "Call Disconnected"
                                  ? "Call Ended"
                                  : campaign.callStatus}
                              </td>
                              <td> {getOutcome(campaign)}</td>
                              <td> {campaign.newCost || "--"}</td>
                              <td>
                                <ReactAudioPlayer
                                  src={campaign.callRecordingUrl}
                                  controls
                                  style={{
                                    minWidth: "40px",
                                    height: "30px",
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <button
                      className="btn btn-primary m-2"
                      onClick={() => {
                        if (newCampaignData?.length > 0) {
                          setPopup(true);
                        }
                      }}
                    >
                      {newCampaignData?.length
                        ? "Create a New campaign"
                        : "Please Select Contacts"}
                    </button>

                    <Popup
                      contentStyle={{ height: "300px", minWidth: "400px" }}
                      className="popup-modal"
                      open={popup}
                      onClose={() => setPopup(false)}
                      closeOnDocumentClick={false}
                      closeOnEscape={false}
                    >
                      <div className="popup-modal">
                        <div className="popup-title">Campaign Details</div>
                        <div className="popup-content">
                          <form onSubmit={createCampaign}>
                            <label style={{ float: "left" }}>
                              Campaign Name
                            </label>
                            <input
                              required
                              className="form-control"
                              onChange={(e) => setCampaignName(e.target.value)}
                            />
                            <label style={{ float: "left", margin: "1%" }}>
                              Audience Name
                            </label>

                            <input
                              required
                              className="form-control"
                              onChange={(e) => setAudienceName(e.target.value)}
                            />
                            <br />
                            <button
                              className="btn btn-success"
                              type="submit"
                              disabled={
                                !buttonSpin || campaignName === nameOnly
                              }
                            >
                              {buttonSpin ? "Save Campaign" : "Saving"}
                            </button>
                          </form>
                        </div>
                      </div>
                    </Popup>
                  </div>
                </Drawer>

                <Modal
                  keyboard={false}
                  show={popup1}
                  onHide={() => {
                    setPopup1(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Filter Outcome</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <table className="">
                      <tbody>
                        {trackO.map((track, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                {" "}
                                <input
                                  className="mr-1"
                                  defaultChecked={
                                    filterdata?.find((o) => o === track.tag)
                                      ? true
                                      : false
                                  }
                                  type="checkbox"
                                  onChange={(e) => {
                                    let savedVal = track.tag;
                                    console.log(e.target.checked);
                                    if (!e.target.checked) {
                                      let cloneArray = filterdata;
                                      let existFilterIndex = cloneArray.find(
                                        (fil) => fil === track.tag
                                      );
                                      if (existFilterIndex) {
                                        let kl = cloneArray.splice(
                                          existFilterIndex,
                                          1
                                        );
                                      }
                                      setFilterData(cloneArray);
                                    } else {
                                      setFilterData([...filterdata, savedVal]);
                                    }
                                  }}
                                />
                              </td>
                              <td>{track.name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-danger"
                      onClick={() => trackResult(true)}
                    >
                      {" "}
                      Clear filters
                    </button>
                    <button
                      className="btn btn-success"
                      onClick={() => trackResult(false)}
                      disabled={filterdata?.length === 0 ? true : false}
                    >
                      {" "}
                      Apply filters
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>

      {summaryModal && (
        <SummaryModel
          state={state}
          setState={setState}
          show={summaryModal}
          toggle={toggle}
          track={trackO}
        />
      )}
      {insightsPopup && (
        <InsightsModal
          insightsPopup={insightsPopup}
          toggle={() => setState((prev) => ({ ...prev, insightsPopup: false }))}
          campaignRunListData={campaignRunListData}
          campaignName={nameOnly}
        />
      )}
      {insightsPopupView && (
        <InsightsViewModal
          insightsPopup={insightsPopupView}
          toggle={() =>
            setState((prev) => ({ ...prev, insightsPopupView: false }))
          }
          campaignRunListData={campaignRunListData}
          campaignName={nameOnly}
        />
      )}
    </div>
  );
}

export default ProductList;
