import { IProduct, ProductModificationStatus } from "../models/product.interface";
export const ADD_TEMPLATE: string = "ADD_TEMPLATE";
export const EDIT_TEMPLATE: string = "EDIT_TEMPLATE";
export const EDIT_TAG: string = "EDIT_TAG";
export const REMOVE_TEMPLATE: string = "REMOVE_TEMPLATE";
export const CHANGE_TEMPLATE_AMOUNT: string = "CHANGE_TEMPLATE_AMOUNT";
export const CHANGE_TEMPLATE_PENDING_EDIT: string = "CHANGE_TEMPLATE_PENDING_EDIT";
export const CLEAR_TEMPLATE_PENDING_EDIT: string = "CLEAR_TEMPLATE_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";

export function addTemplate(template: IProduct): IAddProductActionType {
    return { type: ADD_TEMPLATE, template: template };
}

export function editTemplate(template: IProduct): IEditProductActionType {
    return { type: EDIT_TEMPLATE, template: template };
}


export function removeTemplate(template: any): IRemoveProductActionType {
    return { type: REMOVE_TEMPLATE, template: template };
}



export function changeSelectedProduct(template: IProduct): IChangeSelectedProductActionType {

    return { type: CHANGE_TEMPLATE_PENDING_EDIT, template: template };
}

export function clearSelectedProduct(): IClearSelectedProductActionType {
    return { type: CLEAR_TEMPLATE_PENDING_EDIT };
}

export function setModificationState(value: ProductModificationStatus): ISetModificationStateActionType {
    return { type: SET_MODIFICATION_STATE, value: value };
}

interface IAddProductActionType { type: string, template: IProduct };
interface IEditProductActionType { type: string, template: IProduct };
interface IRemoveProductActionType { type: string, template: IProduct };
interface IChangeSelectedProductActionType { type: string, template: IProduct };
interface IClearSelectedProductActionType { type: string };
interface ISetModificationStateActionType { type: string, value: ProductModificationStatus };
interface IChangeProductAmountType { type: string, id: number, amount: number };