import React, { Fragment, useEffect, useState } from "react";
import LeftMenu from "../LeftMenu/LeftMenu";
import TopMenu from "../TopMenu/TopMenu";
import { Switch, Route } from "react-router";
import Users from "../Users/Users";
import Products from "../Products/Products";
import Orders from "../Orders/Orders";
import Campaigns from "../Campaign/Campaign";
import CampaignDetail from "../CampaignDetail/CampaignDetails";
import CampaignRuns from "../CampaignRuns/CampaignRuns";
import FileUpload from "../Integration/UploadJSON/FileUpload";
import Audience from "../Audience/Audience";
import Home from "../Home/Home";
import Notifications from "../../common/components/Notification";
import CallSettings from "../CallSettings/CallerId";
import AdvanceSetting from "../AdvanceSettings/AdvanceSetting";
import Skeleton from "react-loading-skeleton";
import ComingSoon from "../ComingSoon";
// import Prices from "../PaymentSettings/NewPayment/Prices";
// import Account from "../PaymentSettings/NewPayment/Account";
import TopupAccount from "../PaymentSettings/TopupAccount";
import "react-loading-skeleton/dist/skeleton.css";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import Cancel from "../PaymentSettings/NewPayment/Cancel";
import ChatApi from "../Whatsapp/Whatsapp";
import AddBalance from "../AddBalance/AddBalance";
import Integration from "../Integration/Integration";
import { useHistory } from "react-router-dom";
import Template from "../TemplateSection/Template";
import TemplateDetails from "../TemplateSection/TemplateDetails";
// import TemplateForm from "../AdminComponents/Templates/TemplateForm";
import Templates from "../AdminComponents/Templates/Template";
import WhatsappSetting from "../AdminComponents/Whatsapp/WhatsappSetting";
import PricingStructure from "../AdminComponents/PricingModel/Pricing";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyData } from "../../store/actions/account.actions";
import Coupon from "../AdminComponents/Coupon/Coupon";
import {
  getAccountBalance,
  getUserData,
} from "../../Constant/CommonService/Common.service";
import { addNotification } from "../../store/actions/notifications.action";
import ScheduleCampaign from "../ScheduleCampaign/ScheduleCampaign";
import InvoicesList from "../PaymentSettings/Invoices";
import Prompt from "../Prompt/Prompt";

const Admin: React.FC = () => {
  const [spin, setSpin] = useState(true);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || "");
  const history = useHistory();
  const dispatch = useDispatch();
  const accountEmail = localStorage.getItem("user_email");

  setTimeout(() => {
    setSpin(true);
  }, 1000);

  useEffect(() => {
    const inti = async () => {
      dispatch(getCurrencyData);
      let res: any = await dispatch(getUserData(accountEmail));
      dispatch(addNotification("Welcome", `Hello ${res?.data?.name}`));
    };

    inti();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Fragment>
        <Notifications />
        <LeftMenu />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopMenu />
            <div className="container-fluid">
              {spin ? (
                <Switch>
                  <Route path={`/prompt`}>
                    <Prompt />
                  </Route>
                  <Route path={`/invoices`}>
                    <InvoicesList />
                  </Route>

                  <Route path={`/coupon`}>
                    <Coupon />
                  </Route>
                  <Route path={`/pricing`}>
                    <PricingStructure />
                  </Route>
                  <Route path={`/whatsapp-settings`}>
                    <WhatsappSetting />
                  </Route>
                  <Route path={`/upload-templates`}>
                    <Templates />
                  </Route>
                  <Route path={`/addbalance`}>
                    <AddBalance />
                  </Route>
                  <Route path={`/whatsapp`}>
                    <ChatApi />
                  </Route>
                  <Route path="/topupaccount">
                    <TopupAccount />
                  </Route>
                  {/* <Route path="/cancel">
                    <Cancel />
                  </Route>
                  <Route path="/prices">
                    <Prices />
                  </Route>
                  <Route path="/account">
                    <Account />
                  </Route> */}

                  <Route path={`/comingsoon`}>
                    <ComingSoon />
                  </Route>
                  <Route path={`/users`}>
                    <Users />
                  </Route>
                  <Route path={`/recordings`}>
                    <Products />
                  </Route>

                  <Route path={`/templatedetails`}>
                    <TemplateDetails />
                  </Route>
                  <Route path={`/templates`}>
                    <Template />
                  </Route>
                  <Route path={`/integration1`}>
                    <FileUpload />
                  </Route>
                  <Route path={`/integration`}>
                    <Integration />
                  </Route>
                  <Route path={`/audience`}>
                    <Audience />
                  </Route>
                  <Route path={`/orders`}>
                    <Orders />
                  </Route>
                  <Route path={`/campaigns`}>
                    <Campaigns />
                  </Route>
                  <Route path={`/campaign-run-details`}>
                    <CampaignDetail />
                  </Route>
                  <Route path={`/campaign-runs`}>
                    <CampaignRuns />
                  </Route>
                  <Route path={`/callsettings`}>
                    <CallSettings />
                  </Route>
                  <Route path={`/advancesetting`}>
                    <AdvanceSetting />
                  </Route>
                  <Route path={`/schedule-campaign`}>
                    <ScheduleCampaign />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              ) : (
                <Skeleton count={10} height={35} style={{ margin: "2%" }} />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </Elements>
  );
};

export default Admin;
