import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Alert, Form, Modal } from "react-bootstrap";
import { db } from "../../firebase/index";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  IRootPageStateType,
  IStateType,
} from "../../store/models/root.interface";
import { updateCurrentPath } from "../../store/actions/root.actions";
import moment from "moment";
import "./CallSettings.css";
import {
  getDisconnectedTime,
  saveDisconnectedTime,
} from "../../Constant/CommonService/Common.service";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../Constant/swalMessage";
const Swal = require("sweetalert2");

function CallSettings() {
  const [value, setValue] = useState({ no: "", id: "" });
  const [transeferNo, setTranferNo] = useState({ no: "", id: "" });
  const [show, setShow] = useState(false);
  const [verification, setVerification] = useState("");
  const [editCallerId, setEditCallerId] = useState(true);
  const [editNo, setEditNo] = useState(true);
  const [callButton, setCallButton] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const [resetButton1, setResetButton1] = useState(false);
  const [expiryDate, setExpiryDate] = useState(6);
  const [state, setState] = useState({
    disconnectTime: 0,
    timeDisable: false,
    id: null,
    asrArray: [],
    gateWayArray: [],
    asrEngine: "",
    gateway: "",
    gatewaySpin: false,
    engineSpin: false,
    responseFormatArray: [],
    voicesArray: [],
    responseFormat: "",
    voice: "",
    responseSpin: false,
    customGateway: [],
    interruptVal: false,
  });

  const [callerIdExist, setCallerIdExist] = useState(true);
  const {
    disconnectTime,
    timeDisable,
    id,
    asrArray,
    gateWayArray,
    asrEngine,
    gateway,
    gatewaySpin,
    engineSpin,
    responseFormatArray,
    voicesArray,
    responseFormat,
    voice,
    responseSpin,
    customGateway,
    interruptVal,
  } = state;
  let localUserEmail = localStorage.getItem("user_email");

  const url = process.env.REACT_APP_API_BASE_URL || "localhost:3000";

  const dispatch = useDispatch();

  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );
  const [buttonShow1, setButtonShow1] = useState(false);
  const [languageCode, setLanguageCode] = useState("");
  const [languageCode1, setLanguageCode1] = useState("");
  const [show1, setShow1] = useState(false);
  const [lanId, setLanId] = useState("");
  useEffect(() => {
    dispatch(updateCurrentPath("call settings", ""));
  }, [path.area, dispatch]);

  const getDetails = async () => {
    let {
      disconnectTime,
      id,
      asrEngine,
      gateway,
      responseFormat,
      voice,
      interrupt,
    }: any = await dispatch(getDisconnectedTime({ email: localUserEmail }));
    setState((prev) => ({
      ...prev,
      disconnectTime: disconnectTime,
      id: id,
      asrEngine: asrEngine,
      gateway: gateway,
      responseFormat: responseFormat,
      voice: voice,
      interruptVal: interrupt || false,
    }));
  };

  useEffect(() => {
    if (customGateway.length > 0) {
      let find: any = customGateway.find(
        (cus: any) => cus.split("^")[0] === localUserEmail
      );
      console.log("findfind", find);
      if (find) {
        setState((prev: any) => ({
          ...prev,
          gateWayArray: [...prev.gateWayArray, find.split("^")[1]],
        }));
      }
    }
  }, [customGateway]);

  useEffect(() => {
    const init = async () => {
      db.collection("ASR")
        .get()
        .then((res) => {
          res.forEach((doc) => {
            if (doc.exists) {
              setState((prev) => ({
                ...prev,
                asrArray: doc.data().asrEngine,
                gateWayArray: doc.data().gateway,
                responseFormatArray: doc.data().responseFormat,
                voicesArray: doc.data().voices,
                customGateway: doc.data().customGateway,
              }));
            }
          });
        });
      db.collection("callTransfer")
        .where("userEmail", "==", localUserEmail)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            if (doc.exists) {
              setTranferNo({ id: doc.id, no: doc.data().transferNumber });
              setEditNo(false);
            } else setEditNo(true);
          });
        });

      db.collection("callerId")
        .where("userEmail", "==", localUserEmail)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            if (doc.exists) {
              if (!doc.data().callerIdNo) {
                setCallerIdExist(false);
              }
              getDays(doc.data().expiryDate);
            }
          });
        });

      await getDetails();
      db.collection("callerId")
        .where("userEmail", "==", localUserEmail)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            if (doc.exists) {
              setValue({ id: doc.id, no: doc.data().callerIdNo });

              setEditCallerId(false);
            } else setEditCallerId(true);
          });
        });

      db.collection("projects")
        .where("userEmail", "==", localStorage.getItem("user_email"))
        .get()
        .then((snap) => {
          if (snap.empty) {
            return false;
          } else {
            snap.forEach((doc) => {
              setLanguageCode(doc.data().langCode);
              setLanguageCode1(doc.data().langCode);
              setShow1(true);
              setLanId(doc.id);
              return true;
            });
          }
        });
    };
    init();
  }, []);

  const uploadCallTransfer = async () => {
    if (transeferNo.no) {
      if (transeferNo.no.length > 8) {
        if (transeferNo.id) {
          db.collection("callTransfer")
            .doc(transeferNo.id)
            .update({
              transferNumber: transeferNo.no,
            })
            .then((res) => {
              Swal.fire({
                title: "Successful!",
                text: "Number updated",
                icon: "success",
                confirmButtonText: "Okay",
              });
              setEditNo(false);
            });
        } else {
          db.collection("callTransfer")
            .add({
              transferNumber: transeferNo.no,
              userEmail: localStorage.getItem("user_email"),
            })
            .then((res) => {
              Swal.fire({
                title: "Success!",
                text: "Number uploaded",
                icon: "success",
                confirmButtonText: "Okay",
              });
              setTranferNo({ ...transeferNo, id: res.id });
              setEditNo(false);
            });
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: "Enter a valid number",
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: "Enter Number",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  const CallerSubmit = () => {
    if (value.no.length > 8) {
      setCallButton(true);
      Swal.fire({
        title: "Successful!",
        text: "Calling... for otp",
        icon: "success",
        confirmButtonText: "Okay",
      });
      axios.post(url.concat("voximplant"), { no: value.no }).then((res) => {
        setShow(true);
      });
    } else {
      Swal.fire({
        title: "error!",
        text: "invalid number",
        icon: "warning",
        confirmButtonText: "Okay",
      });
    }
  };

  const verificationSend = () => {
    let saveDate = moment().add(30, "days").format("YYYY-MM-DD");
    setCallButton(false);
    // timeoutk(false);
    axios
      .post(url.concat("verify"), {
        callerid_number: value.no,
        code: verification,
      })
      .then((res1) => {
        if (res1.data.status) {
          if (value.id) {
            db.collection("callerId")
              .doc(value.id)
              .update({
                callerIdNo: value.no,
                expiryDate: saveDate,
              })
              .then((res) => {
                Swal.fire({
                  title: "Successful!",
                  text: "CallerId updated",
                  icon: "success",
                  confirmButtonText: "Okay",
                });

                setEditCallerId(false);
                setCallButton(false);
                setShow(false);
              });
          } else {
            db.collection("callerId")
              .add({
                callerIdNo: value.no,
                userEmail: localStorage.getItem("user_email"),
                expiryDate: saveDate,
              })
              .then((res) => {
                Swal.fire({
                  title: "Successful!",
                  text: "callerId uploaded",
                  icon: "success",
                  confirmButtonText: "Okay",
                });
                setValue({ ...value, id: res.id });
                setEditCallerId(false);
                setCallButton(false);
                setShow(false);
              });
          }
        } else {
          Swal.fire({
            title: "error!",
            text: "invalid otp",
            icon: "failure",
            confirmButtonText: "Okay",
          });
          setCallButton(true);
        }
      });
  };
  const resend = () => {
    Swal.fire({
      title: "Successful!",
      text: "calling again",
      icon: "success",
      confirmButtonText: "Okay",
    });
    axios
      .post(url.concat("resend"), {
        calleridNumber: value.no,
      })
      .then((res) => {
        // timeoutk(false);
        // timeoutk(true);
      });
  };

  function deleteId() {
    setShow(false);
    setEditCallerId(true);
    setCallButton(false);

    axios
      .post(url.concat("delete"), {
        callerId: localStorage.getItem("callerId"),
      })
      .then((res) => {
        // timeoutk(false);
      });
  }

  const resetCallerId = () => {
    setResetButton(true);
    db.collection("callerId")
      .doc(value.id)
      .update({
        callerIdNo: "",
      })
      .then((res) => {
        setValue({ ...value, no: "" });

        Swal.fire({
          title: "Successful!",
          text: "CallerId updated",
          icon: "success",
          confirmButtonText: "Okay",
        });

        setResetButton(false);
      });
  };

  const resetCallerId1 = () => {
    setResetButton1(true);
    db.collection("callTransfer")
      .doc(transeferNo.id)
      .update({
        transferNumber: "",
      })
      .then((res) => {
        setTranferNo({ ...transeferNo, no: "" });

        Swal.fire({
          title: "Successful!",
          text: "Transfer No. updated",
          icon: "success",
          confirmButtonText: "Okay",
        });

        setResetButton1(false);
      });
  };

  const getDays = (date) => {
    if (moment().isBefore(date) || moment().isAfter(date)) {
      if (moment(date).diff(moment(), "days") < 5) {
        let exp = moment(date).diff(moment(), "days");
        setExpiryDate(exp);
      }
    }
  };

  const saveDisconnectTime = async (reset, dep, interVal) => {
    let obj = {
      id: id,
      email: localUserEmail,
      disconnectTime: reset ? 0 : disconnectTime,
      asrEngine: asrEngine,
      gateway: gateway,
      responseFormat: responseFormat,
      voice: responseFormat === "recording" ? null : voice,
      interrupt: interVal,
    };

    if (interVal) {
      setState((prev) => ({
        ...prev,
        interruptVal: interVal,
      }));
      await dispatch(saveDisconnectedTime(obj));
      getDetails();
      return;
    }

    if (dep) {
      if (state[dep]) {
        let obj1 = {
          gateway: "gatewaySpin",
          asrEngine: "engineSpin",
          responseFormat: "responseSpin",
          voice: "responseSpin",
          interVal: "responseSpin",
        };

        setState((prev) => ({
          ...prev,
          [obj1[dep]]: true,
        }));
        await dispatch(saveDisconnectedTime(obj));
        getDetails();

        setState((prev) => ({
          ...prev,
          [obj1[dep]]: false,
        }));
      } else {
        let obj1 = {
          gateway: "Gateway",
          asrEngine: "ASR Engine",
          responseFormat: "Response Format",
          voice: "Voice",
        };
        swalErrorMessage(`Please select ${obj1[dep]}`);
      }
    } else {
      if (disconnectTime > 0) {
        setState((prev) => ({
          ...prev,
          timeDisable: true,
        }));
        await dispatch(saveDisconnectedTime(obj));
        getDetails();

        setState((prev) => ({
          ...prev,
          timeDisable: false,
          disconnectTime: reset ? 0 : disconnectTime,
        }));
      }
    }
  };

  const uploadLanguage = (e) => {
    e.preventDefault();
    var regEx = /^[a-z][a-z\s]*$/;
    // if (languageCode.match(regEx) && languageCode.length < 6) {
    setButtonShow1(true);
    if (lanId) {
      db.collection("projects")
        .doc(lanId)
        .update({
          langCode: languageCode,
        })
        .then((k) => {
          swalSuccessMessage("Language Uploaded");
          setLanguageCode1(languageCode);
          setButtonShow1(false);
          setShow1(true);
        });
    } else {
      db.collection("projects")
        .where("userEmail", "==", localStorage.getItem("user_email"))
        .get()
        .then((snap) => {
          if (snap.empty) {
            db.collection("projects")
              .add({
                langCode: languageCode,
                userEmail: localStorage.getItem("user_email"),
              })
              .then((k) => {
                setLanguageCode1(languageCode);
                setButtonShow1(false);
                setShow1(true);
                swalSuccessMessage("Language Uploaded");
              });
          }
        });
    }
    // } else {
    // swalErrorMessage("Enter valid language code");
    // }
  };

  return (
    <div>
      <div className="card">
        <span className="card-header">Enter Caller Id</span>

        <div className="row card-body">
          <div className="col">
            <PhoneInput
              disabled={!editCallerId}
              containerStyle={{ marginLeft: "2%" }}
              inputStyle={{ width: "100%" }}
              country={"91"}
              placeholder="Enter phone number"
              value={value.no}
              onChange={(e) => setValue({ ...value, no: e })}
            />
          </div>
          <div className="col">
            <button
              disabled={callButton}
              className="btn btn-success ml-3 "
              onClick={
                editCallerId ? CallerSubmit : () => setEditCallerId(true)
              }
            >
              {editCallerId ? (callButton ? "Calling " : "Add") : "Edit"}
            </button>
            <button
              disabled={resetButton}
              className="btn btn-info ml-3 "
              onClick={resetCallerId}
            >
              {resetButton ? "Wait... " : "Reset"}
            </button>
          </div>

          <Modal
            animation={true}
            onHide={() => {
              setShow(false);

              deleteId();

              setCallButton(false);
            }}
            show={show}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Enter Verification Code
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label>verification code</label>
              <br />
              <input
                className="form-control"
                onChange={(e) => setVerification(e.target.value)}
              />
              <button className="btn btn-link" onClick={() => resend()}>
                resend OTP
              </button>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "2%",
                }}
              >
                <button
                  disabled={!callButton}
                  className="btn btn-primary"
                  onClick={() => verificationSend()}
                >
                  {callButton ? "Verify" : "Verifying"}
                </button>
                <button
                  className="btn btn-danger ml-1"
                  onClick={() => deleteId()}
                >
                  cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {callerIdExist && expiryDate < 5 && (
          <Alert variant="warning  m-3" style={{ maxHeight: "50px" }}>
            <p>
              Your caller id is{" "}
              {expiryDate <= 0 ? " expired" : ` expiring soon`} , Please click
              on edit to reverify
            </p>
          </Alert>
        )}
      </div>
      <br />

      <div className="card">
        <span className="card-header">
          Call Transfer Number
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id={"top"}>
                To Transfer call, please put "Call_Transfer" tag in the
                particular dialogflow intent.
              </Tooltip>
            }
          >
            <i
              style={{ fontSize: 15, marginLeft: "5px" }}
              className={`fas fa-info-circle `}
            ></i>
          </OverlayTrigger>
        </span>
        <div
          className="row card-body
"
        >
          <div className="col">
            <PhoneInput
              disabled={!editNo}
              containerStyle={{ marginLeft: "2%" }}
              inputStyle={{ width: "100%" }}
              country={"India"}
              placeholder="Enter phone number"
              value={transeferNo.no}
              onChange={(e) => setTranferNo({ ...transeferNo, no: e })}
            />
          </div>
          <div className="col">
            <button
              className="btn btn-success ml-3 "
              onClick={editNo ? uploadCallTransfer : () => setEditNo(true)}
            >
              {editNo ? "Save" : "Edit"}
            </button>
            <button
              disabled={resetButton1}
              className="btn btn-info ml-3 "
              onClick={resetCallerId1}
            >
              {resetButton1 ? "Wait... " : "Reset"}
            </button>
          </div>
          {/* <label>Enter Phone No</label>
        <input placeholder="enter mobile no. with country code"  /> */}
        </div>
      </div>

      <div className="card mt-4">
        <span className="card-header">
          Disconnect Call Automatically
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id={"top"}>
                This setting will disconnect all calls after X minutes for e.g.
                if you enter 2 below, all calls will be disconnected
                automatically after 2 minutes irrespective of whether the call
                is finished or not. Leave blank if you do not want to disconnect
                the call automatically
              </Tooltip>
            }
          >
            <i
              style={{ fontSize: 15, marginLeft: "5px" }}
              className={`fas fa-info-circle `}
            ></i>
          </OverlayTrigger>
        </span>
        <div className=" card-body">
          <div className=" d-flex">
            <span className="mr-2 mt-1">
              {" "}
              Disconnect the Call Automatically after
            </span>
            <input
              type="number"
              value={disconnectTime === 0 ? "" : disconnectTime}
              onChange={(e) => {
                if (Number(e.target.value) > 0) {
                  let no = Number(e.target.value);
                  setState((prev) => ({
                    ...prev,
                    disconnectTime: no,
                  }));
                }
              }}
              disabled={timeDisable}
              className="form-control minutes-input"
            />

            <span className="ml-2 mt-1"> minutes</span>
            <div className="ml-2">
              <button
                disabled={timeDisable}
                className="btn btn-success ml-3 "
                onClick={() => saveDisconnectTime(false, "", "")}
              >
                Save
              </button>
              <button
                disabled={timeDisable}
                className="btn btn-info ml-3 "
                onClick={() => saveDisconnectTime(true, "", "")}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="col"></div>
        </div>
      </div>

      <form className="card mt-4" onSubmit={uploadLanguage}>
        <span className="card-header">Language code</span>
        <div className=" card-body">
          <div className=" d-flex">
            <span className="mr-2 mt-1">Enter Language code</span>
            <input
              required
              type="text"
              name="language code"
              className="form-control language-input"
              onChange={(e) => {
                setLanguageCode(e.target.value);
              }}
              value={languageCode}
              disabled={show1}
            />

            <div className="ml-2">
              <button
                className="btn btn-success  ml-3 "
                type="submit"
                disabled={buttonShow1}
              >
                {buttonShow1 ? "Saving" : "Save"}
              </button>
              {lanId && (
                <button
                  className="btn btn-info  ml-3 "
                  onClick={(e) => {
                    e.preventDefault();
                    setShow1(false);
                  }}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          <div className="col"></div>
        </div>
      </form>

      <form className="card mt-4">
        <span className="card-header">ASR and Gateway settings</span>
        <div className=" card-body">
          <div className="row">
            <div className="col d-flex">
              <span className="mr-2 mt-1">Select ASR Engine</span>
              <select
                className="form-control language-input text-capitalize"
                onChange={(e) => {
                  e.persist();
                  setState((prev) => ({
                    ...prev,
                    asrEngine: e.target.value,
                  }));
                }}
                value={asrEngine}
              >
                <option value={""}>Select</option>
                {asrArray?.map((asr, index) => (
                  <option value={asr} className="text-capitalize">
                    {asr}
                  </option>
                ))}
              </select>

              <div className="ml-2">
                <button
                  type="button"
                  disabled={engineSpin}
                  className="btn btn-success ml-3 "
                  onClick={() => saveDisconnectTime(false, "asrEngine", "")}
                >
                  {engineSpin ? "Saving" : "Save"}
                </button>
              </div>
            </div>
            <div className="col d-flex">
              <span className="mr-2 mt-1">Select Gateway</span>
              <select
                className="form-control language-input text-capitalize"
                onChange={(e) => {
                  e.persist();
                  setState((prev) => ({
                    ...prev,
                    gateway: e.target.value,
                  }));
                }}
                value={gateway}
              >
                <option value={""}>Select</option>
                {gateWayArray?.map((gateway, index) => (
                  <option value={gateway} className="text-capitalize">
                    {gateway}
                  </option>
                ))}
              </select>
              <div className="ml-2">
                <button
                  type="button"
                  disabled={gatewaySpin}
                  className="btn btn-success ml-3 "
                  onClick={() => saveDisconnectTime(false, "gateway", "")}
                >
                  {gatewaySpin ? "Saving" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form className="card mt-4">
        <span className="card-header">TTS/Recording</span>
        <div className=" card-body">
          <div className="row">
            <div className="col d-flex">
              <span className="mr-2 mt-1">Select Response Format</span>
              <select
                className="form-control language-input text-capitalize"
                onChange={(e) => {
                  e.persist();

                  if (e.target.value === "recording") {
                    setState((prev) => ({
                      ...prev,
                      responseFormat: e.target.value,
                      voice: "",
                    }));
                  } else {
                    setState((prev) => ({
                      ...prev,
                      responseFormat: e.target.value,
                    }));
                  }
                }}
                value={responseFormat}
              >
                <option value={""}>Select</option>
                {responseFormatArray?.map((response, index) => (
                  <option value={response} className="text-capitalize">
                    {response}
                  </option>
                ))}
              </select>

              {responseFormat === "recording" && (
                <div className="ml-2">
                  <button
                    type="button"
                    disabled={responseSpin}
                    className="btn btn-success ml-3 "
                    onClick={() =>
                      saveDisconnectTime(false, "responseFormat", "")
                    }
                  >
                    {responseSpin ? "Saving" : "Save"}
                  </button>
                </div>
              )}
            </div>
            {responseFormat === "TTS" && (
              <div className="col d-flex">
                <span className="mr-2 mt-1">Select Voice</span>
                <select
                  className="form-control language-input text-capitalize"
                  onChange={(e) => {
                    e.persist();
                    setState((prev) => ({
                      ...prev,
                      voice: e.target.value,
                    }));
                  }}
                  value={voice}
                >
                  <option value={""}>Select</option>
                  {voicesArray?.map((voice: any, index) => (
                    <option
                      value={voice}
                      className="text-capitalize"
                      key={index}
                    >
                      {voice.split("^")[0]}
                    </option>
                  ))}
                </select>
                <div className="ml-2">
                  <button
                    type="button"
                    disabled={responseSpin}
                    className="btn btn-success ml-3 "
                    onClick={() => saveDisconnectTime(false, "voice", "")}
                  >
                    {responseSpin ? "Saving" : "Save"}
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="row mt-3">
            <div className="col d-flex">
              <span className="mr-2 ">Interrupt :</span>
              <Form.Check
                type={"radio"}
                label={`True`}
                id={`disabled-default`}
                className="ml-2"
                checked={interruptVal}
                onChange={() => {
                  saveDisconnectTime(false, "interruptVal", true);
                }}
              />

              <Form.Check
                type={"radio"}
                label={`False`}
                id={`disabled-default`}
                className="ml-2"
                checked={!interruptVal}
                onChange={() => {
                  saveDisconnectTime(false, "interruptVal", false);
                }}
              />
            </div>
          </div>
        </div>
      </form>
      <div style={{ height: "100px" }}></div>
    </div>
  );
}

export default CallSettings;
