import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { swalWarningMessage } from "../../Constant/swalMessage";
import {
  editAudienceList,
  getAudienceList,
} from "../../store/Store/Audience/audience.action";

function AudienceExpandView({ list, onBack }) {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const deleteData = (checked, phone) => {
    let dataCopy = data;
    if (checked) {
      dataCopy.push(phone);
      setData(dataCopy);
    } else {
      let ck = dataCopy.findIndex((ph) => ph === phone);
      dataCopy.splice(ck, 1);
      setData(dataCopy);
    }
  };
  const saveDeletedData = async () => {
    if (data.length > 0) {
      setButtonDisabled(true);
      let newData: any = [];
      list?.audienceList?.forEach((aud) => {
        if (!data.includes(aud.phone)) {
          newData.push(aud);
        }
      });
      let obj = {
        id: list.id,
        audienceInformation: {
          audienceListName: list?.audienceListName,
          audienceList: newData,
        },
      };
      await dispatch(editAudienceList(obj));
      await dispatch(getAudienceList());
      setButtonDisabled(true);
      onBack(true);
    } else {
      swalWarningMessage("Please select numbers");
    }
  };
  return (
    <div>
      <button
        className="btn btn-danger m-1"
        disabled={buttonDisabled}
        onClick={() => saveDeletedData()}
      >
        Delete Selected Contacts
      </button>
      <div className="table-responsive portlet">
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col"> First Name</th>
              <th scope="col">Last name</th>
              <th scope="col">phone</th>
              {/* <th scope="col">Delete</th> */}
            </tr>
          </thead>
          <tbody>
            {list?.audienceList?.map((data, idx) => {
              return (
                <tr key={idx}>
                  <th scope="col">
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={(e) => deleteData(e.target.checked, data.phone)}
                    />
                    {idx + 1}
                  </th>
                  <th scope="col">{data.firstName}</th>
                  <th scope="col">{data.lastName}</th>
                  <th scope="col">{data.phone}</th>
                  {/* <th scope="col">
                    <button
                      className="btn btn-danger"
                      //   onClick={() => DeleteAudienceNo(data)}
                    >
                      Delete
                    </button>
                  </th> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AudienceExpandView;
