import { API, axiosClient, BASE_URL_API } from "../apiConstant";
import { swalErrorMessage, swalSuccessMessage } from "../swalMessage";

export const savePayment = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.PAYMENT.SAVE_PAYMENT}`,
      { ...data }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getInvoices = async () => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API}${API.PAYMENT.INVOICE}`);
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const createOrder = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.PAYMENT.ORDER}`,
      data
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyOrder = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.PAYMENT.VERIFY}`,
      data
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const downloadPdf = async (data) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API}${API.PAYMENT.INVOICE}/${data}`,
      {
        responseType: "blob",
      }
    );

    console.log("dffffffffff", res);
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const stripePayment = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.PAYMENT.SAVE_PAYMENT}`,
      { ...data }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateAccount = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.PAYMENT.UPDATE_PAYMENT}`,
      { ...data }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
