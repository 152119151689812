import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { updateCurrentPath } from '../../store/actions/root.actions';
import { IRootPageStateType, IStateType } from '../../store/models/root.interface';
import FileUpload from './UploadJSON/FileUpload';
import UploadRasa from './UploadRasa'


function Integration() {
    const dispatch = useDispatch();


    let [leftMenuVisibility, setLeftMenuVisibility] = useState(false);
    let [value, setValue] = useState('')

    dispatch(updateCurrentPath("integration", `${value === 'Dialogflow' ? 'dialogflow' : '' || value === 'Rasa' ? 'rasa' : ''}`));
    function changeLeftMenuVisibility() {
        setLeftMenuVisibility(!leftMenuVisibility);
    }

    function getCollapseClass() {
        return leftMenuVisibility ? "" : "collapsed";
    }
    return <div className='row'>
        {/* <div className='col' style={{ maxWidth: "250px" }}>
            <Fragment>


                <ul
                    style={{ boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", cursor: "pointer" }}
                    className={`navbar-nav  sidebar sidebar-light accordion ${getCollapseClass()}`}
                    id="collapseMenu"
                >


                    <span className="m-3" style={{ color: "#808080", fontSize: 20 }}>Integration</span>

                    <li className={`nav-item ${value === 'Dialogflow' && 'active'}`}>
                        <span className="nav-link" onClick={() => setValue('Dialogflow')} >
                            <i className="fas fa-fw fa-tasks"></i>
                            <span >Dialogflow</span>
                        </span>
                    </li>

                    <li className={`nav-item ${value === 'Rasa' && 'active'}`}>
                        <span className="nav-link" onClick={() => setValue('Rasa')}>
                            <i className="fas fa-fw fa-tasks"></i>
                            <span >Rasa</span>
                        </span>
                    </li>




                    <hr className="sidebar-divider d-none d-md-block" />
                </ul>
            </Fragment>


        </div> */}
        <div className='col'>
            <FileUpload />

            {/* <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    {
                        value === 'Dialogflow' && <FileUpload /> || value === 'Rasa' && <UploadRasa />

                    }
                </div>
            </div> */}
        </div>

    </div >;
}

export default Integration;
