import { IActionBase } from "../models/root.interface";
import { IAccount } from "../models/account.interface";
import { LOG_IN, LOG_OUT, GET_CURRENCY_DATA } from "../actions/account.actions";
import { db } from "../../firebase";

const initialState: IAccount = {
  email: localStorage.getItem("user_email"),
  currencyData: {},
};

function accountReducer(
  state: IAccount = initialState,
  action: IActionBase
): IAccount {
  switch (action.type) {
    case LOG_IN: {
      window.location.reload();
      return { ...state, email: action.email };
    }
    case LOG_OUT: {
      localStorage.clear();
      window.location.reload();
      return { ...state, email: "" };
    }
    case GET_CURRENCY_DATA: {
      return { ...state, currencyData: action.payload };
    }
    default:
      return state;
  }
}

export default accountReducer;
