import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import { IStateType } from "../../../store/models/root.interface";
import { IAccount } from "../../../store/models/account.interface";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { updateCurrentPath } from "../../../store/actions/root.actions";
import "./Pricing.css";
import { Modal } from "react-bootstrap";

function PricingStructure() {
  const [data, setData] = useState<any>([]);
  const [popup, setPopup] = useState(false);
  const [popup1, setPopup1] = useState(true);
  const [value, setValue] = useState("");
  const [password, setPassword] = useState("");
  const [tableSpin, setTableSpin] = useState(false);
  const [assignedAdmin, setAssignedAdmin] = useState<any>([]);
  const [userData, setUserData] = useState<any>({ data: {} });

  const url = process.env.REACT_APP_API_BASE_URL || "localhost:3000";
  const [unit, setUnit] = useState("min");
  const dispatch = useDispatch();

  const account: IAccount = useSelector((state: IStateType) => state.account);
  const history = useHistory();
  dispatch(updateCurrentPath("Pricing", ""));

  useEffect(() => {
    if (account.email === "founders@lilchirp.io") {
      // dataFetch();
    } else {
      history.push("/");
    }
  }, []);
  const adminRef = db.collection("userPricingConfig");

  const [data1, setData1] = useState<any>([]);

  const dataFetch = async () => {
    await db
      .collection("accountBalance")
      .get()
      .then((res) => {
        res.forEach((doc) => {
          if (doc.exists) {
            setData((oldArray) => [
              ...oldArray,
              {
                balance: doc.data().balance,
                userEmail: doc.data().userEmail,
                id: doc.id,
              },
            ]);
          } else {
          }
        });
      });

    let dat: any = [];
    await adminRef.get().then((res) => {
      if (!res.empty) {
        res.forEach((doc) => {
          dat.push({ id: doc.id, data: doc.data() });
        });
      }
    });

    setPopup1(false);
    setTableSpin(true);
    setAssignedAdmin(dat);
  };
  const authCheck = (e) => {
    e.preventDefault();
    axios
      .post(url.concat("auth"), { email: account.email, password: password })
      .then((res) => {
        if (res.data.status) {
          dataFetch();
        } else {
          Swal.fire({
            title: "Enter Correct Password!",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      });
  };

  const balanceAdd = (e) => {
    setUserData(e);
    setPopup(true);
    setValue(e.data.price);
    setUnit(e.data.unit);
  };

  const savePricingId = async () => {
    await adminRef
      .where("userEmail", "==", userData.userEmail)
      .get()
      .then(async (snap) => {
        if (snap.empty) {
          await adminRef
            .add({
              userEmail: userData.userEmail,
              unit: unit,
              price: value,
            })
            .then((res) => {
              let dat = {
                id: res.id,
                data: {
                  userEmail: userData.userEmail,
                  price: value,
                  unit: unit,
                  currency: userData.data.currency,
                },
              };
              setAssignedAdmin([...assignedAdmin, dat]);
            });
        } else {
          let upd = {
            price: value,
            unit: unit,
          };
          await adminRef
            .doc(userData.newId)
            .update(upd)
            .then((res) => {
              const foundIndex = assignedAdmin.findIndex(
                (pr) => pr.data.userEmail === userData.userEmail
              );
              let products = assignedAdmin;
              products[foundIndex] = {
                id: userData.newId,
                data: {
                  userEmail: userData.userEmail,
                  price: value,
                  unit: unit,
                  currency: userData.data.currency,
                },
              };
              setAssignedAdmin(products);
            });
        }
      });

    setPopup(false);
  };

  const filter = (e) => {
    if (e.target.value) {
      const newArray = data.filter((d) =>
        d.userEmail.includes(e.target.value, 0)
      );
      setData1(newArray);
    } else {
      setData1([]);
    }
  };

  return (
    <div>
      <Popup
        contentStyle={{ height: "180px" }}
        className="popup-modal"
        open={popup1}
      >
        <div className="popup-modal">
          <div className="popup-title">Enter Password</div>

          <form className="popup-content" onSubmit={authCheck}>
            <input
              type="password"
              className="form-control"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <button className="btn btn-success" type="submit">
              Check
            </button>
          </form>
        </div>
      </Popup>

      {tableSpin ? (
        <>
          <div>
            <input
              className="form-control m-2"
              placeholder="search..."
              onChange={filter}
            />
          </div>
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">Currency</th>
                <th scope="col">Unit</th>
                <th scope="col">Price</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {data1.length > 0
              ? data1.map((d, idx) => {
                  let show = false;
                  let assigned = assignedAdmin.find(
                    (o) => o.data.userEmail === d.userEmail
                  );
                  if (assigned) {
                    if (assigned.data.access) show = true;
                    else show = false;
                  }

                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{d.userEmail || "--"}</td>
                      <td>{(assigned && assigned.data.currency) || "--"}</td>
                      <td>{(assigned && assigned.data.unit) || "--"}</td>
                      <td>{(assigned && assigned.data.price) || "--"}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (assigned) {
                              d.newId = assigned.id;
                              d.data = assigned.data;
                            }

                            balanceAdd(d);
                          }}
                        >
                          Edit Balance
                        </button>
                      </td>
                    </tr>
                  );
                })
              : data.map((d, idx) => {
                  let show = false;
                  let assigned = assignedAdmin.find(
                    (o) => o.data.userEmail === d.userEmail
                  );
                  if (assigned) {
                    if (assigned.data.access) show = true;
                    else show = false;
                  }

                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{d.userEmail || "--"}</td>
                      <td className="upperCase">
                        {(assigned && assigned.data.currency) || "--"}
                      </td>
                      <td className="text-capitalize">
                        {(assigned && assigned.data.unit) || "--"}
                      </td>
                      <td>{(assigned && assigned.data.price) || "--"}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (assigned) {
                              d.newId = assigned.id;
                              d.data = assigned.data;
                            }

                            balanceAdd(d);
                          }}
                        >
                          Add Pricing
                        </button>
                      </td>
                    </tr>
                  );
                })}
          </table>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Loading...
        </div>
      )}

      <Modal show={popup} onHide={() => setPopup(false)}>
        <div>
          <div className="modal-header">
            Pricing
            <strong>{userData.userEmail}</strong>
          </div>
          {Object.keys(userData).length > 0 && (
            <div className="modal-body">
              <p>
                Client Currency :{" "}
                <span className="upperCase">
                  {userData.data.currency || "--"}
                </span>
              </p>

              <label className="pricing-label"> Select Biling Unit</label>
              <select
                value={unit}
                className="form-control pricing-select"
                onChange={(e) => setUnit(e.target.value)}
              >
                <option value={""}>Select Unit</option>
                <option value={"min"}>Per Minute</option>
                <option value={"sec"}>Per Second</option>
              </select>
              <label className="pricing-label text-capitalize">
                Enter Price / {unit}
              </label>
              <input
                placeholder="Enter price"
                value={value}
                type={"number"}
                className="form-control pricing-input"
                onChange={(e) => setValue(e.target.value)}
              />
              <div className="modal-footer" style={{ border: "none" }}>
                <button
                  onClick={() => savePricingId()}
                  className="btn btn-primary pricing-button"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default PricingStructure;
