import { scheduleType } from "./schedule.type";

const initialState = {
  schedule: null,
  scheduleList: [],
};

function scheduleReducer(state = initialState, action) {
  switch (action.type) {
    case scheduleType.GET_SCHEDULE_LIST: {
      return { ...state, scheduleList: action.payload };
    }
    default:
      return state;
  }
}

export default scheduleReducer;
