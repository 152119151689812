import Swal from "sweetalert2";

export const swalSuccessMessage = (msg) => {
  Swal.fire({
    title: "Successful!",
    text: msg,
    icon: "success",
    confirmButtonText: "Okay",
    timer: 3000,
  });
};

export const swalWarningMessage = (msg) => {
  Swal.fire({
    title: "Warning!",
    text: msg,
    icon: "warning",
    showConfirmButton: false,
    timer: 3000,
  });
};

export const swalErrorMessage = (msg) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: msg,
    timer: 3000,
  });
};
