import React, { Fragment, Dispatch, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IStateType, IProductState } from "../../store/models/root.interface";
import {
  IProduct,
  ProductModificationStatus,
} from "../../store/models/product.interface";
import ReactAudioPlayer from "react-audio-player";
import Popup from "reactjs-popup";
import { addNotification } from "../../store/actions/notifications.action";

import {
  removeProduct,
  clearSelectedProduct,
  setModificationState,
  changeSelectedProduct,
  editTag,
} from "../../store/actions/products.action";
import { db } from "../../firebase";
import { useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export type productListProps = {
  onSelect?: (product: IProduct) => void;
  children?: React.ReactNode;
};

function ProductList(props: productListProps): JSX.Element {
  const { state } = useLocation();
  //console.log(state);
  const dispatch: Dispatch<any> = useDispatch();
  const [popup, setPopup] = useState(false);

  const products: any = useSelector((state: any) => state.products);

  const onCheck = (id, track) => {
    let data = {
      id,
      track,
    };
    dispatch(editTag(data));
  };

  const productElements: (JSX.Element | null)[] = products.products.map(
    (product, idx) => {
      let displayTrue = false;
      if (!product) {
        return null;
      }
      if (product.isDelete === true || product.isDelete === undefined) {
        displayTrue = true;
      }

      return (
        <tr
          // className={`table-row ${
          //   products.selectedProduct &&
          //   products.selectedProduct.id === product.id
          //     ? "selected"
          //     : ""
          // }`}
          onClick={() => {
            if (props.onSelect) props.onSelect(product);
          }}
          key={`product_${product.id}`}
        >
          <th scope="row">{idx + 1}</th>
          <td>{product.name}</td>
          <td>{product.tag}</td>
          <td>
            {product.downloadUrl ? (
              <ReactAudioPlayer
                src={product.downloadUrl}
                controls
                style={{
                  minWidth: "40px",
                  height: "30px",
                }}
              />
            ) : (
              ""
            )}
          </td>

          <td>
            {product.name !== "filler" && product.name !== "Filler" && (
              <input
                defaultChecked={product.track}
                className="mt-3"
                type="checkbox"
                onChange={(e) => {
                  if (!e.target.checked) {
                    onCheck(product.firebaseId, false);
                  } else {
                    onCheck(product.firebaseId, true);
                  }
                }}
              />
            )}
          </td>
          <td>
            {/* <button
              className="btn btn-danger"
              onClick={() => {
                if (props.onSelect) props.onSelect(product);

                setPopup(true);
              }}
            >
              Delete
            </button> */}

            <button
              className={`btn btn-${
                product.downloadUrl ? "primary" : "info"
              } m-1`}
              onClick={() => {
                dispatch(setModificationState(ProductModificationStatus.Edit));
              }}
            >
              {product.downloadUrl ? "Edit" : "Add"}
            </button>
            {displayTrue && (
              <button
                className="btn btn-danger m-1"
                onClick={() => {
                  if (props.onSelect) props.onSelect(product);
                  setPopup(true);
                }}
              >
                Delete
              </button>
            )}

            {product.helperText && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="button-tooltip-2">{product.helperText}</Tooltip>
                }
              >
                {/* <i
                className="fas fa-fw fa-info"
                style={{ cursor: "pointer" }}
              ></i> */}
                <i
                  className="fa fa-info-circle m-2"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                ></i>

                {/* <i class="fa-solid fa-circle-info"></i> */}
              </OverlayTrigger>
            )}
          </td>
        </tr>
      );
    }
  );

  return (
    <div className="table-responsive portlet">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Recording Name</th>
            <th scope="col">Tag</th>
            <th scope="col">Listen</th>
            <th scope="col">Track</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.products.length > 0 ? (
            productElements
          ) : (
            <tr className={"table-row"}>
              <td></td>
              <td>
                No recording. Click on "Add recording" to add a new recording
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">
            Remove{" "}
            {products.selectedProduct ? products.selectedProduct.name : "this"}{" "}
            recording?
          </div>
          <div className="popup-content">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (!products.selectedProduct) {
                  return;
                }
                dispatch(
                  addNotification(
                    "Recording removed",
                    `Recording ${products.selectedProduct.name} was removed`
                  )
                );
                dispatch(removeProduct(products.selectedProduct));
                dispatch(clearSelectedProduct());
                setPopup(false);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default ProductList;
