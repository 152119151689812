import React, { useState } from "react";
import { auth } from "../../firebase";
import "./NewLogin.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./css/util.css";
import "./css/main.css";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../Constant/swalMessage";
import { Link } from "react-router-dom";

function Reset1() {
  const [spin, setSpin] = useState(false);
  const [state, setState] = useState<any>({
    email: "",
  });

  const { email } = state;

  const resetPassword = async (e) => {
    e.preventDefault();
    setSpin(true);
    if (email) {
      let c = await auth
        .sendPasswordResetEmail(email)
        .then((res) => {
          swalSuccessMessage("Please check your email");
          setSpin(false);
        })
        .catch((err) => {
          swalErrorMessage("No account found with this email address");
          setSpin(false);
        });
    } else {
      swalErrorMessage("Please enter a valid email");
      setSpin(false);
    }
  };

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form
            className="login100-form validate-form"
            onSubmit={resetPassword}
          >
            <span className="login100-form-title p-b-49">Register</span>

            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Username is reauired"
            >
              <span className="label-input100">Email</span>
              <input
                className="input100"
                type="email"
                name="email"
                placeholder="Type your email"
                required={true}
                value={email}
                onChange={(e) =>
                  handleChange("email", e.target.value.toLowerCase())
                }
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>

            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  disabled={spin}
                >
                  {spin ? "Please wait..." : "Reset Password"}
                </button>
              </div>
            </div>
            <div className="flex-col-c p-t-50 p-b-10">
              <span className="txt1 p-b-17">Or </span>
            </div>
            <div>
              <span className="text-right p-t-8 p-b-31 ">
                <Link to={"/login"} className="bottom-span">
                  Login
                </Link>
              </span>
              <span className="text-left p-t-8 p-b-31 p-l-250">
                <Link to={"/register"} className="bottom-span">
                  Register
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Reset1;
