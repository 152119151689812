import { API, axiosClient, BASE_URL_API } from "../apiConstant";
import { swalErrorMessage, swalSuccessMessage } from "../swalMessage";

export const getCampaign = async () => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API}${API.CAMPAIGN.CAMPAIGN1}`);
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const triggerCampaign = async (id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGN_RUNS}/${id}`,
      { id: id }
    );
    if (res?.data?.status === 200) {
      return res.data;
    } else {
      swalErrorMessage(res?.data?.errMsg);
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const triggerCampaign1 = async (id) => {
  try {
    let res = await axiosClient.post(`${BASE_URL_API}/run-campaign-v1/${id}`, {
      id: id,
    });
    if (res?.data?.status === 200) {
      return res.data;
    } else {
      swalErrorMessage(res?.data?.errMsg);
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getRunCampaign = async (id, count) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGN_RUNS}/${id}?${count}`
    );
    if (res?.status === 200) {
      return res?.data;
    } else {
      swalErrorMessage(res?.data?.errMsg);
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCampaignRunDetails = async (id, filterData) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGN_RUNS_DETAILS}/${id}`,
      { params: { filter: filterData } }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCampaignPageWidgetsCount = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGNS_WIDGET_COUNT}`
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCampaignRunsPageWidgetsCount = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGNS_RUNS_WIDGET_COUNT}/${id}`
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getCampaignRunsDetailsPageWidgetsCount = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGNS_RUNS_DETAILS_WIDGET_COUNT}/${id}`
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const exportExcelFile = async (id, filterData) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API}${API.CAMPAIGN.EXCEL_EXPORT}/${id}`,
      { params: { filter: filterData } }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveCampaign = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGN1}`,
      { ...data }
    );
    if (res?.data?.status === 200) {
      return res.data;
    } else {
      swalErrorMessage(res?.data?.errMsg);
    }
  } catch (err) {
    console.log("errerrerrerr", err);
    throw err;
  }
};

export const deleteCampaignById = async (id) => {
  try {
    console.log(id);
    let res = await axiosClient.delete(
      `${BASE_URL_API}${API.CAMPAIGN.CAMPAIGN1}/${id}`
    );
    if (res?.data?.status === 200) {
      return res.data;
    } else {
      swalErrorMessage(res?.data?.errMsg);
    }
  } catch (err) {
    console.log("errerrerrerr", err);
    throw err;
  }
};

export const saveFilterdCampaign = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.CAMPAIGN.SAVE_FILTERED_CAMPAIGN}`,
      { ...data }
    );
    if (res?.data?.status === 200) {
      return res.data;
    } else {
      swalErrorMessage(res?.data?.errMsg);
    }
  } catch (err) {
    console.log("errerrerrerr", err);
    throw err;
  }
};
