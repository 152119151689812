import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { axiosClient } from "../../Constant/apiConstant";
import Skeleton from "react-loading-skeleton";
import { shortenString } from "../../Constant/helper";

function SummaryModel({ show, toggle, state, setState, track }) {
  const [localState, setLocalState] = useState({
    summaryView: false,
    summary: "",
  });
  const { selectedCampaignRun } = state;
  const viewCallSummary = async () => {
    setLocalState((prev) => ({
      ...prev,
      summaryView: true,
    }));
    let res = await axiosClient.post(
      "https://flow-creator-docs.api.lilchirp.io/prompt/summary",
      { recording_url: selectedCampaignRun?.callRecordingUrl }
    );
    if (res.data.summary) {
      setLocalState((prev) => ({
        ...prev,
        summaryView: true,
        summary: res.data.summary,
      }));
    }
  };

  console.log(state.selectedCampaignRun);
  const { summaryView, summary } = localState;
  const getResponse = (tag) => {
    let returnRes = "";
    selectedCampaignRun?.outcomes_response?.forEach((res) => {
      if (res.includes(tag)) {
        console.log("resres", res);
        returnRes = res.split(":")[1];
      }
    });
    return returnRes;
  };
  return (
    <div>
      {" "}
      <Modal keyboard={false} show={show} onHide={toggle} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Responses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table border">
            <thead>
              <tr>
                <th>User Response</th>
                <th>Recording Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {" "}
                <td> Hi</td>
                <td>Greeting</td>
              </tr>

              {track.map((record, idx) => {
                if (record.tag !== "greeting" && getResponse(record.tag) !== "")
                  return (
                    <tr key={idx}>
                      <td>{shortenString(getResponse(record.tag), 40)} </td>
                      <td>{record.name}</td>
                    </tr>
                  );
              })}
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-info" onClick={viewCallSummary}>
            {!summaryView
              ? "Get Call Summary"
              : summary
              ? "Get Call Summary"
              : "Fetching"}
          </button>
          <button className="btn btn-info" onClick={toggle}>
            Close
          </button>
        </Modal.Footer>

        {summaryView && (
          <Modal.Body>
            <div className="card">
              <div className="card-header">Summary of Call</div>
              <div className="card-body">
                {summary ? summary : <Skeleton count={1} height={50} />}
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
}

export default SummaryModel;
