import { campaignsType } from "./campaigns.type";

const initialState = {
  campaignsList: null,
  runCampaignList: null,
  runCampaignDetailsList: null,
  totalCampaign: 0,
  totalCampaignRuns: 0,
  totalCallsMade: 0,
  totalCampaignRunPageCampaignRun: 0,
  totalCampaignRunPageCallConnected: 0,
  totalCampaignRunPageCallMade: 0,
  totalCampaignRunDetailsPageCallFailed: 0,
  totalCampaignRunDetailsPageCallConnected: 0,
  totalCampaignRunDetailsPageCallMade: 0,
};

function mainCampaignReducer(state = initialState, action) {
  switch (action.type) {
    case campaignsType.GET_CAMPAIGN_LIST: {
      return { ...state, campaignsList: action.payload };
    }
    case campaignsType.GET_RUN_CAMPAIGN_LIST: {
      const { data, count } = action.payload;
      return {
        ...state,
        runCampaignList: data,
        totalCampaignRunPageCampaignRun: count?.campaignRuns,
        totalCampaignRunPageCallConnected: count?.totalsuccess,
        totalCampaignRunPageCallMade: count?.totalCalls,
      };
    }
    case campaignsType.GET_CAMPAIGN_WIDGET_COUNT: {
      console.log(action.payload);
      let { totalCampaign, totalCampaignRuns, totalCallsMade } =
        action.payload.data;
      return {
        ...state,
        totalCampaign: totalCampaign,
        totalCampaignRuns: totalCampaignRuns,
        totalCallsMade: totalCallsMade,
      };
    }
    // case campaignsType.GET_CAMPAIGN_RUN_WIDGET_COUNT: {
    //   let { campaignRuns, totalCalls, totalsuccess } = action.payload.data;
    //   return {
    //     ...state,
    //     totalCampaignRunPageCampaignRun: campaignRuns,
    //     totalCampaignRunPageCallConnected: totalsuccess,
    //     totalCampaignRunPageCallMade: totalCalls,
    //   };
    // }
    case campaignsType.GET_CAMPAIGN_RUN_DETAILS_WIDGET_COUNT: {
      let { callFailed, callConnected, totalCallsMade } = action.payload.data;
      return {
        ...state,
        totalCampaignRunDetailsPageCallFailed: callFailed,
        totalCampaignRunDetailsPageCallConnected: callConnected,
        totalCampaignRunDetailsPageCallMade: totalCallsMade,
      };
    }
    case campaignsType.GET_RUN_CAMPAIGN_DETAILS_LIST: {
      return {
        ...state,
        runCampaignDetailsList: action.payload.data,
      };
    }

    default:
      return state;
  }
}

export default mainCampaignReducer;
