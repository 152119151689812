import * as userAction from "../../../Constant/CommonService/user.service";
import { userType } from "./user.type";
export const getToken = (data) => async (dispatch) => {
  let res = await userAction.getTokenData(data);
  if (res) {
    console.log("res", res);
    localStorage.setItem("token", res);
    dispatch({ type: userType.GET_TOKEN, payload: res });
  }
};

export const getGoogleToken = (data) => async (dispatch) => {
  let res = await userAction.getGoogleTokenData(data);
  if (res) {
    localStorage.setItem("token", res);
    dispatch({ type: userType.GET_GOOGLE_TOKEN, payload: res });
  }
};
export const profileCheck = (data) => async (dispatch) => {
  let res = await userAction.profileCheck(data);
  if (res) {
    return res;
  }
};
