import { IProductState, IActionBase } from "../models/root.interface";
import {
  ADD_CAMPAIGNRUN,
  GET_CAMPAIGNRUN,
} from "../actions/campaignRuns.actions";
import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
import firebase from "../../firebase";

let localUserEmail = localStorage.getItem("user_email");

function getCampaignRunData() {
  let campaignRunData = [
    {
      userEmail: "1",
      timeStamp: "Welcome",
      audienceCount: 5,
      campaignId: "Welcome",
    },
  ];

  const campaignRunRef = firebase.firestore().collection("campaignRuns");
  campaignRunRef
    .where("userEmail", "==", localUserEmail)
    .get()
    .then((snapshot) => {
      campaignRunData.shift();
      let index = 0;
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach((doc) => {
        if (!doc.data().isDelete) {
          campaignRunData.push({
            userEmail: doc.data().userEmail,
            timeStamp: doc.data().timeStamp,
            audienceCount: doc.data().audienceCount,
            campaignId: doc.data().campaignId,
          });
        }
      });
    });
  return campaignRunData;
}

const initialState: any = {
  modificationState: ProductModificationStatus.None,
  selectedProduct: null,
  campaigns: getCampaignRunData(),
};

// products: [{
//     id: 1, name: "Welcome", description: "This is Chocolate and it is Sweet",
//     amount: 5, price: 4, hasExpiryDate: true, category: "Welcome"
// },
// {
//     id: 2, name: "Pitch Product", description: "This is Apple and it is healthy",
//     amount: 5, price: 2, hasExpiryDate: true, category: "Pitch"
// },
// {
//     id: 3, name: "Affirm", description: "This is Straw and you can use it for your drink",
//     amount: 100, price: 1, hasExpiryDate: false, category: "Affirm"
// },
// {
//     id: 4, name: "Deny", description: "This is Spoon and it is useful while eating",
//     amount: 3, price: 2, hasExpiryDate: false, category: "Deny"
// },
// {
//     id: 5, name: "Good Bye", description: "This is Sugar and it is to make your life sweet",
//     amount: 15, price: 5, hasExpiryDate: true, category: "Good Bye"
// }]

function campaignRunReducer(
  state: any = initialState,
  action: IActionBase
): IProductState {
  switch (action.type) {
    case GET_CAMPAIGNRUN: {
      let res = getCampaignRunData();
      return { ...state, campaigns: res };
    }
    case ADD_CAMPAIGNRUN: {
      return { ...state, campaigns: [action.campaignRun, ...state.campaigns] };
    }

    default:
      return state;
  }
}

export default campaignRunReducer;
