import { IProductState, IActionBase } from "../models/root.interface";
import {
  ADD_AUDIENCE,
  CHANGE_PRODUCT_PENDING_EDIT,
  CLEAR_PRODUCT_PENDING_EDIT,
  SET_MODIFICATION_STATE,
  CHANGE_PRODUCT_AMOUNT,
  REMOVE_AUDIENCE,
} from "../actions/audience.action";
import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
import firebase from "../../firebase";
import { ADD_SETTING, UPDATE_SETTING } from "../actions/advanceSetting.actions";

let localUserEmail = localStorage.getItem("user_email");

function getAudienceData() {
  let audienceData = {
    id: "",
    apiKey: "",
    ruleId: "",
    accountId: "",
  };

  const recordingRef = firebase.firestore().collection("voximplantSettings");
  recordingRef
    .where("userEmail", "==", localUserEmail)
    .get()
    .then((snapshot) => {
      let index = 0;
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach((doc) => {
        audienceData.id = doc.id;
        audienceData.apiKey = doc.data().voximplantApiKey;
        audienceData.ruleId = doc.data().voximplantRuleId;
        audienceData.accountId = doc.data().voximplantAccountId;
      });
    });

  return audienceData;
}

const initialState: any = {
  modificationState: ProductModificationStatus.None,
  selectedProduct: null,
  audience: getAudienceData(),
};

// products: [{
//     id: 1, name: "Welcome", description: "This is Chocolate and it is Sweet",
//     amount: 5, price: 4, hasExpiryDate: true, category: "Welcome"
// },
// {
//     id: 2, name: "Pitch Product", description: "This is Apple and it is healthy",
//     amount: 5, price: 2, hasExpiryDate: true, category: "Pitch"
// },
// {
//     id: 3, name: "Affirm", description: "This is Straw and you can use it for your drink",
//     amount: 100, price: 1, hasExpiryDate: false, category: "Affirm"
// },
// {
//     id: 4, name: "Deny", description: "This is Spoon and it is useful while eating",
//     amount: 3, price: 2, hasExpiryDate: false, category: "Deny"
// },
// {
//     id: 5, name: "Good Bye", description: "This is Sugar and it is to make your life sweet",
//     amount: 15, price: 5, hasExpiryDate: true, category: "Good Bye"
// }]

function productsReducer(state: any = initialState, action: IActionBase): any {
  switch (action.type) {
    case UPDATE_SETTING: {
      // let maxId: number = Math.max.apply(Math, state.products.map(function(o) { return o.id; }));
      // action.product.id = maxId + 1;

      state.audience.accountId = action.audience.voximplantAccountId;
      state.audience.ruleId = action.audience.voximplantRuleId;
      state.audience.apiKey = action.audience.voximplantApiKey;
      state.audience.clientId = action.audience.clientId;

      return { ...state };
    }
    case ADD_SETTING: {
      // let maxId: number = Math.max.apply(Math, state.products.map(function(o) { return o.id; }));
      // action.product.id = maxId + 1;

      state.audience.accountId = action.audience.voximplantAccountId;
      state.audience.ruleId = action.audience.voximplantRuleId;
      state.audience.apiKey = action.audience.voximplantApiKey;
      state.audience.apiKey = action.audience.voximplantApiKey;
      state.audience.clientId = action.audience.clientId;

      return { ...state };
    }

    case CHANGE_PRODUCT_PENDING_EDIT: {
      return { ...state, selectedProduct: action.product };
    }
    case CLEAR_PRODUCT_PENDING_EDIT: {
      return { ...state, selectedProduct: null };
    }
    case SET_MODIFICATION_STATE: {
      return { ...state, modificationState: action.value };
    }
    case CHANGE_PRODUCT_AMOUNT: {
      const foundIndex: number = state.products.findIndex(
        (pr) => pr.id === action.id
      );
      let products: IProduct[] = state.products;
      products[foundIndex].amount = products[foundIndex].amount - action.amount;
      return { ...state, products: products };
    }
    default:
      return state;
  }
}

export default productsReducer;
