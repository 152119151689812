export const FETCH_BALANCE: string = "FETCH_BALANCE";
export const EDIT_BALANCE: string = "EDIT_BALANCE";
export const ADD_SUB_ID: string = "ADD_SUB_ID";

export function accountBalance(id: any): any {
  return { type: FETCH_BALANCE, id };
}

export function editBalance(id: any): any {
  return { type: EDIT_BALANCE, id };
}
export function addSubId(id: any): any {
  return { type: ADD_SUB_ID, id };
}
