import React, { Fragment, Dispatch, useEffect } from "react";
import CampaignList from "./CampaignList";
import CampaignForm from "./CampaignForm";
import TopCard from "../../common/components/TopCard";
import "./Campaign.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IProductState,
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import {
  clearSelectedProduct,
  setModificationState,
} from "../../store/actions/campaign.action";
import { ProductModificationStatus } from "../../store/models/product.interface";
import * as campaignActions from "../../store/Store/campaigns/campaigns.action";

const Products: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const products: IProductState = useSelector(
    (state: IStateType) => state.products
  );
  useEffect(() => {
    const init = async () => {
      await dispatch(campaignActions.getCampaignPageWidgetsCount());
      await dispatch(campaignActions.getCampaignList());
    };
    init();
  }, [dispatch]);
  const reducer: any = useSelector((state) => state);
  const { mainCampaignReducer } = reducer;
  const { totalCampaign, totalCampaignRuns, totalCallsMade } =
    mainCampaignReducer;

  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );

  useEffect(() => {
    dispatch(clearSelectedProduct());
    dispatch(updateCurrentPath("campaigns", "list"));
  }, [path.area, dispatch]);

  return (
    <Fragment>
      <h1 className="h3 mb-2 text-gray-800">Campaigns</h1>
      <p className="mb-4">Build your campaign here</p>
      <div className="row">
        <TopCard
          title="Total Campaigns"
          text={`${totalCampaign}`}
          icon="box"
          class="primary"
        />
        <TopCard
          title="Total Campaign Runs"
          text={`${totalCampaignRuns}`}
          icon="warehouse"
          class="danger"
        />
        <TopCard
          title="Total Calls Made"
          text={`${totalCallsMade}`}
          icon="phone-volume"
          class="success"
        />
      </div>
      {products.modificationState === ProductModificationStatus.Create ||
      (products.modificationState === ProductModificationStatus.Edit &&
        products.selectedProduct) ? (
        <CampaignForm />
      ) : null}

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Campaign List</h6>
              <div className="header-buttons">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    dispatch(
                      setModificationState(ProductModificationStatus.Create)
                    )
                  }
                >
                  <i className="fas fa fa-plus"></i> Add Campaign
                </button>
                &nbsp;
              </div>
            </div>
            <div className="card-body">
              <CampaignList />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Products;
