import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";
import { IStateType } from "../../store/models/root.interface";
import { IAccount } from "../../store/models/account.interface";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { info } from "console";

function AddBalance() {
  const [data, setData] = useState<any>([]);
  const [popup, setPopup] = useState(false);
  const [popup1, setPopup1] = useState(true);
  const [value, setValue] = useState("");
  const [balance, setBalance] = useState<any>({});
  const [password, setPassword] = useState("");
  const [tableSpin, setTableSpin] = useState(false);
  const [assignedAdmin, setAssignedAdmin] = useState<any>([]);
  const url = process.env.REACT_APP_API_BASE_URL || "localhost:3000";

  const account: IAccount = useSelector((state: IStateType) => state.account);
  const history = useHistory();
  useEffect(() => {
    if (account.email === "founders@lilchirp.io") {
    } else {
      history.push("/");
    }
  }, []);
  const [data1, setData1] = useState<any>([]);

  const dataFetch = async () => {
    await db
      .collection("accountBalance")
      .get()
      .then((res) => {
        res.forEach((doc) => {
          if (doc.exists) {
            setData((oldArray) => [
              ...oldArray,
              {
                balance: doc.data().balance,
                userEmail: doc.data().userEmail,
                id: doc.id,
              },
            ]);
          } else {
          }
        });
      });
    let dat: any = [];
    await db
      .collection("adminAccess")
      .get()
      .then((res) => {
        if (!res.empty) {
          res.forEach((doc) => {
            dat.push({ id: doc.id, data: doc.data() });
          });
        }
      });

    setPopup1(false);
    setTableSpin(true);
    setAssignedAdmin(dat);
  };

  const authCheck = (e) => {
    e.preventDefault();

    axios
      .post(url.concat("auth"), { email: account.email, password: password })
      .then((res) => {
        if (res.data.status) {
          dataFetch();
        } else {
          Swal.fire({
            title: "Enter Correct Password!",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      });
  };

  const balanceAdd = (e) => {
    setPopup(true);
    setBalance(e);
  };

  const saveBalance = () => {
    db.collection("accountBalance")
      .doc(balance.id)
      .update({
        balance: balance.balance + parseInt(value),
      })
      .then((res) => {
        db.collection("accountBalance")
          .get()
          .then((res) => {
            setData([]);
            setData1([]);
            res.forEach((doc) => {
              if (doc.exists) {
                setData((oldArray) => [
                  ...oldArray,
                  {
                    balance: doc.data().balance,
                    userEmail: doc.data().userEmail,
                    id: doc.id,
                  },
                ]);
              } else {
              }
            });
          });
        setTableSpin(true);
      });
    setPopup(false);
  };

  const deductBalance = () => {
    db.collection("accountBalance")
      .doc(balance.id)
      .update({
        balance: balance.balance - parseInt(value),
      })
      .then((res) => {
        db.collection("accountBalance")
          .get()
          .then((res) => {
            setData([]);
            res.forEach((doc) => {
              if (doc.exists) {
                setData((oldArray) => [
                  ...oldArray,
                  {
                    balance: doc.data().balance,
                    userEmail: doc.data().userEmail,
                    id: doc.id,
                  },
                ]);
              } else {
              }
            });
          });
      });
    setPopup(false);
  };

  const filter = (e) => {
    if (e.target.value) {
      const newArray = data.filter((d) =>
        d.userEmail.includes(e.target.value, 0)
      );
      setData1(newArray);
    } else {
      setData1([]);
    }
  };
  const adminAssign = async (data, type) => {
    setTableSpin(false);
    const adminRef = db.collection("adminAccess");
    if (type === "remove") {
      await adminRef
        .doc(data.id)
        .update({ access: false })
        .then((res) => {
          const foundIndex = assignedAdmin.findIndex(
            (pr) => pr.data.userEmail === data.data.userEmail
          );
          let products = assignedAdmin;
          products[foundIndex] = {
            id: data.id,
            data: {
              userEmail: data.data.userEmail,
              access: false,
            },
          };
          setAssignedAdmin(products);
        });
    } else {
      await adminRef
        .where(
          "userEmail",
          "==",
          data.userEmail ? data.userEmail : data.data.userEmail
        )
        .get()
        .then(async (snap) => {
          if (snap.empty) {
            await adminRef
              .add({
                userEmail: data.userEmail,
                access: true,
              })
              .then((res) => {
                let dat = {
                  id: res.id,
                  data: {
                    userEmail: data.userEmail,
                    access: true,
                  },
                };
                setAssignedAdmin([...assignedAdmin, dat]);
              });
          } else {
            await adminRef
              .doc(data.id)
              .update({ access: true })
              .then((res) => {
                const foundIndex = assignedAdmin.findIndex(
                  (pr) => pr.data.userEmail === data.data.userEmail
                );
                let products = assignedAdmin;
                products[foundIndex] = {
                  id: data.id,
                  data: {
                    userEmail: data.data.userEmail,
                    access: true,
                  },
                };
                setAssignedAdmin(products);
              });
          }
        });
    }

    setTableSpin(true);
  };

  return (
    <div>
      <Popup
        contentStyle={{ height: "180px" }}
        className="popup-modal"
        open={popup1}
      >
        <div className="popup-modal">
          <div className="popup-title">Enter Password</div>

          <form className="popup-content" onSubmit={authCheck}>
            <input
              type="password"
              className="form-control"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <button className="btn btn-success" type="submit">
              Check
            </button>
          </form>
        </div>
      </Popup>

      {tableSpin ? (
        <>
          <div>
            <input
              className="form-control m-2"
              placeholder="search..."
              onChange={filter}
            />
          </div>
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">Balance</th>
                <th scope="col">Edit Balance</th>
                <th scope="col">Assign as Admin</th>
              </tr>
            </thead>
            {data1.length > 0
              ? data1.map((d, idx) => {
                  let show = false;
                  let assigned = assignedAdmin.find(
                    (o) => o.data.userEmail === d.userEmail
                  );
                  if (assigned) {
                    if (assigned.data.access) show = true;
                    else show = false;
                  }

                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{d.userEmail}</td>
                      <td>{d.balance}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => balanceAdd(d)}
                        >
                          Edit Balance
                        </button>
                      </td>
                      <button
                        className={`btn btn-${show ? "danger" : "info"}`}
                        onClick={() =>
                          show
                            ? adminAssign(assigned ? assigned : d, "remove")
                            : adminAssign(assigned ? assigned : d, "assign")
                        }
                      >
                        {show ? "Remove" : "Assign"}
                      </button>
                    </tr>
                  );
                })
              : data.map((d, idx) => {
                  let show = false;
                  let assigned = assignedAdmin.find(
                    (o) => o.data.userEmail === d.userEmail
                  );
                  if (assigned) {
                    if (assigned.data.access) show = true;
                    else show = false;
                  }

                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{d.userEmail}</td>
                      <td>{d.balance}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => balanceAdd(d)}
                        >
                          Edit Balance
                        </button>
                      </td>
                      <td>
                        <button
                          className={`btn btn-${show ? "danger" : "info"}`}
                          onClick={() => {
                            show
                              ? adminAssign(assigned ? assigned : d, "remove")
                              : adminAssign(assigned ? assigned : d, "assign");
                          }}
                        >
                          {show ? "Remove" : "Assign"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
          </table>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Loading...
        </div>
      )}

      <Popup
        contentStyle={{ height: "230px" }}
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">Edit Balance</div>
          <div className="popup-content">
            <p>{balance.userEmail}</p>
            <input
              type={"number"}
              className="form-control"
              onChange={(e) => setValue(e.target.value)}
            />
            <br />
            <button
              type="button"
              onClick={() => saveBalance()}
              className="btn btn-primary m-1"
            >
              Add {value}
            </button>
            <button
              type="button"
              onClick={() => deductBalance()}
              className="btn btn-danger m-1"
            >
              Deduct {value}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default AddBalance;
