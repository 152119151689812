import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import Popup from "reactjs-popup";
import { useSelector } from "react-redux";
import { IStateType } from "../../../store/models/root.interface";
import { IAccount } from "../../../store/models/account.interface";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { cloneDeep } from "lodash";

function WhatsappSetting() {
  const [data, setData] = useState<any>([]);
  const [popup, setPopup] = useState(false);
  const [popup1, setPopup1] = useState(true);
  const [value, setValue] = useState("");
  const [userData, setUserData] = useState<any>({});
  const [password, setPassword] = useState("");
  const [tableSpin, setTableSpin] = useState(false);
  const [assignedAdmin, setAssignedAdmin] = useState<any>([]);
  const [dataSet, setDataSet] = useState(true);
  const [savingData, setSavingData] = useState(false);
  const [auth, setAuth] = useState(false);
  const url = process.env.REACT_APP_API_BASE_URL || "localhost:3000";

  const account: IAccount = useSelector((state: IStateType) => state.account);
  const history = useHistory();
  useEffect(() => {
    if (account.email === "founders@lilchirp.io") {
    } else {
      history.push("/");
    }
  }, []);
  const [data1, setData1] = useState<any>([]);

  const dataFetch = async () => {
    await db
      .collection("accountBalance")
      .get()
      .then((res) => {
        res.forEach((doc) => {
          if (doc.exists) {
            setData((oldArray) => [
              ...oldArray,
              {
                balance: doc.data().balance,
                userEmail: doc.data().userEmail,
                id: doc.id,
              },
            ]);
          } else {
          }
        });
      });

    let dat: any = [];
    await db
      .collection("whatsappSetting")
      .get()
      .then((res) => {
        if (!res.empty) {
          res.forEach((doc) => {
            dat.push({ id: doc.id, data: doc.data() });
          });
        }
      });

    setPopup1(false);
    setTableSpin(true);
    setAssignedAdmin(dat);
  };

  const authCheck = (e) => {
    e.preventDefault();

    axios
      .post(url.concat("auth"), { email: account.email, password: password })
      .then((res) => {
        if (res.data.status) {
          setAuth(true);
        } else {
          Swal.fire({
            title: "Enter Correct Password!",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      });
  };
  useEffect(() => {
    if (auth) {
      dataFetch();
      setSavingData(false);
    }
  }, [auth, dataSet]);

  const balanceAdd = (e) => {
    setPopup(true);
    setUserData(e);
  };
  const filter = (e) => {
    setData1([]);
    if (e.target.value) {
      const newArray = data.filter((d) =>
        d.userEmail.includes(e.target.value, 0)
      );
      setData1(newArray);
    } else {
      setData1([]);
    }
  };

  const savePhoneId = async () => {
    setTableSpin(false);
    const adminRef = db.collection("whatsappSetting");
    await adminRef
      .where("userEmail", "==", userData.userEmail)
      .get()
      .then(async (snap) => {
        if (snap.empty) {
          await adminRef
            .add({
              userEmail: userData.userEmail,
              phoneId: value,
            })
            .then((res) => {
              let dat = {
                id: res.id,
                data: {
                  userEmail: userData.userEmail,
                  phoneId: value,
                },
              };
              setAssignedAdmin([...assignedAdmin, dat]);
            });
        } else {
          await adminRef
            .doc(userData.newId)
            .update({ phoneId: value })
            .then((res) => {
              const foundIndex = assignedAdmin.findIndex(
                (pr) => pr.data.userEmail === userData.userEmail
              );
              let products = assignedAdmin;
              products[foundIndex] = {
                id: userData.newId,
                data: {
                  userEmail: userData.userEmail,
                  phoneId: value,
                },
              };
              setAssignedAdmin(products);
            });
        }
      });

    setTableSpin(true);
    setPopup(false);
  };

  const saveActiveId = async (e, data) => {
    setSavingData(true);
    setDataSet(true);
    const adminRef = db.collection("whatsappSetting");
    await adminRef
      .doc(data.newId)
      .update({ active: e })
      .then((res) => {
        // const foundIndex = assignedAdmin.findIndex(
        //   (pr) => pr.data.userEmail === userData.userEmail
        // );
        // let products = cloneDeep(assignedAdmin);
        // products[foundIndex] = {
        //   id: data.newId,
        //   data: {
        //     userEmail: data.userEmail,
        //     active: e,
        //     phoneId: data.phoneId,
        //   },
        // };
        // console.log("products[foundIndex]=>", products[foundIndex]);
        // setAssignedAdmin(products);
      });

    setDataSet(false);

    // setTableSpin(true);
    // setPopup(false);
  };

  return (
    <div>
      <Popup
        contentStyle={{ height: "180px" }}
        className="popup-modal"
        open={popup1}
      >
        <div className="popup-modal">
          <div className="popup-title">Enter Password</div>

          <form className="popup-content" onSubmit={authCheck}>
            <input
              type="password"
              className="form-control"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <button className="btn btn-success" type="submit">
              Check
            </button>
          </form>
        </div>
      </Popup>

      {tableSpin ? (
        <>
          <div>
            <input
              className="form-control m-2"
              placeholder="search..."
              onChange={filter}
            />
          </div>
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Id</th>
                <th scope="col">Add/Edit</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {data1.length > 0
              ? data1.map((d, idx) => {
                  let show = false;
                  let phoneId = "--";
                  let active = false;
                  let assigned = assignedAdmin.find(
                    (o) => o.data.userEmail === d.userEmail
                  );

                  if (assigned) {
                    if (assigned.data.phoneId) {
                      phoneId = assigned.data.phoneId;
                      active = assigned.data.active;
                      show = true;
                    } else show = false;
                  }

                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{d.userEmail}</td>
                      <td>{phoneId}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (assigned) {
                              d.newId = assigned.id;
                            }

                            balanceAdd(d);
                          }}
                        >
                          {show ? " Edit phone id" : "Add phone id"}
                        </button>
                      </td>

                      <td>
                        {show && (
                          <Switch
                            disabled={savingData}
                            onChange={(e) => {
                              if (assigned) {
                                d.newId = assigned.id;
                                d.phoneId = phoneId;
                              }
                              saveActiveId(e, d);
                            }}
                            checked={active}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })
              : data.map((d, idx) => {
                  let show = false;
                  let phoneId = "--";
                  let active = false;

                  let assigned = assignedAdmin.find(
                    (o) => o.data.userEmail === d.userEmail
                  );
                  if (assigned) {
                    if (assigned.data.phoneId) {
                      show = true;
                      phoneId = assigned.data.phoneId;
                      active = assigned.data.active;
                    } else show = false;
                  }

                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{d.userEmail}</td>
                      <td>{phoneId}</td>

                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (assigned) {
                              d.newId = assigned.id;
                            }

                            balanceAdd(d);
                          }}
                        >
                          {show ? " Edit phone id" : "Add phone id"}
                        </button>
                      </td>
                      <td>
                        {show && (
                          <Switch
                            disabled={savingData}
                            onChange={(e) => {
                              if (assigned) {
                                d.newId = assigned.id;
                                d.phoneId = phoneId;
                              }
                              saveActiveId(e, d);
                            }}
                            checked={active}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
          </table>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bolder",
          }}
        >
          Loading...
        </div>
      )}

      <Popup
        contentStyle={{ height: "230px" }}
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick
      >
        <div className="popup-modal">
          <div className="popup-title">Add/Edit Phone Id</div>
          <div className="popup-content">
            <p>{userData.userEmail}</p>
            <input
              type={"number"}
              className="form-control"
              onChange={(e) => setValue(e.target.value)}
            />
            <br />

            <button
              type="button"
              onClick={() => savePhoneId()}
              className="btn btn-primary m-1"
            >
              Add
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default WhatsappSetting;
