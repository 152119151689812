import React, { Fragment, Dispatch, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import TopCard from "../../common/components/TopCard";
import { IProductState, IStateType } from "../../store/models/root.interface";
import { db } from "../../firebase/index";
import { Link, useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { getCurrencyData } from "../../store/actions/account.actions";
import moment from "moment";
import { getCamapaignRunDetails } from "../../store/actions/campaignRunsDetail.actions";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import { getCamapaignRun } from "../../store/actions/campaignRuns.actions";
import { getAccountBalance } from "../../Constant/CommonService/Common.service";
import * as campaignActions from "../../store/Store/campaigns/campaigns.action";
import Skeleton from "react-loading-skeleton";
import { getAudienceList } from "../../store/Store/Audience/audience.action";

const Home: React.FC = () => {
  const products: IProductState = useSelector(
    (state: IStateType) => state.products
  );
  const dispatch: Dispatch<any> = useDispatch();

  const numberItemsCount: number = products.products.length;
  const audience: any = useSelector((state: any) => state.audience);
  const [spin, setSpin] = useState(false);

  const [accountBalace, setAccountBalance] = useState(0);
  const [callerId, setCallerId] = useState<any>({});
  const [callerIdExist, setCallerIdExist] = useState(true);
  const [expiryDate, setExpiryDate] = useState(6);
  const history = useHistory();
  const account = useSelector((state: any) => {
    return state.account;
  });
  const [accountData, setAccountData] = useState({
    leftBalance: 0,
    totalMinutesLeft: 0,
    totalMinutesUsed: 0,
    usedBalance: 0,
  });

  const localUserEmail = localStorage.getItem("user_email");
  const [totalCost, setTotalCost] = useState(0);
  const [show, setShow] = useState(false);
  let showCurr = account.currencyData.currency === "inr" ? true : false;

  useEffect(() => {
    const init = async () => {
      dispatch(updateCurrentPath("dashboard", ""));
      dispatch(getCurrencyData);
      await dispatch(getCamapaignRunDetails());
      await dispatch(getCamapaignRun());
      let res: any = await dispatch(getAccountBalance(localUserEmail));
      setAccountData(res);
      await dispatch(campaignActions.getCampaignPageWidgetsCount());
      await dispatch(campaignActions.getCampaignList());
      await dispatch(getAudienceList());

      setSpin(true);
    };
    init();
  }, [dispatch]);
  const reducer: any = useSelector((state) => state);
  const { mainCampaignReducer, mainAudienceReducer } = reducer;
  const { totalCampaign, totalCallsMade } = mainCampaignReducer;
  const { totalAudience, totalContacts, audienceList } = mainAudienceReducer;

  useEffect(() => {
    const init = async () => {};
    init();
  }, []);
  useEffect(() => {
    const init = async () => {
      await db
        .collection("accountBalance")
        .where("userEmail", "==", localUserEmail)
        .get()
        .then((res) => {
          res.forEach(async (doc) => {
            if (doc.exists) {
              if (!doc.data().updated) {
                let res: any = await updateAccountBalance(
                  doc.id,
                  doc.data().balance
                );
                setAccountBalance(res);
              } else {
                setAccountBalance(doc.data().balance);
              }
            }
          });
        });

      const campaignRunRef = await db.collection("campaignRunDetails");
      campaignRunRef
        .where("userEmail", "==", localUserEmail)
        .get()
        .then((snapshot) => {
          let c = 0;
          if (snapshot.empty) {
            return;
          }
          snapshot.forEach(async (doc) => {
            if (!doc.data().newCost) {
              if (doc.data().cost) {
                let res = await updateCallCost(doc.id, doc.data().cost);
                c = c + res * 10000;
              }
            } else if (doc.data().newCost === "0") {
              c = c + 0 * 10000;
            } else {
              let pri = doc.data().newCost.split(` `)[1];
              c = c + parseFloat(pri) * 10000;
            }
          });
          setTotalCost(c / 10000);
        });

      await db
        .collection("newUser")
        .where("userEmail", "==", localUserEmail)
        .get()
        .then((res) => {
          if (res.empty) {
            setShow(true);
          } else {
            setShow(false);
          }
        });

      await db
        .collection("callerId")
        .where("userEmail", "==", localUserEmail)
        .get()
        .then((res) => {
          res.forEach((doc) => {
            if (doc.exists) {
              getDays(doc.data().expiryDate);
              setCallerId({ id: doc.id, data: doc.data() });
              console.log(doc.data());
              if (!doc.data().callerIdNo) {
                setCallerIdExist(false);
              }
            }
          });
        });
    };

    init();
  }, []);

  console.log("fdssdfsa", callerIdExist);

  const updateAccountBalance = async (id, balance) => {
    let finalBalance = 0;
    if (showCurr) {
      finalBalance = parseInt(balance) * 80;
      await db.collection("accountBalance").doc(id).update({
        balance: finalBalance,
        updated: true,
      });
    }
    return finalBalance;
  };

  const updateCallCost = async (id, balance) => {
    let finalBalance = 0;
    if (showCurr) {
      finalBalance = parseFloat(balance) * 80;
      let updatedCost = `${showCurr ? "₹" : "$"} ${finalBalance}`;
      await db
        .collection("campaignRunDetails")
        .doc(id)
        .update({
          newCost: updatedCost,
          updated: true,
        })
        .then((res) => {});
    }

    return finalBalance;
  };

  const newUser = () => {
    db.collection("newUser")
      .add({
        userEmail: localUserEmail,
        newUser: false,
      })
      .then((res) => {
        setShow(false);
        localStorage.removeItem("integration");
        localStorage.removeItem("audience");
        localStorage.removeItem("recordings");
        localStorage.removeItem("campaign");
      });
  };

  const getDays = (date) => {
    if (moment().isBefore(date) || moment().isAfter(date)) {
      if (moment(date).diff(moment(), "days") < 5) {
        let exp = moment(date).diff(moment(), "days");
        setExpiryDate(exp);
      }
    }
  };

  return spin ? (
    <Fragment>
      {Object.keys(callerId).length > 0 && callerIdExist && expiryDate < 5 && (
        <div className="toast-wrapper">
          <div className="toast" style={{ float: "right" }}>
            <div className="toast-header">
              <i className="fas fa-fw fa-bell red-color"></i>
              <strong className="mr-auto red-color ml-1">Alert</strong>
              {/* <small>{notification.date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}</small> */}

              <button
                onClick={() => setExpiryDate(6)}
                type="button"
                className="ml-2 mb-1 close"
                data-dismiss="toast"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="toast-body red-color font-bolder">
              Your Caller id is
              {expiryDate <= 0 ? " expired" : ` expiring in ${expiryDate} days`}
              <p onClick={() => history.push("/callsettings")}>
                <strong className="mr-auto red-color ml-1 cursor-pointer">
                  Click here to reverify
                </strong>
              </p>
            </div>
          </div>
        </div>
      )}
      <h1 className="h3 mb-2 text-gray-800">Dashboard</h1>
      <p className="mb-4">Summary and overview of your bots</p>

      {show ? (
        <div
          className="card mb-2"
          style={{
            maxWidth: "700px",
            margin: "auto",
            boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)",
          }}
        >
          <div className="card-header" style={{ backgroundColor: "#fff" }}>
            <span style={{ fontSize: 16, fontWeight: "bold" }}>
              Please complete the steps to get started
            </span>

            <span
              style={{ float: "right" }}
              className="btn btn-light "
              onClick={() => newUser()}
            >
              <i className="fas fa-times"></i> Close
            </span>
          </div>

          <div style={{ width: "60%", margin: "auto" }}>
            <div className="card-body">
              <Alert variant={"primary"}>
                <div className="row">
                  <div className="col">
                    <strong>Templates</strong>
                    <span style={{ float: "right" }}>
                      <Link
                        to={"/templates"}
                        className={
                          localStorage.getItem("templates")
                            ? "btn btn-success"
                            : "btn btn-light"
                        }
                        onClick={() =>
                          localStorage.setItem("templates", "true")
                        }
                      >
                        {localStorage.getItem("templates")
                          ? "1/4 Completed"
                          : "1/4 Start"}
                      </Link>
                    </span>
                  </div>
                </div>
              </Alert>
              <Alert variant={"primary"}>
                <div className="row">
                  <div className="col">
                    <strong>Create Recording</strong>
                    <span style={{ float: "right" }}>
                      <Link
                        onClick={() =>
                          localStorage.setItem("recordings", "true")
                        }
                        to={"/recordings"}
                        className={
                          localStorage.getItem("recordings")
                            ? "btn btn-success"
                            : "btn btn-light"
                        }
                      >
                        {localStorage.getItem("recordings")
                          ? "2/4 Completed"
                          : "2/4 Start"}
                      </Link>
                    </span>
                  </div>
                </div>
              </Alert>
              <Alert variant={"primary"}>
                <div className="row">
                  <div className="col">
                    <strong> Create Audience</strong>
                    <span style={{ float: "right" }}>
                      <Link
                        to={"/audience"}
                        onClick={() => localStorage.setItem("audience", "true")}
                        className={
                          localStorage.getItem("audience")
                            ? "btn btn-success"
                            : "btn btn-light"
                        }
                      >
                        {localStorage.getItem("audience")
                          ? "3/4 Completed"
                          : "3/4 Start"}
                      </Link>
                    </span>
                  </div>
                </div>
              </Alert>
              <Alert variant={"primary"}>
                <div className="row">
                  <div className="col">
                    <strong> Create Campaign</strong>
                    <span style={{ float: "right" }}>
                      <Link
                        to={"/campaigns"}
                        className={
                          localStorage.getItem("campaign")
                            ? "btn btn-success"
                            : "btn btn-light"
                        }
                        onClick={() => {
                          localStorage.setItem("campaign", "true");

                          newUser();
                        }}
                      >
                        {localStorage.getItem("campaign")
                          ? "4/4 Completed"
                          : "4/4 Start"}
                      </Link>
                    </span>
                  </div>
                </div>
              </Alert>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <TopCard
          title="Account Balance"
          text={`${showCurr ? "₹" : "$"} ${accountData?.leftBalance}`}
          icon="donate"
          class="primary"
        />
        <TopCard
          title="Total Call Cost"
          text={`${showCurr ? "₹" : "$"} ${accountData?.usedBalance}`}
          icon="dollar-sign"
          class="danger"
        />

        <TopCard
          title="Total Calls Made"
          text={`${totalCallsMade}`}
          icon="phone"
          class="success"
        />
        <TopCard
          title="Total calling minutes"
          text={`${accountData?.totalMinutesUsed}`}
          icon="clock"
          class="success"
        />
      </div>

      <div className="row">
        <TopCard
          title="Recording Count"
          text={`${numberItemsCount}`}
          icon="headphones-alt"
          class="primary"
        />
        <TopCard
          title="Audience List Count"
          text={`${totalAudience}`}
          icon="users"
          class="danger"
        />
        <TopCard
          title="Campaign Count"
          text={`${totalCampaign}`}
          icon="user"
          class="success"
        />
        <TopCard
          title="Remaining Minutes"
          text={`${accountData?.totalMinutesLeft}`}
          icon="clock"
          class="success"
        />
      </div>

      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">
                Total Calls Data
              </h6>
            </div>
            <div className="card-body">
              <div
                style={{ marginLeft: "50px", height: "400px", width: "400px" }}
              >
                <PieChart />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Campaign Data</h6>
            </div>
            <div className="card-body">
              <div style={{ marginTop: "10x", height: "400px" }}>
                <BarChart />
              </div>
              {/* <OrderList
                onSelect={onProductSelect}
                onBack={onBack}
                view={view}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <Skeleton count={10} height={35} style={{ margin: "2%" }} />
  );
};

export default Home;
