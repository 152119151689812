import { IProductState, IActionBase } from "../models/root.interface";
import {
  ADD_CAMPAIGNRUNDETAILS,
  EDIT_CAMPAIGNRUNDETAILS,
  GET_CAMPAIGNRUNDETAILS,
} from "../actions/campaignRunsDetail.actions";
import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
import firebase from "../../firebase";

let localUserEmail = localStorage.getItem("user_email");

function getCampaignRunData() {
  let campaignRunData = [
    {
      id: "",
      userEmail: "1",
      phone: "Welcome",
      cost: 5,
      campaignRunId: "Welcome",
      callStatus: "not",
      callRecordingUrl: "",
    },
  ];

  const campaignRunRef = firebase.firestore().collection("campaignRunDetails");
  campaignRunRef
    .where("userEmail", "==", localUserEmail)
    .get()
    .then((snapshot) => {
      campaignRunData.shift();
      let index = 0;
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach((doc) => {
        if (!doc.data().isDelete) {
          campaignRunData.push({
            id: doc.id,
            userEmail: doc.data().userEmail,
            phone: doc.data().phone,
            cost: doc.data().cost,
            campaignRunId: doc.data().campaignRunId,
            callStatus: doc.data().callStatus,
            callRecordingUrl: doc.data().callRecordingUrl,
          });
        }
      });
    });
  return campaignRunData;
}

const initialState: any = {
  modificationState: ProductModificationStatus.None,
  selectedProduct: null,
  campaigns: getCampaignRunData(),
};

// products: [{
//     id: 1, name: "Welcome", description: "This is Chocolate and it is Sweet",
//     amount: 5, price: 4, hasExpiryDate: true, category: "Welcome"
// },
// {
//     id: 2, name: "Pitch Product", description: "This is Apple and it is healthy",
//     amount: 5, price: 2, hasExpiryDate: true, category: "Pitch"
// },
// {
//     id: 3, name: "Affirm", description: "This is Straw and you can use it for your drink",
//     amount: 100, price: 1, hasExpiryDate: false, category: "Affirm"
// },
// {
//     id: 4, name: "Deny", description: "This is Spoon and it is useful while eating",
//     amount: 3, price: 2, hasExpiryDate: false, category: "Deny"
// },
// {
//     id: 5, name: "Good Bye", description: "This is Sugar and it is to make your life sweet",
//     amount: 15, price: 5, hasExpiryDate: true, category: "Good Bye"
// }]

function campaignRunReducer(
  state: any = initialState,
  action: IActionBase
): IProductState {
  switch (action.type) {
    case GET_CAMPAIGNRUNDETAILS: {
      let res = getCampaignRunData();
      return { ...state, campaigns: res };
    }

    case ADD_CAMPAIGNRUNDETAILS: {
      return { ...state, campaigns: [action.campaignRun, ...state.campaigns] };
    }
    case EDIT_CAMPAIGNRUNDETAILS: {
      const foundIndex = state.campaigns.findIndex(
        (pr) => pr.id === action.campaignRun.id
      );

      let products = state.campaigns;
      products[foundIndex] = action.campaignRun;
      return { ...state, campaigns: products };
    }

    default:
      return state;
  }
}

export default campaignRunReducer;
