import { IProductState, IActionBase } from "../models/root.interface";
import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
import firebase from "../../firebase";
import {
  ADD_SUB_ID,
  EDIT_BALANCE,
  FETCH_BALANCE,
} from "../actions/PaymentSetting.action";

let localUserEmail = localStorage.getItem("user_email");

const recordingRef = firebase.firestore().collection("paymentSetting");

function getAudienceData() {
  let paymentData = {
    id: "1",
    customerId: "",
    subscriptionId: "",
  };

  recordingRef
    .where("userEmail", "==", localUserEmail)
    .get()
    .then((snapshot) => {
      let index = 0;
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach((doc) => {
        paymentData.id = doc.id;
        paymentData.customerId = doc.data().customerId;
        paymentData.subscriptionId = doc.data().subscriptionId;
      });
    });

  return paymentData;
}

const initialState: any = {
  paymentData: getAudienceData(),
};

function paymentReducer(state: any = initialState, action: IActionBase): any {
  switch (action.type) {
    case FETCH_BALANCE: {
      state.paymentData.subscriptionId = action.id;

      return { ...state };
    }
    case EDIT_BALANCE: {
      recordingRef
        .doc(action.id)
        .update({
          subscriptionId: "",
        })
        .then((res) => {
          state.paymentData.subscriptionId = "";
        });

      return { ...state };
    }
    case ADD_SUB_ID: {
      recordingRef
        .doc(action.id.id)
        .update({
          subscriptionId: action.id.subId,
        })
        .then((res) => {
          state.paymentData.subscriptionId = action.id.subId;
        });

      return { ...state };
    }

    default:
      return state;
  }
}

export default paymentReducer;
