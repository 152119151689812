import * as audienceService from "../../../Constant/services/audience.service";
import {
  swalSuccessMessage,
  swalErrorMessage,
} from "../../../Constant/swalMessage";
import { audienceType } from "./audience.type";
export const addAudienceList = (data) => async (dispatch) => {
  let res = await audienceService.addAudience(data);
  console.log("resre", res);
  if (res.status === 200) {
    swalSuccessMessage("Audience Uploaded");
  } else {
    swalErrorMessage("Something went wrong");
  }
};
export const addAudienceListExcel = (data) => async (dispatch) => {
  let res = await audienceService.addAudienceExcel(data);
  if (res.status === 200) {
    swalSuccessMessage("Audience Uploaded");
  } else {
    swalErrorMessage("Something went wrong");
  }
};
export const getAudienceList = () => async (dispatch) => {
  let res = await audienceService.getAudience();
  if (res.status === 200) {
    await dispatch({
      type: audienceType.GET_AUDIENCE_LIST,
      payload: res,
    });
  } else {
    swalErrorMessage("Something went wrong");
  }
};

export const editAudienceList = (data) => async () => {
  let res = await audienceService.editAudience(data);
  console.log(res);
  if (res?.status === 200) {
    swalSuccessMessage("Updated Successfully");
  } else {
    if (res?.errMsg === '"audienceList" must contain at least 1 items') {
      swalErrorMessage("Audience list must contain at least 1 item");
    }
  }
};

export const deleteAudience = (id) => async () => {
  let res = await audienceService.deleteAudience(id);
  if (res.status === 200) {
    swalSuccessMessage("Deleted Successfully");
  } else {
    swalErrorMessage("Something went wrong");
  }
};
export const deleteCampaignById = (id) => async () => {
  let res = await audienceService.deleteCampaignById(id);
  if (res) {
    return res;
  }
};

export const addManualAudienceListExcel = (data) => async (dispatch) => {
  let res = await audienceService.addManualAudienceExcel(data);
  if (res?.status === 200) {
    swalSuccessMessage("Excel Uploaded Successfully");
  } else {
    swalErrorMessage("Something went wrong");
  }
};
