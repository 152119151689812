import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
export const ADD_CAMPAIGN: string = "ADD_CAMPAIGN";
export const EDIT_CAMPAIGN: string = "EDIT_CAMPAIGN";
export const REMOVE_CAMPAIGN: string = "REMOVE_CAMPAIGN";
export const CHANGE_PRODUCT_AMOUNT: string = "CHANGE_PRODUCT_AMOUNT";
export const CHANGE_PRODUCT_PENDING_EDIT: string =
  "CHANGE_PRODUCT_PENDING_EDIT";
export const CLEAR_PRODUCT_PENDING_EDIT: string = "CLEAR_PRODUCT_PENDING_EDIT";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";

export function addCampaign(campaign: any): any {
  return { type: ADD_CAMPAIGN, campaign: campaign };
}

export function editCampaign(product: IProduct): IEditProductActionType {
  return { type: EDIT_CAMPAIGN, product: product };
}

export function removeProduct(name: any): any {
  return { type: REMOVE_CAMPAIGN, campaignName: name };
}

export function changeProductAmount(
  id: number,
  amount: number
): IChangeProductAmountType {
  return { type: CHANGE_PRODUCT_AMOUNT, id: id, amount: amount };
}

export function changeSelectedProduct(
  product: IProduct
): IChangeSelectedProductActionType {
  return { type: CHANGE_PRODUCT_PENDING_EDIT, product: product };
}

export function clearSelectedProduct(): IClearSelectedProductActionType {
  return { type: CLEAR_PRODUCT_PENDING_EDIT };
}

export function setModificationState(
  value: ProductModificationStatus
): ISetModificationStateActionType {
  return { type: SET_MODIFICATION_STATE, value: value };
}

interface IAddProductActionType {
  type: string;
  product: IProduct;
}
interface IEditProductActionType {
  type: string;
  product: IProduct;
}
interface IRemoveProductActionType {
  type: string;
  id: number;
}
interface IChangeSelectedProductActionType {
  type: string;
  product: IProduct;
}
interface IClearSelectedProductActionType {
  type: string;
}
interface ISetModificationStateActionType {
  type: string;
  value: ProductModificationStatus;
}
interface IChangeProductAmountType {
  type: string;
  id: number;
  amount: number;
}
