import axios from "axios";
import { db } from "../../firebase";
const adminRef = db.collection("userPricingConfig");
export const LOG_IN: string = "LOG_IN";
export const LOG_OUT: string = "LOG_OUT";
export const GET_DATA: string = "GET_DATA";
export const GET_CURRENCY_DATA: string = "GET_CURRENCY_DATA";

export function login(email: string): ILogInActionType {
  return { type: LOG_IN, email: email };
}

export function logout(): ILogOutActionType {
  return { type: LOG_OUT };
}

export function getData(): ILogOutActionType {
  return { type: GET_DATA };
}
let email = localStorage.getItem("user_email");

export const getCurrencyData = async (dispatch) => {
  let curr: any = {
    currency: "usd",
    unit: "min",
    userEmail: email,
  };
  const response = await db
    .collection("userPricingConfig")
    .where("userEmail", "==", email)
    .get();
  if (response.empty) {
    axios.get("https://ipapi.co/json/").then(async (apRes) => {
      let data = apRes.data;
      if (data.country_name === "India") {
        curr.currency = "inr";
        // curr.price = "4";
      }
      await adminRef.add(curr);
    });
  } else {
    response.forEach((res) => {
      curr = res.data();
    });
  }

  dispatch({ type: GET_CURRENCY_DATA, payload: curr });
};
interface ILogInActionType {
  type: string;
  email: string;
}
interface ILogOutActionType {
  type: string;
}
