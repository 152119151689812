import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import firebase from "firebase/app";
import { auth, db } from "../../firebase";
import "./NewLogin.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./css/util.css";
import "./css/main.css";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../Constant/swalMessage";
import { login } from "../../store/actions/account.actions";
import { getGoogleToken, getToken } from "../../store/Store/User/user.action";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

function NewLogin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [spin, setSpin] = useState(false);
  const [googleButtonDisable, setGooogleButtonDisable] = useState(false);
  const [state, setState] = useState<any>({
    email: "",
    password: "",
  });
  const { email, password } = state;
  const signIn = async (e) => {
    console.log(email, password);
    e.preventDefault();
    setSpin(true);
    try {
      let obj = {
        email: email,
        password: password,
      };
      await dispatch(getToken(obj));
      await auth.signInWithEmailAndPassword(email, password);
      db.collection("accountBalance")
        .where("userEmail", "==", email)
        .get()
        .then((res) => {
          if (res.empty) {
            db.collection("accountBalance")
              .add({
                userEmail: email,
                balance: 1,
              })
              .then((res) => {
                localStorage.setItem("user_email", email);
                swalSuccessMessage("Login Successful");
                setSpin(false);
                dispatch(login(email));
              });
          } else {
            localStorage.setItem("user_email", email);
            swalSuccessMessage("Login Successful");
            dispatch(login(email));
            setSpin(false);
          }
        });
    } catch (error) {
      swalErrorMessage("Email or Password is incorrect");
      setSpin(false);
    }
  };

  firebase
    .auth()
    .getRedirectResult()
    .then(function (result) {
      let user = result.user;
      if (user) {
        setGooogleButtonDisable(true);
        signInForGoogleToken(user.email, user.uid);
      }
    });

  const signInForGoogleToken = async (userEmail, uid) => {
    await dispatch(getGoogleToken(uid));
    const adminRef = db.collection("userPricingConfig");
    let user_email = userEmail;
    let balance = 1;
    await axios.get("https://ipapi.co/json/").then(async (response) => {
      let data = response.data;
      let curr = {
        currency: "usd",
        unit: "min",
        userEmail: user_email,
      };
      if (data.country_name === "India") {
        curr.currency = "inr";
        balance = 100;
      }
      adminRef
        .where("userEmail", "==", user_email)
        .get()
        .then(async (res) => {
          if (res.empty) {
            await adminRef.add(curr);
          }
        });
    });

    await db
      .collection("accountBalance")
      .where("userEmail", "==", user_email)
      .get()
      .then((res) => {
        if (res.empty) {
          db.collection("accountBalance")
            .add({
              userEmail: user_email,
              balance: balance,
              updated: true,
            })
            .then((res) => {
              localStorage.setItem("user_email", user_email);
              swalSuccessMessage("Login successful");
              setSpin(false);
              dispatch(login(user_email));
            });
        } else {
          localStorage.setItem("user_email", user_email);
          swalSuccessMessage("Login successful");
          dispatch(login(user_email));
          setSpin(false);
        }
      });
  };

  const responseGoogle = async () => {
    setGooogleButtonDisable(true);
    let provider = new firebase.auth.GoogleAuthProvider();
    await auth.signInWithRedirect(provider);
  };

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    history.push("/");
  }, []);
  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form className="login100-form validate-form" onSubmit={signIn}>
            <span className="login100-form-title p-b-49">Login</span>

            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Username is reauired"
            >
              <span className="label-input100">Email</span>
              <input
                className="input100"
                type="email"
                name="email"
                placeholder="Type your email"
                required={true}
                value={email}
                onChange={(e) =>
                  handleChange("email", e.target.value.toLowerCase())
                }
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>

            <div
              className="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <span className="label-input100">Password</span>
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Type your password"
                required={true}
                value={password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <span className="focus-input100" data-symbol="&#xf190;"></span>
            </div>

            <div className="text-right p-t-8 p-b-31">
              <Link to={"/reset-password"}>Forgot password?</Link>
            </div>

            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  disabled={spin}
                >
                  {spin ? "Logging In" : "Login"}
                </button>
              </div>
            </div>

            <div className="txt1 text-center p-t-54 p-b-20">
              <span>Or Login Using</span>
            </div>

            <div className="flex-c-m">
              <button
                disabled={googleButtonDisable}
                onClick={responseGoogle}
                className={`login100-social-item bg3 ${
                  googleButtonDisable && "backgroundDark"
                }`}
              >
                <i className="fab fa-google"></i>
              </button>
            </div>

            <div className="flex-col-c p-t-40">
              <Link to="/register" className="txt1 p-b-17">
                Or Register
              </Link>

              {/* <Link to="/register" className="txt2">
                Sign Up
              </Link> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewLogin;
