import React, { useEffect } from "react";
import "./App.css";
import "./styles/sb-admin-2.min.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "./components/Account/Login";
import Admin from "./components/Admin/Admin";
import { PrivateRoute } from "./common/components/PrivateRoute";
import { AccountRoute } from "./common/components/AccountRoute";
import NewLogin from "./components/NewLogin/NewLogin";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { axiosClient } from "./Constant/apiConstant";

const App: React.FC = () => {
  // const ExportCSV1 = async () => {
  //   console.log("llllllllll");

  //   //localhost:8080/api/get-campaign-run-details-by-date
  //   axiosClient
  //     .post("https://api.vodex.ai/api/get-campaign-run-details-by-date", {
  //       date: "2023-05-23T00:00:00",
  //     })
  //     .then((res) => {
  //       console.log("res", res.data);
  //       if (res.data) {
  //         console.log("kkkkkkkkkkkkk");
  //         const fileType =
  //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //         const fileExtension = ".xlsx";
  //         const ws = XLSX.utils.json_to_sheet(res.data);
  //         const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //         const excelBuffer = XLSX.write(wb, {
  //           bookType: "xlsx",
  //           type: "array",
  //         });
  //         const data = new Blob([excelBuffer], { type: fileType });
  //         FileSaver.saveAs(
  //           data,
  //           `Vodex Campaign Report ${"smiles"}` + fileExtension
  //         );
  //       }
  //     });
  // };
  return (
    <div className="App" id="wrapper">
      <Router>
        <Switch>
          <PrivateRoute path="/">
            <Admin />
          </PrivateRoute>
          <AccountRoute path="/login">
            <NewLogin />  
          </AccountRoute>
        </Switch> 
      </Router>

      {/* <button onClick={ExportCSV1}>fdsafsaf</button> */}
    </div>
  );
};

export default App;
