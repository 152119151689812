import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { db } from "../../firebase/index";
import "../../styles/AdvanceSetting.css";
import {
  addSetting,
  updateSetting,
} from "../../store/actions/advanceSetting.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  IRootPageStateType,
  IStateType,
} from "../../store/models/root.interface";
import { updateCurrentPath } from "../../store/actions/root.actions";

const Swal = require("sweetalert2");

function AdvanceSetting() {
  const [voximConfig, setVoximConfig] = useState({
    id: "",
    apiKey: "",
    ruleId: "",
    accountId: "",
    clientId: "",
  });
  const [show, setShow] = useState(false);
  const [verification, setVerification] = useState("");
  const [editNo, setEditNo] = useState(true);
  let localUserEmail = localStorage.getItem("user_email");

  const dispatch = useDispatch();

  dispatch(updateCurrentPath("advance settings", ""));

  useEffect(() => {
    db.collection("voximplantSettings")
      .where("userEmail", "==", localUserEmail)
      .get()
      .then((res) => {
        res.forEach((doc) => {
          if (doc.exists) {
            setVoximConfig({
              id: doc.id,
              apiKey: doc.data().voximplantApiKey,
              ruleId: doc.data().voximplantRuleId,
              accountId: doc.data().voximplantAccountId,
              clientId: doc.data().clientId,
            });
            setEditNo(false);
          } else setEditNo(true);
        });
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (editNo) {
      setShow(true);
      if (voximConfig.id) {
        db.collection("voximplantSettings")
          .doc(voximConfig.id)
          .update({
            voximplantApiKey: voximConfig.apiKey,
            voximplantRuleId: voximConfig.ruleId,
            voximplantAccountId: voximConfig.accountId,
            clientId: voximConfig.clientId,
          })
          .then((res) => {
            Swal.fire({
              title: "Successful!",
              text: "Settings updated",
              icon: "success",
              confirmButtonText: "Okay",
            });
            setEditNo(false);

            dispatch(
              updateSetting({
                voximplantApiKey: voximConfig.apiKey,
                voximplantRuleId: voximConfig.ruleId,
                voximplantAccountId: voximConfig.accountId,
                clientId: voximConfig.clientId,
              })
            );

            setShow(false);
          });
      } else {
        db.collection("voximplantSettings")
          .add({
            voximplantApiKey: voximConfig.apiKey,
            voximplantRuleId: voximConfig.ruleId,
            voximplantAccountId: voximConfig.accountId,
            clientId: voximConfig.clientId,
            userEmail: localStorage.getItem("user_email"),
          })
          .then((res) => {
            Swal.fire({
              title: "Success!",
              text: "Settings Uploaded",
              icon: "success",
              confirmButtonText: "Okay",
            });
            setVoximConfig({ ...voximConfig, id: res.id });

            dispatch(
              addSetting({
                id: res.id,
                voximplantApiKey: voximConfig.apiKey,
                voximplantRuleId: voximConfig.ruleId,
                voximplantAccountId: voximConfig.accountId,
                clientId: voximConfig.clientId,
              })
            );
            setShow(false);
            setEditNo(false);
          });
      }
    } else {
      setEditNo(true);
    }
  };

  const onChange = (e) => {
    setVoximConfig({ ...voximConfig, [e.target.name]: e.target.value });
  };

  const resetSetting = () => {
    db.collection("voximplantSettings")
      .doc(voximConfig.id)
      .update({
        voximplantApiKey: "",
        voximplantRuleId: "",
        voximplantAccountId: "",
        clientId: "",
      })
      .then((res) => {
        Swal.fire({
          title: "Success!",
          text: "Settings updated",
          icon: "success",
          confirmButtonText: "Okay",
        });
        setEditNo(false);

        dispatch(
          updateSetting({
            voximplantApiKey: "",
            voximplantRuleId: "",
            voximplantAccountId: "",
            clientId: "",
          })
        );
        setVoximConfig({
          ...voximConfig,
          apiKey: "",
          ruleId: "",
          accountId: "",
          clientId: "",
        });

        setShow(false);
      });
  };
  return (
    <div>
      <form className="card" onSubmit={onSubmit} onChange={onChange}>
        <span className="card-header">Advance Settings</span>

        <div className="card-body card-body1">
          <fieldset disabled={!editNo}>
            <div className="row row1">
              <label className="form-label"> Voximplant API Key</label>
              <input
                value={voximConfig.apiKey}
                name="apiKey"
                required
                className="form-control"
              />
            </div>
            <div className="row row1">
              <label className="form-label"> Voximplant Rule Id </label>
              <input
                type="number"
                value={voximConfig.ruleId}
                name="ruleId"
                required
                className="form-control"
              />
            </div>
            <div className="row row1">
              <label className="form-label"> Voximplant Account Id</label>
              <input
                type="number"
                value={voximConfig.accountId}
                name="accountId"
                required
                className="form-control"
              />
            </div>
            <div className="row row1">
              <label className="form-label"> Client Id</label>
              <input
                type="text"
                value={voximConfig.clientId}
                name="clientId"
                required
                className="form-control"
              />
            </div>
          </fieldset>
        </div>
        <div className="card-footer">
          <button
            disabled={show}
            className="btn btn-success btn1"
            type="submit"
          >
            {show ? "Saving" : editNo ? "Save" : "Edit"}
          </button>
          {voximConfig.id && (
            <button
              className="btn btn-danger btn1 ml-3"
              type="submit"
              onClick={resetSetting}
            >
              Reset
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AdvanceSetting;
