import React, { Dispatch, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IStateType } from "../../store/models/root.interface";
import {
  IProduct,
  ProductModificationStatus,
} from "../../store/models/product.interface";
import {
  removeProduct1,
  clearSelectedProduct,
  setModificationState,
} from "../../store/actions/audience.action";
import { addNotification } from "../../store/actions/notifications.action";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import AudienceExpandView from "./AudienceExpandView";
import {
  deleteAudience,
  getAudienceList,
} from "../../store/Store/Audience/audience.action";

export type productListProps = {
  onSelect?: (product: IProduct) => void;
  view?: Boolean;
  onBack?: Function;
  children?: React.ReactNode;
};

function ProductList({ view, onSelect, onBack }): JSX.Element {
  const reducer: any = useSelector((state) => state);
  const { mainAudienceReducer } = reducer;
  const { audienceList } = mainAudienceReducer;
  const dispatch: Dispatch<any> = useDispatch();
  const products: any = useSelector((state: IStateType) => state.products);
  const [popup, setPopup] = useState(false);
  const [list, setList] = useState([]);
  const [deleteButton, setDeleteButton] = useState(false);
  const productElements: (JSX.Element | null)[] = audienceList?.map(
    (audience, idx) => {
      if (!audience) {
        return null;
      }
      return (
        <tr key={`product_${audience.id}`}>
          <th scope="row">{idx + 1}</th>
          <td>{audience?.audienceListName}</td>
          <td style={{ textAlign: "center" }}>
            {audience?.audienceList?.length}
          </td>
          <td>
            <button
              className="btn btn-info"
              onClick={(e) => {
                setList(audience);
                onBack(false);
              }}
            >
              View
            </button>
          </td>
          <td>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (onSelect) onSelect(audience);
                dispatch(setModificationState(ProductModificationStatus.Edit));
              }}
            >
              Edit
            </button>
          </td>
          <td>
            <button
              className="btn btn-danger"
              onClick={() => {
                if (onSelect) onSelect(audience);
                setPopup(true);
              }}
            >
              Delete
            </button>
          </td>
        </tr>
      );
    }
  );

  const deleteAud = async () => {
    setDeleteButton(true);
    await dispatch(deleteAudience(products.selectedProduct.id));
    await dispatch(getAudienceList());
    // dispatch(removeProduct1(products.selectedProduct.id));
    // dispatch(clearSelectedProduct());
    dispatch(
      addNotification(
        "Audience removed",
        `Audience ${products?.selectedProduct?.audienceListName} was removed`
      )
    );
    setDeleteButton(false);
    setPopup(false);
  };
  return view ? (
    <div className="table-responsive portlet">
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Audience List Name</th>
            <th scope="col" style={{ textAlign: "center" }}>
              Total Contacts in List
            </th>
            <th scope="col">View List</th>
            <th scope="col">Edit</th>
            <th scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
          {audienceList?.length > 0 ? (
            productElements
          ) : (
            <tr className={"table-row"}>
              <td></td>
              <td style={{ minWidth: 300 }}>
                No Audience. Click on "Add Audience" to add a new audience
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Popup
        className="popup-modal"
        open={popup}
        onClose={() => setPopup(false)}
        closeOnDocumentClick={deleteButton ? false : true}
      >
        <div className="popup-modal">
          <div className="popup-title">
            {`Remove ${
              products.selectedProduct &&
              products.selectedProduct?.audienceListName
            } Audience?`}
          </div>
          <div className="popup-content mt-6">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => deleteAud()}
              disabled={deleteButton}
            >
              Remove
            </button>
          </div>
        </div>
      </Popup>
    </div>
  ) : (
    <AudienceExpandView list={list} onBack={onBack} />
  );
}

export default ProductList;
