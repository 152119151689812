import Swal from "sweetalert2";
import { API, axiosClient, BASEURL } from "../apiConstant";
import { swalSuccessMessage } from "../swalMessage";

export const getUserData = (email) => async (dispatch) => {
  let res = await axiosClient.get(
    `${BASEURL}${API.USER.GET_USER_PROFILE}?email=${email}`
  );
  if (res?.status === 200) {
    return res;
  }
};

export const getAccountBalance = (email) => async (dispatch) => {
  let res = await axiosClient.get(
    `${BASEURL}${API.ACCOUNT_DETAILS.GET_ACCOUNT_BALANCE}?email=${email}`
  );
  if (res?.status === 200) {
    return res.data;
  }
};

export const checkSufficientBalance = (data) => async (dispatch) => {
  let res = await axiosClient.get(
    `${BASEURL}${API.BALANCE.CHECK_SUFFICIENT_BALANCE}?data=${JSON.stringify(
      data
    )}`
  );
  if (res.status === 200) {
    return res.data;
  }
};

export const getTermsConditionsUrl = () => async (dispatch) => {
  let res = await axiosClient.get(
    `${BASEURL}${API.TERMS.GET_TERMS_CONDITIONS}`
  );
  if (res.status === 200) {
    return res.data;
  }
};

export const saveDisconnectedTime = (data) => async (dispatch) => {
  let res = await axiosClient.post(
    `${BASEURL}${API.CALL_SETTINGS.SAVE_DISCONNECTED_TIME}`,
    data
  );
  if (res.status === 200) {
    swalSuccessMessage(res.data);
  }
};

export const getDisconnectedTime = (data) => async (dispatch) => {
  let res = await axiosClient.get(
    `${BASEURL}${API.CALL_SETTINGS.GET_DISCONNECTED_TIME}`,
    { params: data }
  );
  if (res.status === 200) {
    return res.data;
  }
};

export const deleteCampaignData = (data) => async (dispatch) => {
  let res = await axiosClient.delete(`${BASEURL}${API.CAMPAIGN.CAMPAIGN}`, {
    params: data,
  });
  if (res.status === 200) {
    return res.data;
  }
};
