import React, { Dispatch, useState, useEffect } from "react";
import CampaignRunList from "./CampaignRunList";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import {
  IStateType,
  IRootPageStateType,
} from "../../store/models/root.interface";
import { clearSelectedProduct } from "../../store/actions/campaign.action";
import * as campaignAction from "../../store/Store/campaigns/campaigns.action";
import SkeletonLoding from "../../Constant/SkeletonLoding";

const Products: React.FC = (props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [spin, setSpin] = useState(false);
  const path: IRootPageStateType = useSelector(
    (state: IStateType) => state.root.page
  );
  let id = window.location.href.split("?")[1];
  let campaignDetails: any = localStorage.getItem("campaign");
  let data = JSON.parse(campaignDetails);
  useEffect(() => {
    let callFn = async () => {
      await dispatch(
        campaignAction.getRunCampaignList(id, data.audienceList.count)
      );
      setSpin(true);
    };
    callFn();
  }, [dispatch]);
  useEffect(() => {
    dispatch(clearSelectedProduct());
    dispatch(updateCurrentPath("campaign runs", "list"));
  }, [path.area, dispatch]);

  return spin ? <CampaignRunList /> : <SkeletonLoding />;
};

export default Products;
