import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { db } from "../../firebase";
import { updateCurrentPath } from "../../store/actions/root.actions";
import "./Template.css";

function Template() {
  const templates = useSelector((state: any) => state.templateReducer);
  const dispatch = useDispatch();
  dispatch(updateCurrentPath("/templates", ""));
  const history = useHistory();

  const detailsPage = (e) => {
    console.log(e);
    localStorage.setItem("state", JSON.stringify(e));
    history.push({ pathname: "/templatedetails" });
  };

  const lang = {
    "bn-in": "Bengali",
    "mr-in": "Marathi",
    "ta-in": "Tamil",
    "te-in": "Telugu",
    kn: "Kannada",
    en: "English",
    hi: "Hindi",
  };
  return (
    <div>
      <h4 className="ml-3">Select Template</h4>
      <hr />

      <div className="row" style={{ marginLeft: "20px" }}>
        {templates.template.length > 0 ? (
          templates.template.map((temp, idx) => {
            return (
              <div key={idx}>
                <div className={` bg-gradient-primary-green payCardPrice `}>
                  <span className="spanLeft">{temp.name}</span>

                  <div>
                    <span className="bottomRight spanRight">
                      <button
                        onClick={() => detailsPage(temp)}
                        className="btn btn-light"
                      >
                        Select
                      </button>
                    </span>

                    <div className="bottomLeft">
                      Language : {lang[temp.language]}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No Templates</div>
        )}
      </div>
    </div>
  );
}

export default Template;
