import React, { Fragment, Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPath } from "../../store/actions/root.actions";
import { db } from "../../firebase/index";
import profileImg from "../../assets/profile.png";
const Swal = require("sweetalert2");

const Users: React.FC = () => {
  let localUserEmail = localStorage.getItem("user_email");
  const [edit, setEdit] = useState(true);
  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  const [data, setData] = useState({
    id: "",
    name: "",
    email: localUserEmail,
    phone: "",
    mobile: "",
    address: "",
  });
  useEffect(() => {
    db.collection("Profile")
      .where("userEmail", "==", localUserEmail)
      .get()
      .then((res) => {
        res.forEach((doc) => {
          if (doc.exists) {
            setData({
              id: doc.id,
              name: doc.data().name,
              email: doc.data().userEmail,
              phone: doc.data().phone,
              mobile: doc.data().mobile,
              address: doc.data().address,
            });
            // setData(doc.data())
            setEdit(false);
          } else setEdit(true);
        });
      });
  }, []);

  const dispatch: Dispatch<any> = useDispatch();
  dispatch(updateCurrentPath("profile", ""));

  const SaveData = () => {
    if (data.name && data.phone) {
      if (data.phone.length > 6 && data.phone.length < 16) {
        if (data.id) {
          db.collection("Profile")
            .doc(data.id)
            .update({
              userEmail: data.email,
              phone: data.phone,
              name: data.name,
              address: data.address,
            })
            .then((res) => {
              Swal.fire({
                title: "Successful!",
                text: "Profile updated",
                icon: "success",
              });
              setEdit(false);
            });
        } else {
          db.collection("Profile")
            .add({
              userEmail: data.email,
              phone: data.phone,
              name: data.name,
              address: data.address,
            })
            .then((res) => {
              setData({ ...data, id: res.id });
              Swal.fire({
                title: "Success!",
                text: "Profile uploaded",
                icon: "success",
              });
              setEdit(false);
            });
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: "Enter valid number!",
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: "Enter required fields",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  return (
    <Fragment>
      <h1 className="h3 mb-2 text-gray-800">Profile</h1>
      <p className="mb-4">Users here</p>

      <br />

      <div className="container bg-gradient-primary-green">
        <div className="main-body" style={{ padding: "3%" }}>
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src={profileImg}
                      alt="Admin"
                      className="rounded-circle"
                      width="150"
                      height="150"
                    />
                    <div className="mt-3">
                      <h3 style={{ maxWidth: "180px" }}>{data.name}</h3>
                      <button
                        className={edit ? "btn btn-primary" : "btn btn-info"}
                        onClick={() => setEdit(!edit)}
                      >
                        {" "}
                        {edit ? "Cancel" : "Edit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Full Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {edit ? (
                        <input
                          pattern="/^[A-Za-z]+$/"
                          className="form-control"
                          value={data.name}
                          onChange={(e) => {
                            if (/[^a-zA-Z\ \/]/.test(e.target.value)) {
                              setMsg("Enter only characters");
                              setTimeout(() => {
                                setMsg("");
                              }, 4000);
                            } else {
                              setData({ ...data, name: e.target.value });
                              setMsg("");
                            }
                          }}
                        />
                      ) : (
                        `${data.name} `
                      )}
                    </div>
                    <span style={{ color: "red", marginLeft: "3%" }}>
                      {msg}
                    </span>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Email</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">{data.email}</div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {edit ? (
                        <input
                          type="number"
                          onChange={(e) => {
                            if (
                              e.target.value.length < 6 ||
                              e.target.value.length > 16
                            ) {
                              setMsg1("Enter valid number");

                              setTimeout(() => {
                                setMsg1("");
                              }, 4000);
                            } else {
                              setMsg1("");
                            }
                            if (/[^0-9\\/]/.test(e.target.value)) {
                              setMsg1("Enter only Numbers");
                              setTimeout(() => {
                                setMsg1("");
                              }, 4000);
                            } else {
                              setData({ ...data, phone: e.target.value });
                            }
                          }}
                          className="form-control"
                          value={data.phone}
                        />
                      ) : (
                        data.phone
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Address</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {edit ? (
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setData({ ...data, address: e.target.value });
                          }}
                          value={data?.address}
                        />
                      ) : (
                        data.address
                      )}
                    </div>
                  </div>
                  <span style={{ color: "red" }}>{msg1}</span>
                  {edit ? (
                    <div>
                      <hr />
                      <div style={{ textAlign: "center" }}>
                        <button className="btn btn-success " onClick={SaveData}>
                          Save
                        </button>{" "}
                      </div>
                    </div>
                  ) : (
                    <br />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row">
        <TopCard title="ADMINS" text={admins.length.toString()} icon="user-tie" className="primary" />
        <TopCard title="USER" text={users.length.toString()} icon="user" className="danger" />
      </div>

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Admin List</h6>
              <div className="header-buttons">
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive portlet">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First name</th>
                      <th scope="col">Last name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminElements}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">User List</h6>
              <div className="header-buttons">
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive portlet">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">First name</th>
                      <th scope="col">Last name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userElements}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default Users;
