import {
  IProduct,
  ProductModificationStatus,
} from "../models/product.interface";
export const SET_MODIFICATION_STATE: string = "SET_MODIFICATION_STATE";

export const ADD_CAMPAIGNRUNDETAILS: string = "ADD_CAMPAIGNRUNDETAILS";
export const EDIT_CAMPAIGNRUNDETAILS: string = "EDIT_CAMPAIGNRUNDETAILS";
export const GET_CAMPAIGNRUNDETAILS: string = "GET_CAMPAIGNRUNDETAILS";

export function addCampaignRunDetails(campaignRun: any): any {
  return { type: ADD_CAMPAIGNRUNDETAILS, campaignRun: campaignRun };
}
export function editCamapaignRunDetails(campaignRunDetails: any): any {
  return { type: EDIT_CAMPAIGNRUNDETAILS, campaignRun: campaignRunDetails };
}
export function getCamapaignRunDetails(): any {
  return { type: GET_CAMPAIGNRUNDETAILS };
}

export function setModificationState(
  value: ProductModificationStatus
): ISetModificationStateActionType {
  return { type: SET_MODIFICATION_STATE, value: value };
}

interface ISetModificationStateActionType {
  type: string;
  value: ProductModificationStatus;
}
