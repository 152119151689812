import { API, axiosClient, BASE_URL_API } from "../apiConstant";
import { swalErrorMessage, swalSuccessMessage } from "../swalMessage";

export const scheduleCampaign = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API}${API.CAMPAIGN.SCHEDULE_CAMPAIGN}`,
      { ...data }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getScheduleCampaign = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API}${API.CAMPAIGN.SCHEDULE_CAMPAIGN}/${id}`
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteScheduleCampaign = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API}${API.CAMPAIGN.SCHEDULE_CAMPAIGN}/${id}`
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
