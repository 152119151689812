import moment from "moment";
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  downloadInvoice,
  getInvoices,
} from "../../store/Store/Payment/payment.action";

function InvoicesList() {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    invoiceList: [],
    spin: false,
  });
  const { invoiceList, spin } = state;

  useEffect(() => {
    const init = async () => {
      let res = await dispatch(getInvoices());
      console.log(res);
      setState((prev) => ({
        ...prev,
        invoiceList: res[0]?.invoices || [],
        spin: true,
      }));
    };
    init();
  }, []);
  console.log("invoiceList", invoiceList);

  const shortenString = (text, max) => {
    if (text && text.length > max) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="text-tooltip" id={`tooltip_${Date.now()}`}>
              {text}
            </Tooltip>
          }
        >
          <span>{`${text.slice(0, max)}...`}</span>
        </OverlayTrigger>
      );
    }
    return text;
  };

  const downloadPdf = async (id) => {
    await dispatch(downloadInvoice(id));
  };
  const productElements = () => {
    return invoiceList.map((invoice, idx) => (
      <tr key={idx}>
        <th scope="row">{idx + 1}</th>
        <td>{moment(invoice.date).format("YYYY-MM-DD")}</td>
        <td>{invoice?.amt}</td>
        {/* <td>
          <a
            href={invoice?.invoice}
            className="btn btn-primary"
            target="_blank"
            onClick={() => downloadPdf(invoice?.paymentId)}
          >
            View
          </a>
        </td> */}
      </tr>
    ));
  };

  return (
    <>
      <h1 className="h3 mb-2 text-gray-800">Invoices</h1>
      <p className="mb-4">Check your Invoices here</p>
      <div className="table-responsive portlet">
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Payement Date</th>
              <th scope="col">Payement Amount </th>
              {/* <th scope="col">Download</th> */}
            </tr>
          </thead>
          <tbody>{invoiceList?.length > 0 && productElements()}</tbody>
        </table>

        {!spin && (
          <div
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bolder",
            }}
          >
            Loading ...
          </div>
        )}
        {console.log(invoiceList?.length, spin)}
        {invoiceList?.length === 0 && spin && (
          <div
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bolder",
            }}
          >
            No Invoices
          </div>
        )}
      </div>
    </>
  );
}

export default InvoicesList;
